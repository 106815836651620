<template>
  <label class="text-grey-9 text-bold">{{ $t('label.audience.number_of_audience_size') }} {{ audienceSize }} </label>
  <div class="row">
    <div class="q-mt-sm q-mr-sm">
      <DatePicker
        :default-date-range="dateRange"
        :disable="value.is_fork"
        @update:onChangeDateRange="changeDateRange"
      ></DatePicker>
    </div>
    <div v-for="item in columnOptions" :key="item.field" class="col-3 q-mt-sm" ref="columFilter">
      <q-select
        v-if="item.field"
        outlined
        dense
        option-value="_id"
        option-label="label"
        v-model="filterOptions[item.field]"
        :options="onFilterCell(item.field)"
        style="width: 93%"
        emit-value
        map-options
        multiple
        ref="cb_value_option"
        @update:model-value="onFilterOptionChange"
        :disable="value.is_fork"
      >
        <template
          v-if="filterOptions[item.field] === undefined || filterOptions[item.field].length === 0"
          v-slot:selected
        >
          <div class="text-grey-6">{{ item.label.length > 15 ? item.label.slice(0, 15) + '...' : item.label }}</div>
        </template>
        <template v-if="filterOptions[item.field] && filterOptions[item.field].length > 0" v-slot:append>
          <q-icon name="cancel" @click.stop="onClearOptionChange(item.field)" class="cursor-pointer" />
        </template>
        <template v-slot:option="scope">
          <q-item v-bind="scope.itemProps">
            <q-checkbox v-model="filterOptions[item.field]" :val="scope.opt._id"> {{ scope.opt.label }}</q-checkbox>
          </q-item>
        </template>
      </q-select>
    </div>
  </div>
  <q-table
    v-model:pagination="pagination"
    :rows-per-page-options="rowPerPageOptions"
    @request="onRequest"
    flat
    dense
    bordered
    :columns="columns"
    :rows="rows"
    :title="$t('label.audience.answers')"
    row-key="_id"
    separator="cell"
    class="q-mt-sm text-grey-8"
    :no-data-label="$t('messages.no_data_available')"
    ref="audienceFilterRef"
  >
    <template v-slot:body="props">
      <q-tr :props="props">
        <q-td v-for="(column, index) in columns" :key="column.field">
          <q-btn
            size="sm"
            unelevated
            rounded
            color="primary"
            v-if="
              props.row[column.field] !== undefined &&
              index > 1 &&
              props.row[column.field] !== null &&
              props.row[column.field] !== ''
            "
          >
            {{ props.row[column.field] }}
          </q-btn>
          <span v-else>{{ props.row[column.field] }}</span>
        </q-td>
      </q-tr>
    </template>
  </q-table>
</template>

<script lang="ts">
import { mixins, Options } from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'
import { maska } from 'maska'
import DatePicker from '@/components/common/ui/DatePicker.vue'
import {
  EScenarioResourceType,
  IAnswer,
  IAudience,
  IAudienceOption,
  IAudienceQuestionHeader,
  IAudienceQuestionRow,
  IDateRange,
  IQuestion,
} from '@/utils/types'
import AudienceMixin from '@/components/audience/mixins/AudienceMixin.vue'
import { USER_ANSWER } from '@/store/actions'
import { constant } from '@/utils/constants'
import { ICard } from 'bot-flow-maker/src/types'

@Options({
  components: { AudienceMixin, DatePicker },
  directives: { maska },
  emits: ['update:modelValue', 'update:handleLoading'],
})
export default class AudienceFilter extends mixins(AudienceMixin) {
  @Prop()
  modelValue!: IAudience

  @Prop()
  loading!: boolean

  filterOptions = {}
  dateRange: IDateRange = {
    from: '',
    to: '',
  }

  columns: IAudienceQuestionHeader[] = []
  columnOptions: IAudienceQuestionHeader[] = []
  rows: IAudienceQuestionRow[] = []
  audienceSize = 0

  pagination = {
    sortBy: '',
    descending: false,
    page: 1,
    rowsPerPage: constant.ROW_PER_PAGE,
    rowsNumber: 10,
  }

  get rowPerPageOptions() {
    return constant.ROW_PER_PAGE_OPTIONS
  }

  get value() {
    return this.modelValue
  }

  set value(value: IAudience) {
    this.$emit('update:modelValue', value)
  }

  getAnswers(data: IAnswer) {
    /* eslint-disable  @typescript-eslint/no-explicit-any */
    let result: any[] = []
    if (data.type === constant.ANSWER_TYPE.TEXT) {
      result = data.text_answers ?? []
    } else if (data.type === constant.ANSWER_TYPE.IMAGE) {
      result = data.image_answers ?? []
    } else if (data.type === constant.ANSWER_TYPE.BUTTON) {
      if (data.button_answer?.is_carousel) {
        const carousel_images = data.button_answer?.carousel_images ?? []
        result = []
        for (let index = 0; index < carousel_images.length; index++) {
          const element = carousel_images[index]
          if (element.actions) {
            const ac = element.actions.filter((p) => p.content !== undefined && p.content !== '')
            result = result.concat(ac)
          }
        }
      } else {
        const actions = data.button_answer?.actions ?? []
        result = []
        if (actions) {
          result = actions.filter((p) => p.content !== undefined && p.content !== '')
        }
      }
    } else {
      result = data.imagemap_answer?.tappable_area ?? []
    }
    return result
  }

  onFilterCell(question_id: string) {
    const dataOptions: IAudienceOption[] = []
    const question: IQuestion = this.questions.find((item) => item._id === question_id)
    if (question && question.answers) {
      const answers = this.getAnswers(question.answers)
      for (const answer of answers) {
        dataOptions.push({
          _id: answer._id,
          label: answer.label || answer.title || '',
        })
      }
    }
    return dataOptions
  }

  async loadQuestions() {
    const questionIds: string[] = []
    if (this.value.campaign_ids) {
      this.value.campaign_ids.forEach((element) => {
        const scenarios: ICard[] = this.getScenarios(element._id, element.type)

        for (const scenario of scenarios) {
          if (scenario.cardType === EScenarioResourceType.question && !questionIds.includes(scenario.id)) {
            questionIds.push(scenario.id)
          }
        }
      })
    }

    return questionIds
  }

  async loadTableHeader() {
    this.columns = [
      {
        _id: 'display_name',
        label: this.$t('label.audience.user_name'),
        field: 'display_name',
      },
      {
        _id: 'created_at',
        label: this.$t('label.audience.created_at'),
        field: 'created_at',
      },
    ]

    const questionIds: string[] = await this.loadQuestions()
    for (const question_id of questionIds) {
      this.filterOptions[question_id] = []
      const question = this.questions.find((item) => item._id === question_id)
      if (question) {
        const label = question.title
        this.columns.push({
          _id: question_id,
          label: label,
          field: question_id,
        })
      }
    }
    this.columnOptions = [] // get data for select not user_name and created_at
    this.columns.forEach((item, index) => {
      if (index > 1) {
        this.columnOptions.push(item)
      }
    })
  }

  async loadUserAnswers() {
    // get initial data from server (1st page)
    await this.$refs.audienceFilterRef.requestServerInteraction()
  }

  changeDateRange(value) {
    this.dateRange = value
  }

  onClearOptionChange(questionId) {
    this.filterOptions[questionId] = []
    this.onFilterOptionChange()
  }

  @Watch('dateRange')
  @Watch('filterOptions', { deep: true })
  async onFilterOptionChange() {
    if (this.loading) {
      return
    }

    this.value.filter_condition = {
      date_range: this.dateRange,
      answers: this.filterOptions,
    }

    await this.loadUserAnswers()
  }

  @Watch('value.is_test_mode', { deep: true })
  @Watch('value.campaign_ids', { deep: true })
  @Watch('value.traffic_source_ids', { deep: true })
  @Watch('value.target_setting_ids', { deep: true })
  async onInitialCampaignSelected() {
    if (this.loading) {
      return
    }
    await this.loadData()
  }

  async loadData() {
    if (this.value.filter_condition && this.value.filter_condition.date_range) {
      this.dateRange = this.value.filter_condition.date_range
    }

    await this.loadTableHeader()

    if (this.value && this.value.filter_condition && this.value.filter_condition.answers) {
      Object.keys(this.value.filter_condition.answers).forEach((element) => {
        if (this.value.filter_condition && this.value.filter_condition.answers) {
          this.filterOptions[element] = this.value?.filter_condition?.answers[element]
        }
      })
    }

    await this.loadUserAnswers()
  }

  async onRequest(props) {
    const { page, rowsPerPage, sortBy, descending } = props.pagination
    const filter = props.filter
    this.$emit('update:handleLoading', true)
    console.log('onRequest start')

    const fetchCount = rowsPerPage === 0 ? this.audienceSize : rowsPerPage
    const startRow = (page - 1) * rowsPerPage
    const returnedData: [] = await this.fetchFromServer(startRow, fetchCount, filter, sortBy, descending)
    // eslint-disable-next-line
    this.rows = returnedData['rows']
    if (!this.rows) {
      this.rows = []
    }
    // eslint-disable-next-line
    this.audienceSize = returnedData['audience_size']
    // eslint-disable-next-line
    this.pagination.rowsNumber = returnedData['total_rows']
    this.rows.splice(0, this.rows.length, ...this.rows)
    this.pagination.page = page
    this.pagination.rowsPerPage = rowsPerPage
    this.pagination.sortBy = sortBy
    this.pagination.descending = descending
    console.log('onRequest end')

    this.$emit('update:handleLoading', false)
  }

  async fetchFromServer(startRow, count, filter, sortBy, descending) {
    const items = await this.$store.dispatch(USER_ANSWER.USER_ANSWER_ROWS, {
      audience: this.value,
      is_test_mode: this.isTestMode,
      filter: {
        start_row: startRow,
        rows_number: count,
        filter: filter,
        sort_by: sortBy,
        descending: descending,
      },
    })

    return items
  }

  validate() {
    if (
      (!this.value.campaign_ids || (this.value.campaign_ids && this.value.campaign_ids?.length <= 0)) &&
      (!this.value.traffic_source_ids ||
        (this.value.traffic_source_ids && this.value.traffic_source_ids?.length <= 0)) &&
      (!this.value.target_setting_ids || (this.value.target_setting_ids && this.value.target_setting_ids?.length <= 0))
    ) {
      return false
    }
    // if (!this.value.is_auto_update) {
    //   if (this.audienceSize <= 0) {
    //     return false
    //   }
    // }

    return true
  }
}
</script>
<style lang="sass">
.q-table thead tr th
  font-weight: bold
  text-align: left
</style>
