import { ICard } from 'bot-flow-maker/src/types'

export interface ILoginPayload {
  email: string
  password: string
}

export interface IResetPasswordPayload {
  token: string
  password: string
}

export interface ILoginResponse {
  token: string
  user: IUser
}

export interface IRegisterPayload {
  email: string
  display_name: string
  password: string
}

export interface IUser {
  _id?: string
  user_id?: string
  email?: string
  password?: string
  is_active?: boolean
  is_sendmail_password?: boolean
  display_name?: string
  last_logged_in?: Date
  is_admin?: boolean
  is_super?: boolean
  photo_url?: string
  label?: string
}

export interface IApp {
  _id?: string
  name?: string
  token?: string
  secret?: string
  app_prefix?: string
  line_id?: string
  line_id_test?: string
  token_test?: string
  secret_test?: string
  is_test_mode?: boolean
  app_prefix_test?: string
  platform?: string
  triggered_at?: string
  deployed_at?: string
  logs?: string[]
  created_at?: string
  updated_at?: string
  copy_app_id?: string
  user_id?: string
  liff_id?: string
  liff_id_test?: string
  account_id?: string
  account_id_test?: string
  auto_reply?: IAutoReply
}

export interface IAnswer {
  type?: string
  text_answers?: ITextAnswer[] // If type is 'text'
  alt_text?: string // If type is image
  image_answers?: IImageAnswer[] // If type is 'image'
  imagemap_answer?: IImagemapAnswer // If type is 'imagemap'
  freetext_answer?: IFreeTextAnswer // If type is 'imagemap'
  button_answer?: IButtonAnswer
}

export interface IFreeTextAnswer {
  option_type?: string
  error_message?: string
}

export interface ITextAnswer {
  _id?: string
  label?: string
  title?: string
  image_url?: string
}

export interface IImageAnswer {
  _id?: string
  label?: string
  title?: string
  image_url?: string
}

export interface IImagemapAnswer {
  _id?: string
  base_url?: string
  folder_id?: string
  is_apply_all_device?: boolean
  alt_text?: string
  image_url_sizes?: IImageUrlSize[]
  tappable_area?: ITappableArea[]
}

export interface IButtonAnswer {
  _id?: string
  title?: string
  text?: string
  actions?: IButtonAnswerAction[]
  image_aspect_ratio?: number
  image_size?: string
  image_background_color?: string
  image_url?: string
  is_use_image?: string
  is_carousel?: boolean
  carousel_images?: ICarouselButton[]
}

export interface ICarouselButton {
  _id?: string
  title?: string
  text?: string
  image_aspect_ratio?: number
  image_size?: string
  image_background_color?: string
  image_url?: string
  actions?: IButtonAnswerAction[]
}

export interface IButtonAnswerAction {
  _id?: string
  type?: string
  label?: string
  content?: string
}

export interface IImageUrlSize {
  width?: number
  image_url?: string
  height?: number
}

export interface ITappableArea {
  _id?: string
  action_type?: string
  label?: string
  title?: string
  link_uri?: string
  push_id?: string
  rich_menu_alias_id?: string
  x?: number
  y?: number
  width?: number
  height?: number
}

// Question
export interface IQuestion {
  _id?: string
  display_id?: string
  app_id?: string
  title?: string
  message?: string
  answers?: IAnswer
  user_id?: string
  created_at?: string
  updated_at?: string
  applied_campain?: number
}

export interface IForm {
  _id?: string
  display_id?: string
  app_id?: string
  title?: string
  message?: string
  details?: IFormDetail[]
  user_id?: string
  created_at?: string
  updated_at?: string
  applied_campain?: number
}

export interface IFormDetail {
  _id?: string
  field_name?: string
  option_type?: string
  error_message?: string
}

export interface IFormListRequest {
  app_id: string
}

export interface IMessageDetail {
  type?: string
  alt_text?: string // If type is image
  message?: string // If type is 'message'
  images?: DetailImageType[] // If type is image
  imagemap_answer?: IImagemapAnswer
  button_answer?: IButtonAnswer
}

export interface DetailImageType {
  image_url?: string
  click_url?: string
}

export interface IQuestionListRequest {
  app_id: string
}

// Goal
export interface IGoal {
  _id?: string
  display_id?: string
  app_id?: string
  title?: string
  details?: IMessageDetail[]
  is_continue_question?: boolean
  message?: string
  answers?: IAnswer
  applied_campain?: number
}
export interface IUploadFileResponse {
  file_url_org?: string
  file_url_thumb?: string
  filename?: string
  extension?: string
}

export interface IUploadFileMultiSizesResponse {
  file_url_org?: string
  filename?: string
  base_url?: string
  image_url_sizes?: IImageUrlSize[]
  extension?: string
  folder_id?: string
}

// Initial
export interface IInitialCampaign {
  _id?: string
  app_id?: string
  title?: string
  is_active?: boolean
  is_test_mode?: boolean
  delivered?: number
  prod_detail?: IInitialCampaignDetail
  test_detail?: IInitialCampaignDetail
  success_count?: number
  success_rate?: number
  total_click?: number
  click_rate?: number
  total_user_of_goal?: number
  gtr?: number
  story_id?: string
}

export interface IVersionHistory {
  _id?: string
  app_id?: string
  default_title?: string
  title?: string
  campaign_type?: string
  campaign_id?: string
  campaign?: IInitialCampaign | IPushCampaign
  user_id?: string
  user_name?: string
  updated_at?: string
}

export interface IInitialCampaignDetail {
  is_use?: boolean
  direction_type?: number
  welcomes?: Iwelcomes[]
  init_quick_reply?: IInitQuickReply
  scenarios?: ICard[]
}

export interface IInitQuickReply {
  is_auto_start?: boolean
  start_survey?: string
  is_start_survey?: boolean
  restart_survey?: string
  is_restart_survey?: boolean
}

export interface IAutoReply {
  is_auto_reply?: boolean
}

export interface IAutoResponse {
  _id?: string
  app_id?: string
  title?: string
  response_type?: string
  response_keywords?: string[]
  response_exclusive_keywords?: string[]
  is_schedule?: boolean
  schedule_type?: string
  from_date?: string
  to_date?: string
  from_time?: string
  to_time?: string
  message?: string
  in_use?: boolean
}

export interface IAutoResponseRequest {
  app_id: string
}

export interface IInitialRequest {
  app_id: string
}

export interface Iwelcomes {
  name?: string
  order?: number
  fixed?: boolean
}

// Push
export interface IPushCampaign {
  _id?: string
  title?: string
  app_id?: string
  audience_size?: number
  delivered?: number
  is_active?: boolean
  is_test_mode?: boolean
  prod_detail?: IPushCampaignDetail
  test_detail?: IPushCampaignDetail
  success_count?: number
  success_rate?: number
  total_click?: number
  click_rate?: number
  total_user_of_goal?: number
  gtr?: number
  next_time?: string
  number_of_delivery?: number
  story_id?: string
}

export interface IPushCampaignDetail {
  starting_point?: string
  unit_type?: string
  delivery_target_type?: string
  previous_campaign_id?: string
  campaign_type?: string
  direction_type?: number
  audience?: IPushAudience
  frequency?: IFrequency
  schedule?: ISchedule
  scenarios?: ICard[]
}

export interface IFrequency {
  active?: boolean
  time_per_user?: number
}

export interface IPushAudience {
  audience_or?: IAudienceOrNot[]
  audience_not?: IAudienceOrNot[]
  card_or?: IScenarioAnswer[]
  card_not?: IScenarioAnswer[]
  unreached_users?: ICard[]
  reached_users?: ICard[]
}

export interface IScenarioAnswer {
  id: string
  uniqueId: string
  title: string
  cardType: string
  totalUsers: number
  answerId: string
  answerTitle: string
}

export interface IAudienceOrNot {
  _id?: string
  title?: string
  app_id?: string
  is_active?: boolean
  is_test_mode?: boolean
  audience_size?: number
}

export interface ISchedule {
  schedule_type?: string
  schedule_by_type?: string
  day_of_month?: number
  day_of_week?: number
  delay_day?: number
  date?: string
  time?: string
}

export enum EScenarioResourceType {
  question = 'question',
  message = 'message',
  goal = 'goal',
  form = 'form',
  start = 'start',
  welcome = 'welcome',
}

export interface ISelectedItemCondition {
  cardType?: string
  uniqueId?: string
  selectedAnswerIds?: string[]
}

export interface IAnswerItem {
  _id?: string
  label?: string
  title?: string
  isSelect?: boolean
}

// Messages
export interface IMessage {
  _id?: string
  display_id?: string
  title?: string
  app_id?: string
  messages?: IMessageDetail[]
  applied_campain?: number
}

export interface IAudienceCampaign {
  _id?: string
  title?: string
  type?: string
}

// IAudience
export interface IAudience {
  _id?: string
  title?: string
  app_id?: string
  is_active?: boolean
  is_test_mode?: boolean
  campaign_ids?: IAudienceCampaign[]
  campaign_names?: string
  traffic_source_ids?: ITrafficSource[]
  target_setting_ids?: string[]
  traffic_source_names?: string
  audience_size?: number
  filter_condition?: IAudienceFilter
  is_auto_update?: boolean
  is_fork?: boolean
}

export interface IAudienceFilter {
  date_range?: IDateRange
  answers?: Record<string, string[]>
}

export interface IAudienceQuestionHeader {
  _id?: string
  label?: string
  field?: string
}

export interface IAudienceQuestionRow {
  _id: string
  user_id: string
  user_name: string
  created_at: string
  [key: string]: string
}

// IAudienceAnswerRequest
export interface IAudienceAnswerRequest {
  app_id?: string
  campaign_ids?: IAudienceCampaign[]
}

export interface IAudienceUserDetailRequest {
  app_id?: string
  user_ids?: string[]
}

// IAudienceAnswer
export interface IAudienceAnswer {
  _id?: string
  answer_id?: string
  answer_label?: string
  app_id?: string
  campaign_id?: string
  created_at?: string
  question_id?: string
  survey_id?: string
  user_id?: string
}

export interface IAudienceOption {
  _id?: string
  label?: string
}

export interface IUserAnswer {
  _id?: string
  app_id?: string
  user_id?: string
  user_name?: string
  campaign_id?: string
  unique_id?: string
  resource_id?: string
  resource_type?: string
  next_unique_id?: string
  next_resource_id?: string
  next_resource_type?: string
  answer_id?: string
  answer_label?: string
  survey_id?: string
  created_at?: string
  updated_at?: string
}

export interface IDeliverLog {
  _id?: string
  app_id?: string
  campaign_type?: string
  campaign_id?: string
  request_id?: string
  success_count?: string
  failure_count?: number
  target_count?: string
  failed_description?: string
  error_code?: number
  accepted_time?: string
  completed_time?: string
  phase?: string
  audience_group_id: string
  user_id: string
}

export interface IDateRange {
  from?: string
  to?: string
}

export interface IDateRangePicker {
  startDate: string
  endDate: string
}

export interface IAppUser {
  _id?: string
  app_id?: string
  user_id?: string
  display_name?: string
  picture_url?: string
  email?: string
  is_active?: boolean
  is_test_mode?: boolean
}

export interface IRichMenuRequest {
  app_id: string
}

export interface IRichMenu {
  _id?: string
  app_id?: string
  name?: string
  is_active?: boolean
  is_test_mode?: boolean
  prod_detail?: IRichMenuDetail
  test_detail?: IRichMenuDetail
  prod_rich_menu_alias_ids?: string[]
  prod_rich_menu_ids?: string[]
  test_rich_menu_alias_ids?: string[]
  test_rich_menu_ids?: string[]
}

export interface IRichMenuDetail {
  chat_bar_text?: string
  rich_menu_content?: IRichMenuContent[]
}

export interface IRichMenuContent {
  label?: string
  rich_menu_alias_id?: string
  image_url?: string
  image_width?: number
  image_height?: number
  content_type?: string
  size?: ISize
  tappable_area?: ITappableArea[]
  selected_template?: IRichMenuTemplateCompact
}

export interface IRichMenuTemplate {
  _id?: string
  app_id?: string
  name?: string
  width?: number
  height?: number
  img?: string
  total_row?: number
  content?: IImageSize[]
}

export interface IRichMenuTemplateCompact {
  _id?: string
  add_menu?: boolean
  total_menu?: number
  bottom_menu?: boolean
  components?: IRichMenuTemplateComponent[]
  tappable_area?: ITappableArea[]
}

export interface IRichMenuTemplateComponent {
  index: number
  img: string
}

export interface IOriginRichMenuTemplate {
  _id?: string
  app_id?: string
  name?: string
  width?: number
  height?: number
  img?: string
  total_row?: number
  content?: readonly IOriginImageSize[]
}

export interface IImageSize {
  x?: number
  y?: number
  width?: number
  height?: number
  src?: string
  rowspan?: number
}

export interface IOriginImageSize {
  readonly x?: number
  readonly y?: number
  readonly width?: number
  readonly height?: number
  readonly src?: string
  readonly rowspan?: number
}

export interface ISize {
  width: number
  height: number
}

export interface ITargetSetting {
  _id?: string
  app_id?: string
  target_name?: string
  prod_detail?: ITargetSettingDetail
  test_detail?: ITargetSettingDetail
  is_active?: boolean
  is_test_mode?: boolean
}

export interface ITargetSettingDetail {
  success_page_urls?: ISuccessPageURL[]
}

export interface ISuccessPageURL {
  url: string
  is_full_match: boolean
}

export interface ITargetSettingRequest {
  app_id: string
}

export interface ICampaign {
  _id?: string
  app_id?: string
  display_id?: string
  campaign_name?: string
  prod_detail?: ICampaignDetail
  test_detail?: ICampaignDetail
  is_active?: boolean
  is_test_mode?: boolean
  is_traffic_source?: boolean
  copy_campaign_id?: string
  story_id?: string
}

export interface ICampaignDetail {
  events_setting?: IEventSetting
}

export interface IEventSetting {
  back_button_click?: {
    is_active: boolean
    force_display: boolean
    seconds: number
  }
  mouse_out_screen?: {
    is_active: boolean
    seconds: number
  }
  after_page_loaded?: {
    is_active: boolean
    seconds: number
  }
  scroll_up?: {
    is_active: boolean
    position: number
  }
  scroll_down?: {
    is_active: boolean
    position: number
  }
  number_display_trigger?: {
    is_active: boolean
    number: number
  }
}

export interface ICampaignRequest {
  app_id: string
}

export interface IPopup {
  _id?: string
  app_id?: string
  campaign_id?: string
  display_id?: string
  popup_name?: string
  images: PopupContent[]
  is_active?: boolean
  is_test_mode?: boolean
  animation_type?: number
  redirect_url?: string
}
export interface PopupContent {
  pc: {
    img_org: string
    img_thumb: string
    filename: string
  }
  sp: {
    img_org: string
    img_thumb: string
    filename: string
  }
}

export interface IPopupRequest {
  app_id: string
  campaign_id: string
}

export interface IAudienceUserHeader {
  _id?: string
  label?: string
  field?: string
  is_display?: boolean
  align: string
}

export interface IAudienceUserRow {
  [key: string]: object
}

export interface IChat {
  name?: string
  text?: string[]
  sent?: boolean
  type?: string
  imagecarousels?: DetailImageType[] // If type is image
  imagemap_answer?: IImagemapAnswer
  image_answers?: IImageAnswer[]
  uniqueId?: string
  button_answer?: IButtonAnswer
}

export interface IValidRequest {
  app_id: string
  initial_id: string
  push_id: string
  card_id: string
  card_type: string
  total_messages: number
  campaign: IInitialCampaign | IPushCampaign
}

export interface ITrafficSource {
  _id?: string
  app_id?: string
  campaign_id?: string
  source_name?: string
  num_of_traffic?: number
  num_of_users?: number
  tracking_url?: string
  qr_code?: string
  is_active?: boolean
  is_test_mode?: boolean
}

export interface ITrafficSourceRequest {
  app_id: string
}

export interface IForkAudienceRequest {
  _id?: string
  app_id?: string
  fork_number: number
}

export interface IOverviewMessage {
  date?: string
  audience_size?: number
  delivered?: number
  total_user_of_goal?: number
  gtr?: number
  total_click?: number
  success_rate?: number
  success_count?: number
  message_type?: string
}

export interface IOverViewRequest {
  app_id: string
  campaign_id: string
  date: string
  audience_size: number
  delivered: number
  total_user_of_goal: number
  gtr: number
  total_click: number
  success_rate: number
  success_count: number
}

export interface IOverviewDetail {
  app_id?: string
  date?: string
  date_mm_dd?: string
  new_users?: number
  blocked_users?: number
  total_active_users?: number
}

export interface IScenarioTemplate {
  _id?: string
  app_id?: string
  title?: string
  direction_type?: number
  scenario?: ICard[]
}

export interface ICommonRequest {
  app_id: string
  user_id?: string
}
export interface IActivityLog {
  _id?: string
  app_id: string
  user_id: string
  survey_id: string
  campaign_type: string
  campaign_id: string
  campaign_name: string
  resource_type: string
  resource_id: string
  answer_id: string
  event_type: string
  event_value: string
  is_active: boolean
  is_test_mode?: boolean
}

export interface IFilter {
  filter_type?: string
  filter_value?: {}
  filter_multi_select?: string
  filter_select_all?: boolean
}

export interface IEventType {
  label?: string
  value?: string
}

export interface IMedia {
  _id?: string
  app_id?: string
  name?: string
  url?: string
  thumb?: string
  file_type?: string
  image_width?: number
  image_height?: number
  content_type?: string
}

export interface IFormReportRequest {
  app_id?: string
}

export interface IFormReport {
  user_id?: string
  display_name?: string
  details?: {
    name_kanji?: string
    name_hira?: string
    name_kana?: string
    phone_number?: string
    phone_number_no_dash?: string
    address?: string
    postcode_dash?: string
    postcode_no_dash?: string
    email?: string
    freetext?: string
  }
  updated_at?: string
}

export interface IFormReportCSV {
  updated_at?: string
  display_name?: string
  name_kanji?: string
  name_hira?: string
  name_kana?: string
  phone_number?: string
  phone_number_no_dash?: string
  address?: string
  postcode_dash?: string
  postcode_no_dash?: string
  email?: string
  freetext?: string
}

export interface IPermission {
  app_id?: string
  user_id?: string
}

export interface IPermissionUsers {
  app_id?: string
  user_ids?: string[]
}

export interface IPermissionRequest {
  app_id: string
}

export interface IStory {
  _id?: string
  app_id?: string
  display_id?: string
  story_name?: string
  is_active?: boolean
  is_test_mode?: boolean
  trigger_type?: string
  campaign?: ICampaign
  popups?: IPopup[]
  initial?: IInitialCampaign
  pushes?: IPushCampaign[]
  push?: IPushCampaign
  creator_name?: string
  updater_name?: string
  created_at?: string
  updated_at?: string
}

export interface IStoryRequest {
  app_id: string
}

export interface ISendingTestRequest {
  app_id?: string
  story_id?: string
  is_test_mode?: boolean
  initial_id?: string
  push_id?: string
  user_ids?: string[]
}

export interface IChartData {
  name?: string
  value?: number
}
