
import { Options, Vue } from 'vue-class-component'
import { maska } from 'maska'
import { Prop, Watch } from 'vue-property-decorator'
import UserAvatar from '@/components/user/UserAvatar.vue'
import { IUser } from '@/utils/types'
import CLoading from '@/components/common/ui/CLoading.vue'
@Options({
  components: { CLoading, UserAvatar },
  directives: { maska },
  emits: ['update:closeModal', 'update:submitUsers', 'update:loadUsers', 'update:filterUsers'],
})
export default class UserSelectionModal extends Vue {
  @Prop()
  modalVisible!: boolean

  @Prop({})
  users!: IUser[]

  @Prop({})
  filterUsers!: IUser[]

  @Prop()
  totalPages!: number

  @Prop()
  totalFilterPages!: number

  @Prop()
  totalUsers!: number

  @Prop()
  perPages!: number

  @Prop()
  loading!: boolean

  currentPage = 1
  currentFilterPage = 1
  search = ''
  selectAll = true
  selectedUserIds: (string | undefined)[] = []
  selectedUserState = 'all'
  userStateOptions = [
    {
      label: 'すべて',
      value: 'all',
    },
    {
      label: '選択済み',
      value: 'selected',
    },
    {
      label: '未選択',
      value: 'unselected',
    },
  ]

  get visible() {
    return this.modalVisible
  }

  set visible(value) {
    this.$emit('update:closeModal', value)
  }

  get displayUsers() {
    if (this.selectedUserState === 'all') {
      return this.users
    }

    return this.filterUsers
  }

  @Watch('currentPage')
  async currentPageChanged(page) {
    const filter = {
      page: page ?? this.currentPage,
      per_page: this.perPages,
      display_name: this.search,
      state: this.selectedUserState,
      user_ids: this.selectedUserIds,
      is_all: this.selectAll,
    }
    this.$emit('update:loadUsers', filter)
  }

  @Watch('users')
  @Watch('filterUsers')
  userChanged() {
    let currentUserIds: (string | undefined)[]
    if (this.selectAll) {
      currentUserIds = this.users.map((user) => user.user_id)
    } else {
      currentUserIds = []
    }

    if (this.totalPages === 0) {
      this.currentPage = 0
    }

    if (this.totalFilterPages === 0) {
      this.currentFilterPage = 0
    }

    this.selectedUserIds = [...new Set([...this.selectedUserIds, ...currentUserIds])]
  }

  @Watch('selectedUserState')
  @Watch('currentFilterPage')
  selectedUserStateChanged() {
    if (this.selectedUserState !== 'all') {
      const filter = {
        page: this.currentFilterPage,
        per_page: this.perPages,
        display_name: this.search,
        state: this.selectedUserState,
        user_ids: this.selectedUserIds,
        is_all: this.selectAll,
      }
      this.$emit('update:filterUsers', filter)
    }
  }

  toggleSelectAll(val) {
    this.selectAll = val
    if (this.selectAll) {
      this.selectedUserState = 'all'
      this.selectedUserIds = this.users.map((user) => user.user_id)
    } else {
      this.selectedUserIds = []
    }
  }

  toggleUser() {
    this.selectAll = this.selectedUserIds.length === this.totalUsers
  }

  onSearchUsers() {
    this.currentPageChanged(1)
  }

  onSubmit() {
    this.$emit('update:submitUsers', this.selectedUserIds, this.selectAll)
    this.visible = false
  }

  getUserInfo(display_name, picture_url) {
    const u: IUser = {
      photo_url: picture_url,
      display_name: display_name,
    }
    return u
  }

  truncate(text, length, clamp) {
    clamp = clamp || '...'
    const node = document.createElement('div')
    node.innerHTML = text
    const content = node.textContent
    if (content) {
      return content.length > length ? content.slice(0, length) + clamp : content
    }
    return text
  }
}
