import { render } from "./ButtonsTemplatePreview.vue?vue&type=template&id=9aca2554&scoped=true"
import script from "./ButtonsTemplatePreview.vue?vue&type=script&lang=ts"
export * from "./ButtonsTemplatePreview.vue?vue&type=script&lang=ts"

import "./ButtonsTemplatePreview.vue?vue&type=style&index=0&id=9aca2554&lang=scss&scoped=true"
script.render = render
script.__scopeId = "data-v-9aca2554"

export default script
import QCarousel from 'quasar/src/components/carousel/QCarousel.js';
import QCarouselSlide from 'quasar/src/components/carousel/QCarouselSlide.js';
import QImg from 'quasar/src/components/img/QImg.js';
import QCardActions from 'quasar/src/components/card/QCardActions.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QBtnToggle from 'quasar/src/components/btn-toggle/QBtnToggle.js';
import qInstall from "../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QCarousel,QCarouselSlide,QImg,QCardActions,QBtn,QBtnToggle});
