import { render } from "./ButtonSelector.vue?vue&type=template&id=69fbdf53&scoped=true"
import script from "./ButtonSelector.vue?vue&type=script&lang=ts"
export * from "./ButtonSelector.vue?vue&type=script&lang=ts"

import "./ButtonSelector.vue?vue&type=style&index=0&id=69fbdf53&scoped=true&lang=scss"
script.render = render
script.__scopeId = "data-v-69fbdf53"

export default script
import QList from 'quasar/src/components/item/QList.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QImg from 'quasar/src/components/img/QImg.js';
import QCardActions from 'quasar/src/components/card/QCardActions.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QToggle from 'quasar/src/components/toggle/QToggle.js';
import QFile from 'quasar/src/components/file/QFile.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QRadio from 'quasar/src/components/radio/QRadio.js';
import QItemLabel from 'quasar/src/components/item/QItemLabel.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QPopupProxy from 'quasar/src/components/popup-proxy/QPopupProxy.js';
import QColor from 'quasar/src/components/color/QColor.js';
import QExpansionItem from 'quasar/src/components/expansion-item/QExpansionItem.js';
import QSelect from 'quasar/src/components/select/QSelect.js';
import qInstall from "../../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QList,QItem,QCard,QCardSection,QImg,QCardActions,QBtn,QToggle,QFile,QItemSection,QRadio,QItemLabel,QInput,QIcon,QPopupProxy,QColor,QExpansionItem,QSelect});
