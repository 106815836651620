import { render } from "./DateRangeSelectorV2.vue?vue&type=template&id=50dca4d1&scoped=true"
import script from "./DateRangeSelectorV2.vue?vue&type=script&lang=ts"
export * from "./DateRangeSelectorV2.vue?vue&type=script&lang=ts"

import "./DateRangeSelectorV2.vue?vue&type=style&index=0&id=50dca4d1&scoped=true&lang=scss"
script.render = render
script.__scopeId = "data-v-50dca4d1"

export default script
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import qInstall from "../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QIcon,QBtn});
