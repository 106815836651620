<template>
  <div>
    <draggable
      v-if="value.length"
      :list="value"
      itemKey="image"
      v-bind="{
        animation: 200,
        group: 'welcome',
        disabled: false,
      }"
      @change="(evt) => onDragEnd(evt, value)"
      handle=".cursor-move"
      :component-data="{
        type: 'transition-group',
        name: 'drag-area',
        class: 'q-list q-list--dense q-list--separator q-py-sm',
      }"
      :forceFallback="true"
      fallbackClass="dragging-item"
      :disabled="value.length <= 1"
    >
      <template #item="{ element: detail, index }">
        <div class="bg-white q-pb-sm">
          <q-item class="q-pa-none">
            <q-item-section avatar class="cursor-move q-pr-none">
              <q-icon name="swap_vert" color="grey" size="sm" />
            </q-item-section>
            <q-item-section>
              <div>
                <q-btn-toggle
                  padding="0 10px"
                  no-caps
                  unelevated
                  color="grey-6"
                  text-color="white"
                  toggle-color="blue-8"
                  toggle-text-color="white"
                  style="border: none"
                  emit-value
                  :options="typeLists"
                  :model-value="detail.type"
                  @update:model-value="(updatedVal) => changeType(detail, updatedVal)"
                />
              </div>
              <div v-if="detail.type === 'message'" class="q-mr-md q-mt-md q-mb-md">
                <InputEmojiSelector
                  v-model="detail.message"
                  type="textarea"
                  :placeholder="$t('label.common.messages.message')"
                  :rules="[(val) => (val && val.length > 0) || requiredRule.detailMessage]"
                />
              </div>
              <div v-if="detail.type === 'image'" class="q-mr-md q-mt-md q-mb-md">
                <CarouselImageSelector
                  v-model="detail.images"
                  :label="detail.alt_text"
                  :ref="'imageSelector' + index"
                  @update:label="(updateVal) => (detail.alt_text = updateVal)"
                />
              </div>
              <div v-if="detail.type === 'imagemap'" class="q-mr-md q-mt-md q-mb-md">
                <ImagemapSelector
                  v-model="detail.imagemap_answer"
                  :ref="'imagemapSelector' + index"
                  :isURL="true"
                  :isMessage="true"
                />
              </div>
              <div v-if="detail.type === 'button'" class="q-mr-md q-mt-md q-mb-md">
                <ButtonSelector v-model="detail.button_answer" :ref="'buttonSelector' + index" />
              </div>
              <span v-if="index + 1 === maxItemMessage">
                <div class="text-red">{{ $t('label.common.messages.max_items') }}{{ maxItemMessage }}</div>
              </span>
            </q-item-section>
          </q-item>
          <div class="row items-center">
            <div class="col horizontal-line"></div>
            <q-btn
              round
              flat
              color="white"
              text-color="black"
              icon="add"
              class="solid-border"
              @click="onAddDetail(index + 1)"
              :disable="value.length + 1 > maxItemMessage"
            />
            <div class="horizontal-line" style="width: 20px"></div>
            <q-btn
              round
              flat
              color="white"
              text-color="black"
              icon="close"
              class="dotted-border"
              @click="onRemoveDetail(index)"
              :disabled="!canRemoveLast && value.length === 1"
            />
            <div class="col horizontal-line"></div>
          </div>
        </div>
      </template>
    </draggable>
  </div>
</template>

<script lang="ts">
import { Vue, Options } from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'
import { maska } from 'maska'
import draggable from 'vuedraggable'

import { IMessageDetail } from '@/utils/types'
import { calcOrderingDragend } from '@/utils/helpers'
import { constant } from '@/utils/constants'
import CarouselImageSelector from './CarouselImageSelector.vue'
import InputEmojiSelector from '@/components/common/ui/InputEmojiSelector.vue'
import ImagemapSelector from '@/components/story/common/tappable-area/selectors/ImagemapSelector.vue'
import ButtonSelector from '@/components/story/common/selectors/ButtonSelector.vue'

@Options({
  components: { ButtonSelector, ImagemapSelector, CarouselImageSelector, draggable, InputEmojiSelector },
  directives: { maska },
  emits: ['update:modelValue'],
})
export default class MessageSelector extends Vue {
  @Prop({ default: [] })
  modelValue!: IMessageDetail[]

  @Prop({ default: false })
  canRemoveLast!: boolean

  @Prop({ default: false })
  answerNumber!: number

  get maxItemMessage() {
    return constant.MAX_ITEM_MESSAGE - (this.answerNumber > 0 ? 1 : 0)
  }

  get typeLists() {
    const types = [
      {
        label: this.$t('label.common.messages.message'),
        value: constant.ANSWER_TYPE.MESSAGE,
      },
      {
        label: this.$t('label.common.messages.image'),
        value: constant.ANSWER_TYPE.IMAGE,
      },
      {
        label: this.$t('label.common.messages.imagemap'),
        value: constant.ANSWER_TYPE.IMAGEMAP,
      },
      {
        label: this.$t('label.common.messages.button'),
        value: constant.ANSWER_TYPE.BUTTON,
      },
    ]
    return types
  }

  get requiredRule() {
    const requiredRule = {
      detailMessage: this.$t('validate.this_field_is_required', {
        placeholder: this.$t('label.common.messages.message'),
      }),
    }
    return requiredRule
  }

  get value() {
    return this.modelValue
  }

  set value(value: IMessageDetail[]) {
    this.$emit('update:modelValue', value)
  }

  validate() {
    let isValid = true
    this.value.forEach((item, index) => {
      if (item.type === constant.ANSWER_TYPE.IMAGEMAP) {
        const id = 'imagemapSelector' + index
        if (this.$refs[id] && !this.$refs[id].validate()) {
          isValid = false
        }
      } else if (item.type === constant.ANSWER_TYPE.IMAGE) {
        const id = 'imageSelector' + index
        if (this.$refs[id] && !this.$refs[id].validate()) {
          isValid = false
        }
      } else if (item.type === constant.ANSWER_TYPE.BUTTON) {
        const id = 'buttonSelector' + index
        if (this.$refs[id] && !this.$refs[id].validate()) {
          isValid = false
        }
      }
    })

    return isValid
  }

  @Watch('modelValue', { immediate: true })
  showDefault() {
    if (this.value.length === 0) {
      this.onAddDetail(0)
    }
  }

  onAddDetail(index: number) {
    const newDetail: IMessageDetail = {
      type: constant.ANSWER_TYPE.MESSAGE,
      message: '',
      images: [],
      imagemap_answer: {},
      button_answer: {
        is_carousel: false,
      },
    }
    if (this.value) {
      this.value.splice(index + 1, 0, newDetail)
    }
  }

  onRemoveDetail(index: number) {
    if (this.value) {
      this.value.splice(index, 1)
    }
  }

  changeType(detail: IMessageDetail, updateType: string) {
    if (detail.message === '' && detail.images?.length === 0 && !detail.imagemap_answer?.base_url) {
      detail.type = updateType
    } else {
      this.$q
        .dialog({
          title: this.$t('messages.confirm'),
          message: this.$t('messages.are_you_sure_change_this_tab'),
          cancel: {
            flat: true,
            label: this.$t('cancel'),
          },
          persistent: true,
        })
        .onOk(async () => {
          detail.type = updateType
          detail.message = ''
          detail.images = []
          detail.imagemap_answer = {}
          detail.button_answer = {
            is_carousel: false,
          }
        })
    }
  }

  // eslint-disable-next-line
  async onDragEnd(evt: any, finalList: IMessageDetail[]) {
    if (!evt.moved) {
      return
    }

    const record = evt.moved.element
    calcOrderingDragend(record, finalList)
  }

  async created() {
    this.value.forEach((element) => {
      if (!element.images) {
        element.images = []
      }
      if (!element.imagemap_answer) {
        element.imagemap_answer = {}
      }
      if (!element.button_answer) {
        element.button_answer = {
          is_carousel: false,
        }
      } else {
        if (!element.button_answer.is_carousel) {
          element.button_answer.is_carousel = false
        }
      }
      if (!element.message) {
        element.message = ''
      }
    })
  }
}
</script>

<style scoped lang="scss">
:deep(.q-item__section--avatar) {
  min-width: 20px;
}
</style>
