
import { Options, Vue } from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'
import { maska } from 'maska'

import { IImagemapAnswer, IImageUrlSize, IMedia, IUploadFileMultiSizesResponse } from '@/utils/types'
import UploadApi from '@/api/upload'
import UploadingProgress from '@/components/common/ui/UploadingProgress.vue'
import InputEmojiSelector from '@/components/common/ui/InputEmojiSelector.vue'
import { IMAGEMAP_MESSAGE } from '@/utils/constants'
import CropperImageSelector from '@/components/tappable-area/selectors/CropperImageSelector.vue'
import MediaUploader from '@/components/media-manager/MediaUploader.vue'
import CLoading from '@/components/common/ui/CLoading.vue'
import SettingTappableModal from '@/components/story/common/tappable-area/SettingTappableModal.vue'
import MediaDetailsModal from '@/components/media-manager/MediaDetailsModal.vue'

@Options({
  components: {
    MediaDetailsModal,
    SettingTappableModal,
    UploadingProgress,
    InputEmojiSelector,
    CropperImageSelector,
    MediaUploader,
    CLoading,
  },
  directives: { maska },
  emits: ['update:modelValue'],
})
export default class ImagemapSelector extends Vue {
  @Prop({})
  modelValue!: IImagemapAnswer

  @Prop()
  isURL!: boolean

  @Prop()
  isMessage!: boolean

  errorImageMessage = ''
  isValidArea = true
  modalVisible = false
  cropperImageModalVisible = false
  selectedIndex = -1
  componentKey = 0
  file: File | null = null
  cropFiles: File[] = []
  loading = false
  modalMediaVisible = false
  selectedMedia = {
    url: '',
    name: '',
  }

  get requiredRule() {
    return {
      altText: this.$t('validate.this_field_is_required', {
        placeholder: this.$t('label.common.answer.alt_text'),
      }),
      messageImage: this.$t('validate.this_field_is_required', {
        placeholder: this.$t('label.common.messages.image'),
      }),
      settingTappableAreas: this.$t('validate.this_field_is_required', {
        placeholder: this.$t('label.common.answer.setting_tappable_areas'),
      }),
    }
  }

  get value() {
    return this.modelValue
  }

  set value(value: IImagemapAnswer) {
    this.$emit('update:modelValue', value)
  }

  get getDefaultSize() {
    if (this.value.image_url_sizes && this.value.image_url_sizes) {
      return this.value.image_url_sizes.find((item) => item.width === IMAGEMAP_MESSAGE.IMAGE_DEFAULT_SIZE)
    }
  }

  get imageUrlSizes() {
    if (this.value && this.value.image_url_sizes) {
      return this.value.image_url_sizes.sort((a, b) => {
        const widthA = a.width || 0
        const widthB = b.width || 0
        return widthB - widthA
      })
    }
    return []
  }

  get hasImages() {
    const images = this.value.image_url_sizes?.filter((item) => {
      if (item.image_url === '') {
        return false
      }
      return true
    })
    if (!images) {
      return false
    }
    return images?.length > 0
  }

  async onSelectMedia(media: IMedia, indexImagemap: number) {
    this.loading = true
    this.selectedIndex = indexImagemap
    if (this.value.image_url_sizes) {
      let selctedSize = this.value.image_url_sizes[this.selectedIndex]
      const baseUrl = process.env.VUE_APP_API_ENDPOINT
      const proxyUrl = `${baseUrl}v1/proxy_image?url=` + encodeURIComponent(media.url ?? '')
      const response = await fetch(proxyUrl)
      // const response = await fetch(media.url ?? '')
      const blob = await response.blob()
      const fileName = media.name ?? '' // Replace with your desired filename
      const file = new File([blob], fileName, { type: blob.type })

      const uploaded: IUploadFileMultiSizesResponse = await UploadApi.uploadFileMultiWidth(
        file,
        this.value.is_apply_all_device ?? true,
        this.value.folder_id ?? '',
        selctedSize?.width ?? 0
      )
      if (uploaded) {
        if (this.value.is_apply_all_device) {
          this.value.image_url_sizes = uploaded.image_url_sizes
        } else {
          if (uploaded.image_url_sizes && this.value.image_url_sizes) {
            selctedSize = uploaded.image_url_sizes[0]
            this.value.image_url_sizes.forEach((item) => {
              if (item.width === selctedSize.width) {
                item.image_url = uploaded.base_url + '/' + item.width
                item.height = selctedSize.height
              }
            })
          }
        }
        this.value.base_url = uploaded.base_url
        this.value.folder_id = uploaded.folder_id
        this.componentKey++
      }
    }

    this.loading = false
  }

  // async validateImageWidth(file: File) {
  //   const selectedSize = IMAGEMAP_MESSAGE.IMAGE_SIZES[this.selectedIndex]
  //   return new Promise<boolean>((resolve) => {
  //     const img = new Image()
  //     img.src = window.URL.createObjectURL(file)
  //     img.onload = (event: Event) => {
  //       const data = event.target as HTMLImageElement
  //       if (data.width !== selectedSize) {
  //         this.errorImageMessage = this.$t('validate.image_width_size_imagemap')
  //         resolve(false)
  //       }

  //       resolve(true)
  //     }
  //   })
  // }

  async validateMaxFileSize(file: File) {
    this.errorImageMessage = ''

    if (file && file.size > IMAGEMAP_MESSAGE.MAX_FILE_SIZE) {
      this.errorImageMessage = this.$t('validate.file_too_big', {
        placeholder: file.name,
      })

      return false
    }
    return true
  }

  validate() {
    this.$refs.altText.showErrorMessage()
    this.isValidArea = true
    this.errorImageMessage = ''
    const images = this.value.image_url_sizes?.filter((item) => {
      if (item.image_url !== '') {
        return false
      }
      return true
    })
    if (images && images.length > 0) {
      this.errorImageMessage = this.requiredRule.messageImage
    }
    const tappableAreas = this.value.tappable_area?.filter((item) => {
      if (item.action_type !== '' && Math.ceil(item.width ?? 0) !== 0 && Math.ceil(item.height ?? 0) !== 0) {
        return false
      }
      return true
    })
    if (tappableAreas && tappableAreas.length > 0) {
      this.isValidArea = false
    }
    if (this.errorImageMessage !== '' || !this.isValidArea) {
      return false
    }
    return true
  }

  async submitFile(file: File) {
    console.log(file, 'submitFile file')

    try {
      if (this.value.image_url_sizes) {
        let selctedSize = this.value.image_url_sizes[this.selectedIndex]
        const uploaded: IUploadFileMultiSizesResponse = await UploadApi.uploadFileMultiWidth(
          file,
          this.value.is_apply_all_device ?? true,
          this.value.folder_id ?? '',
          selctedSize?.width ?? 0
        )
        if (uploaded) {
          if (this.value.is_apply_all_device) {
            this.value.image_url_sizes = uploaded.image_url_sizes
          } else {
            if (uploaded.image_url_sizes && this.value.image_url_sizes) {
              selctedSize = uploaded.image_url_sizes[0]
              this.value.image_url_sizes.forEach((item) => {
                if (item.width === selctedSize.width) {
                  item.image_url = uploaded.base_url + '/' + item.width
                }
              })
            }
          }
          this.value.base_url = uploaded.base_url
          this.value.folder_id = uploaded.folder_id
          this.componentKey++
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  @Watch('modelValue', { immediate: true })
  showDefault() {
    if (!this.value || !this.value.image_url_sizes || this.value.image_url_sizes?.length === 0) {
      const imageSizes: IImageUrlSize[] = []
      IMAGEMAP_MESSAGE.IMAGE_SIZES.forEach((size) => {
        imageSizes.push({
          width: size,
          image_url: '',
        })
      })
      this.value = {
        _id: '',
        base_url: '',
        folder_id: '',
        is_apply_all_device: true,
        image_url_sizes: imageSizes,
        tappable_area: [],
      }
    }
  }

  @Watch('file')
  async handleUpload() {
    console.log('handleUpload')
    if (!this.file) {
      return false
    }

    if (!(await this.validateMaxFileSize(this.file))) {
      return false
    }

    this.loading = true
    // if (!(await this.validateImageWidth(this.file))) {
    //   await this.$q
    //     .dialog({
    //       title: this.$t('messages.confirm'),
    //       message: this.$t('messages.image_aspect_ratio_is_not_1_1', {
    //         ratio: '1:1',
    //       }),
    //       cancel: {
    //         flat: true,
    //         label: this.$t('messages.no'),
    //       },
    //       ok: {
    //         flat: true,
    //         label: this.$t('messages.yes'),
    //       },
    //       persistent: true,
    //     })
    //     .onOk(async () => {
    //       this.cropperImageModalVisible = true
    //     })
    //     .onCancel(async () => {
    //       this.onCloseModal()
    //     })
    // } else {
    //   await this.submitFile(this.file)
    //   this.file = null
    //   this.cropFiles = []
    // }
    await this.submitFile(this.file)
    this.file = null
    this.cropFiles = []

    this.loading = false
  }

  handleDisbaleSize(size: IImageUrlSize) {
    if (
      !this.value.is_apply_all_device ||
      (this.value.is_apply_all_device && size.width === IMAGEMAP_MESSAGE.IMAGEMAP_MAX_SIZE)
    ) {
      return true
    }

    return false
  }

  onRemoveImage(index: number) {
    if (this.value && this.value.image_url_sizes) {
      if (this.value.is_apply_all_device) {
        this.value.image_url_sizes.forEach((item) => {
          item.image_url = ''
        })
      } else {
        this.value.image_url_sizes[index].image_url = ''
      }

      this.componentKey++
    }
  }

  handleFocusImage(index: number) {
    if (this.value.image_url_sizes) {
      this.selectedIndex = index
    }
    console.log(this.selectedIndex, 'handleFocusImage')
  }

  onSetting() {
    this.modalVisible = true
  }

  onCloseModal() {
    this.modalVisible = false
    this.cropperImageModalVisible = false
    this.file = null
    this.cropFiles = []
    this.errorImageMessage = ''
  }

  async onCropImage(file) {
    this.cropperImageModalVisible = false
    this.cropFiles.push(file)
    await this.submitFile(file)
    this.onCloseModal()
  }

  onOpenMediaDetails(image) {
    this.selectedMedia.url = image.image_url
    this.selectedMedia.name = image.title ?? 'image'
    this.modalMediaVisible = true
  }
}
