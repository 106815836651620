import { render } from "./ScenariosMakerFrame.vue?vue&type=template&id=6fdbdeb8"
import script from "./ScenariosMakerFrame.vue?vue&type=script&lang=ts"
export * from "./ScenariosMakerFrame.vue?vue&type=script&lang=ts"

import "./ScenariosMakerFrame.vue?vue&type=style&index=0&id=6fdbdeb8&lang=scss"
script.render = render

export default script
import QMenu from 'quasar/src/components/menu/QMenu.js';
import QList from 'quasar/src/components/item/QList.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QCheckbox from 'quasar/src/components/checkbox/QCheckbox.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QMenu,QList,QItem,QItemSection,QIcon,QCheckbox,QBtn});
