
import { Options, mixins } from 'vue-class-component'
import { maska } from 'maska'
import {
  ICampaign,
  IInitialCampaign,
  IInitialCampaignDetail,
  IPopup,
  IPushCampaign,
  IPushCampaignDetail,
  IStory,
} from '@/utils/types'
import { Watch } from 'vue-property-decorator'
import cloneDeep from 'lodash/cloneDeep'
import {
  ACTION_CAMPAIGN,
  ACTION_GOAL,
  ACTION_INITIAL,
  ACTION_MESSAGES,
  ACTION_POPUP,
  ACTION_PUSH,
  ACTION_QUESTION,
  ACTION_STORY,
} from '@/store/actions'

import { constant, TRIGGER_TYPE } from '@/utils/constants'
import { randomString } from '@/utils/helpers'
import { ICard } from 'bot-flow-maker/src/types'
import CLoading from '@/components/common/ui/CLoading.vue'
import BaseFormMixin from '../common/mixins/BaseFormMixin.vue'
import PreviewSelectorModal from '@/components/scenarios/selectors/PreviewSelectorModal.vue'
import { Formater } from '@/utils/formater'
import TransmissionLogModal from '@/components/story/TransmissionLogModal.vue'

@Options({
  components: { PreviewSelectorModal, CLoading, TransmissionLogModal },
  directives: { maska },
  emits: [],
})
export default class StoryList extends mixins(BaseFormMixin) {
  loading = true
  modalPreviewVisible = false
  campaignIndex = 0
  campaignDetails: IInitialCampaignDetail[] | IPushCampaignDetail[] = []
  initialCampaigns: IInitialCampaign[] = []

  transmissionLogModalVisible = false
  selectedStory!: IStory

  get selectedAppId() {
    return this.$route.params.app_id
  }

  get stories() {
    return cloneDeep(this.$store.getters.stories) || []
  }

  @Watch('selectedAppId', { immediate: true })
  async appIdChanged() {
    if (!this.selectedAppId) {
      return
    }

    await this.$store.dispatch(ACTION_STORY.LOAD_ITEMS, {
      app_id: this.selectedAppId,
    })

    await this.$store.dispatch(ACTION_QUESTION.LOAD_ITEMS, {
      app_id: this.selectedAppId,
    })

    await this.$store.dispatch(ACTION_GOAL.LOAD_ITEMS, {
      app_id: this.selectedAppId,
    })

    await this.$store.dispatch(ACTION_MESSAGES.LOAD_ITEMS, {
      app_id: this.selectedAppId,
    })

    this.initialCampaigns = await this.$store.dispatch(ACTION_INITIAL.LOAD_ITEMS_WITHOUT_DETAIL, {
      app_id: this.selectedAppId,
    })

    this.loading = false
  }

  async updateIsActive(item: IStory, isTestMode: boolean) {
    let isSuccess = false
    if (isTestMode) {
      isSuccess = await this.$store.dispatch(ACTION_STORY.TEST_MODE, item)
    } else {
      isSuccess = await this.$store.dispatch(ACTION_STORY.IS_ACTIVE, item)
    }

    if (isSuccess) {
      this.$q.notify({
        message: this.$t('messages.saved'),
        color: 'positive',
      })
    }

    return isSuccess
  }

  async handleToggleActive(record: IStory) {
    if (record.is_active && record.trigger_type === TRIGGER_TYPE.DEFAULT) {
      const initial: IInitialCampaign = await this.$store.dispatch(ACTION_INITIAL.LOAD_STORY_ITEM, record._id)
      console.log(initial, 'initial')
      let message = ''
      if (
        initial &&
        (initial._id === '' || initial._id === undefined || initial.prod_detail?.scenarios?.length === 0)
      ) {
        message = this.$t('messages.display_an_alert_prompting_the_user_to_create_a_message')
      }

      const scenarios = initial.prod_detail?.scenarios
      if (scenarios) {
        for (const element of scenarios) {
          if (element.id !== 'start' && element.id === '') {
            message = this.$t('messages.display_an_alert_prompting_the_user_to_create_a_message')
            break
          }
        }
      }
      if (message === '') {
        const resp = await this.$store.dispatch(ACTION_STORY.VALIDATE_DEFAULT_TYPE, {
          app_id: record.app_id,
          is_test_mode: false,
        })
        // eslint-disable-next-line
        if (resp && resp['result']) {
          message = this.$t('messages.display_an_alert_that_only_one_can_be_active')
        }
      }
      if (message !== '') {
        this.$q.dialog({
          title: 'ご確認下さい',
          message: message,
          persistent: true,
          html: true,
          class: 'q-dialog-plugin-custom',
        })

        record.is_active = false
        return
      }
    }

    if (!record.is_active) {
      await this.updateIsActive(record, false)
    } else {
      this.$q
        .dialog({
          title: this.$t('messages.confirm'),
          message: this.$t('messages.are_you_sure_to_turn_on_active_for_this_item'),
          cancel: {
            flat: true,
            label: this.$t('messages.no'),
          },
          ok: {
            flat: true,
            label: this.$t('messages.yes'),
          },
          persistent: true,
        })
        .onOk(async () => {
          await this.updateIsActive(record, false)
        })
        .onCancel(async () => {
          record.is_active = false
        })
    }
  }

  async handleToggleTest(record: IStory) {
    if (record.is_test_mode && record.trigger_type === TRIGGER_TYPE.DEFAULT) {
      let message = ''
      const initial: IInitialCampaign = await this.$store.dispatch(ACTION_INITIAL.LOAD_STORY_ITEM, record._id)
      console.log(initial, 'initial')
      if (
        initial &&
        (initial._id === '' || initial._id === undefined || initial.test_detail?.scenarios?.length === 0)
      ) {
        message = this.$t('messages.display_an_alert_prompting_the_user_to_create_a_message')
      }

      const scenarios = initial.test_detail?.scenarios
      if (scenarios) {
        for (const element of scenarios) {
          if (element.id !== 'start' && element.id === '') {
            message = this.$t('messages.display_an_alert_prompting_the_user_to_create_a_message')
            break
          }
        }
      }
      if (message === '') {
        const resp = await this.$store.dispatch(ACTION_STORY.VALIDATE_DEFAULT_TYPE, {
          app_id: record.app_id,
          is_test_mode: true,
        })
        // eslint-disable-next-line
        if (resp && resp['result']) {
          message = this.$t('messages.display_an_alert_that_only_one_can_be_active')
        }
      }
      if (message !== '') {
        this.$q.dialog({
          title: 'ご確認下さい',
          message: message,
          persistent: true,
          html: true,
          class: 'q-dialog-plugin-custom',
        })

        record.is_test_mode = false
        return
      }
    }

    await this.updateIsActive(record, true)
  }

  onAdd() {
    this.goto('story_setting', {
      app_id: this.selectedAppId,
      action_type: constant.ACTION_TYPE.ADD,
    })
  }

  async onCopy(item: IStory) {
    this.loading = true
    const pushes: IPushCampaign[] = await this.$store.dispatch(ACTION_PUSH.LOAD_STORY_ITEMS, item._id)

    if (
      item.trigger_type === TRIGGER_TYPE.POPUP_DELIVER ||
      item.trigger_type === TRIGGER_TYPE.QR_CODE ||
      item.trigger_type === TRIGGER_TYPE.DEFAULT
    ) {
      if (item.trigger_type === TRIGGER_TYPE.POPUP_DELIVER || item.trigger_type === TRIGGER_TYPE.QR_CODE) {
        const campaign: ICampaign = await this.$store.dispatch(ACTION_CAMPAIGN.LOAD_STORY_ITEM, item._id)
        if (campaign._id !== '') {
          item.campaign = campaign
          const popups: IPopup[] = await this.$store.dispatch(ACTION_POPUP.LOAD_ITEMS, {
            app_id: this.appId,
            campaign_id: campaign._id,
            is_test_mode: this.isTestMode,
          })
          item.popups = popups
        }
      }

      const initial: IInitialCampaign = await this.$store.dispatch(ACTION_INITIAL.LOAD_STORY_ITEM, item._id)
      if (initial._id !== '') {
        item.initial = initial
      }

      item.pushes = pushes
    } else if (item.trigger_type === TRIGGER_TYPE.AUDIENCE) {
      if (pushes.length > 0) {
        item.push = pushes[0]
        item.pushes = pushes
      }
    }
    item._id = ''
    item.story_name = item.story_name + ' Copy'
    item.is_test_mode = false
    item.is_active = false
    item.created_at = undefined
    if (item.campaign) {
      item.campaign._id = ''
      item.campaign.campaign_name = item.campaign.campaign_name + ' Copy'
      item.campaign.story_id = ''
      item.campaign.is_active = false
      item.campaign.is_test_mode = false
    }
    if (item.initial) {
      item.initial._id = ''
      item.initial.story_id = ''
      item.initial.title = item.initial.title + ' Copy'
      if (item.initial.prod_detail) {
        item.initial.prod_detail = this.getDetail(item.initial.prod_detail)
      }
      if (item.initial.test_detail) {
        item.initial.test_detail = this.getDetail(item.initial.test_detail)
      }
    }
    if (item.push) {
      item.push._id = ''
      item.push.story_id = ''
      item.push.is_active = false
      item.push.is_test_mode = false
      item.push.title = item.push.title + ' Copy'
      if (item.push.prod_detail) {
        item.push.prod_detail = this.getDetail(item.push.prod_detail)
      }
      if (item.push.test_detail) {
        item.push.test_detail = this.getDetail(item.push.test_detail)
      }
    }

    if (item.popups) {
      for (const p of item.popups) {
        p._id = ''
        p.campaign_id = ''
      }
    }
    if (item.pushes) {
      for (const p of item.pushes) {
        p._id = ''
        p.story_id = ''
        p.is_active = false
        p.is_test_mode = false
        p.title = p.title + ' Copy'
        if (p.prod_detail) {
          p.prod_detail = this.getDetail(p.prod_detail)
        }
        if (p.test_detail) {
          p.test_detail = this.getDetail(p.test_detail)
        }
      }
    }

    const rsp = await this.$store.dispatch(ACTION_STORY.ADD, {
      ...item,
    })
    if (rsp) {
      this.goto('story_setting', {
        app_id: this.selectedAppId,
        story_id: rsp._id,
        action_type: constant.ACTION_TYPE.EDIT,
      })
    }
    this.loading = false
  }

  getDetail(detail: IInitialCampaignDetail | IPushCampaignDetail) {
    let scenarios = detail.scenarios
    if (scenarios) {
      for (const s of scenarios) {
        if (s.cardType !== 'start' && s.uniqueId !== 'welcome') {
          // eslint-disable-next-line dot-notation
          s['old_uniqueId'] = cloneDeep(s.uniqueId)
          s.uniqueId = randomString()
        }
      }

      scenarios = this.updateUniqueIdNextCard(scenarios)
    }

    detail.scenarios = scenarios
    return detail
  }

  updateUniqueIdNextCard(scenarios: ICard[]) {
    for (const scenario of scenarios) {
      for (const answer of scenario.answers) {
        for (const nextCard of answer.nextCards) {
          const uniqueId = this.getUniqueIdNextCard(scenarios, nextCard.uniqueId)
          if (uniqueId) {
            // eslint-disable-next-line dot-notation
            nextCard['old_uniqueId'] = cloneDeep(nextCard.uniqueId)
            nextCard.uniqueId = uniqueId
          }
        }
      }
    }
    return scenarios
  }

  getUniqueIdNextCard(scenarios: ICard[], uniqueId) {
    for (const scenario of scenarios) {
      // eslint-disable-next-line dot-notation
      if (scenario['old_uniqueId'] === uniqueId) {
        return scenario.uniqueId
      }
    }
    return null
  }

  onEdit(data: IStory) {
    this.goto('story_setting', {
      app_id: this.selectedAppId,
      story_id: data._id,
      action_type: constant.ACTION_TYPE.EDIT,
    })
  }

  async onPreview(story: IStory) {
    if (
      story.trigger_type === TRIGGER_TYPE.POPUP_DELIVER ||
      story.trigger_type === TRIGGER_TYPE.QR_CODE ||
      story.trigger_type === TRIGGER_TYPE.DEFAULT
    ) {
      const initial: IInitialCampaign = await this.$store.dispatch(ACTION_INITIAL.LOAD_STORY_ITEM, story._id)
      if (initial._id !== '' && initial.prod_detail) {
        this.campaignDetails = [...this.campaignDetails, initial.prod_detail]
      }
      const pushes: IPushCampaign[] = await this.$store.dispatch(ACTION_PUSH.LOAD_STORY_ITEMS, story._id)
      for (const push of pushes) {
        if (push.prod_detail) {
          this.campaignDetails = [...this.campaignDetails, push.prod_detail]
        }
      }
    }
    if (this.campaignDetails.length) {
      this.modalPreviewVisible = true
    }
  }

  onClosePreviewModal() {
    this.campaignDetails = []
    this.campaignIndex = 0
    this.modalPreviewVisible = false
  }

  onNextCampaign() {
    if (this.campaignIndex < this.campaignDetails.length - 1) {
      this.campaignIndex++
    }
  }

  goto(name: string, params = {}) {
    this.$router.push({
      name,
      params,
    })
  }

  numberWithCommas(num) {
    return Formater.numberFormat(num)
  }

  roundNumber(num) {
    return Formater.round(num)
  }

  onTransmissionLog(data) {
    this.transmissionLogModalVisible = true
    this.selectedStory = data
  }

  onCloseModal() {
    this.transmissionLogModalVisible = false
  }

  async onDelete(data: IStory) {
    this.$q
      .dialog({
        title: this.$t('confirm'),
        message: this.$t('are_you_sure_you_want_to_delete'),
        cancel: true,
        persistent: true,
      })
      .onOk(async () => {
        const success = await this.$store.dispatch(ACTION_STORY.DELETE, data._id)
        if (success) {
          this.$q.notify({
            message: this.$t('messages.deleted'),
            color: 'positive',
          })
        }
      })
  }
}
