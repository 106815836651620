
import { Vue, Options } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { maska } from 'maska'
import { IButtonAnswer, IButtonAnswerAction, ICarouselButton, IChat } from '@/utils/types'
import { constant, IMAGEMAP_MESSAGE } from '@/utils/constants'

@Options({
  components: {},
  directives: { maska },
  emits: ['update:handleAnswerClick'],
})
export default class ButtonsTemplatePreview extends Vue {
  @Prop()
  msg!: IChat

  activeSlide = 0
  imageId = 'button-template-image-x-' + Math.floor(Math.random() * 100)

  get filteredButtons() {
    console.log(this.button, 'this.button')

    const carouselImages: ICarouselButton[] = []
    if (this.msg.button_answer?.carousel_images) {
      for (const carousel of this.msg.button_answer.carousel_images) {
        if (!carousel.text || carousel.text === '' || !carousel.title || carousel.title === '') {
          continue
        }

        if (
          carousel.actions &&
          carousel.actions[0].type &&
          carousel.actions[0].type !== '' &&
          carousel.actions[0].content &&
          carousel.actions[0].content !== ''
        ) {
          carouselImages.push(carousel)
        }
      }
    }

    console.log(carouselImages, 'carouselImages')

    return carouselImages
  }

  get optionsSlides() {
    const opts: {
      label: number
      value: number
    }[] = []

    for (let index = 0; index < this.filteredButtons.length; index++) {
      opts.push({
        label: index + 1,
        value: index,
      })
    }

    return opts
  }

  get actions() {
    if (this.msg.button_answer) {
      return this.msg.button_answer.actions?.filter((p) => p.content !== undefined && p.content !== '')
    }
    return []
  }

  getCarouselActions(carousel: ICarouselButton) {
    if (carousel) {
      return carousel.actions?.filter((p) => p.content !== undefined && p.content !== '')
    }
    return []
  }

  handleAnswerClick(btn: IButtonAnswerAction) {
    if (btn.type === IMAGEMAP_MESSAGE.ACTION_TYPE.URI) {
      window.open(btn.content)
    } else if (
      btn.type === IMAGEMAP_MESSAGE.ACTION_TYPE.MESSAGE ||
      btn.type === IMAGEMAP_MESSAGE.ACTION_TYPE.POSTBACK
    ) {
      // const msg: IChat = {
      //   text: [btn.content ?? ''],
      //   sent: true,
      //   type: constant.ANSWER_TYPE.TEXT,
      // }
      this.$emit('update:handleAnswerClick', this.msg.uniqueId, btn._id ?? '', btn.content ?? '')
    }
  }

  mounted() {
    const img = document.querySelector('.' + this.imageId) as HTMLElement
    if (img && this.msg.button_answer?.image_background_color) {
      img.style.backgroundColor = this.msg.button_answer?.image_background_color
    }
  }
}
