<template>
  <CLoading :loading="loading" />
  <div class="row full-width q-mb-md test-mode">
    <TestModeSelector v-model="isTestMode" :isHideCopy="true" />
  </div>
  <div class="full-width q-pb-md row q-gutter-md">
    <q-card class="full-width">
      <q-card-section class="row q-gutter-sm">
        <q-select
          outlined
          dense
          option-value="_id"
          option-label="title"
          v-model="selectedFormId"
          :options="forms"
          emit-value
          map-options
          class="q-ma-md"
          style="width: 240px"
          :label="$t('label.form_select')"
        >
          <template v-slot:append v-if="selectedFormId">
            <q-icon name="close" @click.stop.prevent="selectedFormId = ''" class="cursor-pointer" />
          </template>
        </q-select>
      </q-card-section>
    </q-card>
  </div>
  <q-table
    bordered
    flat
    dense
    :title="$t('menu.form_report')"
    ref="welcomeList"
    :rows="reports"
    row-key="display_name"
    :rows-per-page-options="[10]"
    separator="cell"
    :no-data-label="$t('messages.no_data_available')"
    class="text-grey-8"
  >
    <template v-slot:top separator>
      <h2 class="text-h6 q-mr-md text-black">{{ $t('menu.form_report') }}</h2>
      <DatePicker
        :default-date-range="dateRange"
        :disable="false"
        @update:onChangeDateRange="changeDateRange"
      ></DatePicker>
      <q-space />
      <q-btn no-caps color="primary" class="q-ml-sm" icon="download" @click="onExportReport()">
        {{ $t('label.export_csv') }}
      </q-btn>
      <download-csv
        class="hidden"
        name="Form Report.csv"
        :data="jsonData"
        :fields="csvHeader.fields"
        :labels="csvHeader.labels"
        @update:exportFinished="exportFinished"
        ref="downloadCSV"
      >
        {{ $t('label.export_csv') }}</download-csv
      >
    </template>
    <template v-slot:header>
      <q-tr>
        <q-th key="updated_at">
          <span class="text-bold">{{ $t('label.updated_at') }}</span>
        </q-th>
        <q-th key="display_name">
          <span class="text-bold">{{ $t('label.audience_user.display_name') }}</span>
        </q-th>
        <q-th key="name_kanji">
          <span class="text-bold">{{ $t('label.common.form.form_field_type_name_kanji') }}</span>
        </q-th>
        <q-th key="name_hira">
          <span class="text-bold">{{ $t('label.common.form.form_field_type_name_hira') }}</span>
        </q-th>
        <q-th key="name_kana">
          <span class="text-bold">{{ $t('label.common.form.form_field_type_name_kana') }}</span>
        </q-th>
        <q-th key="phone_number">
          <span class="text-bold">{{ $t('label.common.form.form_field_type_phone_number_dash') }}</span>
        </q-th>
        <q-th key="phone_number_no_dash">
          <span class="text-bold">{{ $t('label.common.form.form_field_type_phone_number_no_dash') }}</span>
        </q-th>
        <q-th key="address">
          <span class="text-bold">{{ $t('label.common.form.form_field_type_address') }}</span>
        </q-th>
        <q-th key="postcode_dash">
          <span class="text-bold">{{ $t('label.common.form.form_field_type_postcode_dash') }}</span>
        </q-th>
        <q-th key="postcode_no_dash">
          <span class="text-bold">{{ $t('label.common.form.form_field_type_postcode_no_dash') }}</span>
        </q-th>
        <q-th key="email">
          <span class="text-bold">{{ $t('label.common.form.form_field_type_email') }}</span>
        </q-th>
        <q-th key="freetext">
          <span class="text-bold">{{ $t('label.common.form.form_field_type_freetext') }}</span>
        </q-th>
      </q-tr>
    </template>
    <template v-slot:body="props">
      <q-tr :props="props" :index="props.rowKey">
        <q-td key="updated_at">
          {{ props.row.updated_at }}
        </q-td>
        <q-td key="display_name">
          {{ props.row.display_name }}
        </q-td>
        <q-td key="name_kanji">
          {{ props.row.details.name_kanji }}
        </q-td>
        <q-td key="name_hira">
          {{ props.row.details.name_hira }}
        </q-td>
        <q-td key="name_kana">
          {{ props.row.details.name_kana }}
        </q-td>
        <q-td key="phone_number">
          {{ props.row.details.phone_number }}
        </q-td>
        <q-td key="phone_number_no_dash">
          {{ props.row.details.phone_number_no_dash }}
        </q-td>
        <q-td key="address">
          {{ props.row.details.address }}
        </q-td>
        <q-td key="postcode_dash">
          {{ props.row.details.postcode_dash }}
        </q-td>
        <q-td key="postcode_no_dash">
          {{ props.row.details.postcode_no_dash }}
        </q-td>
        <q-td key="email">
          {{ props.row.details.email }}
        </q-td>
        <q-td key="freetext">
          {{ props.row.details.freetext }}
        </q-td>
      </q-tr>
    </template>
  </q-table>
</template>

<script lang="ts">
import { Options, mixins } from 'vue-class-component'
import CLoading from '@/components/common/ui/CLoading.vue'
import TestModeSelector from '@/components/common/ui/TestModeSelector.vue'
import { Watch } from 'vue-property-decorator'
import { ACTION_ANSWER_REPORT, ACTION_FORM } from '@/store/actions'
import DatePicker from '@/components/common/ui/DatePicker.vue'
import { IDateRange, IFormReport, IFormReportCSV } from '@/utils/types'
import { lastNMonth, now } from '@/utils/datetime'
import JsonCSV from '@/components/common/ui/JsonCSV.vue'
import { CSVHeader } from '@/utils/csv'
import TagContent from '@/components/common/ui/TagContent.vue'
import BaseFormMixin from '../common/mixins/BaseFormMixin.vue'

@Options({
  components: {
    TagContent,
    DatePicker,
    TestModeSelector,
    CLoading,
    'download-csv': JsonCSV,
  },
})
export default class FormReport extends mixins(BaseFormMixin) {
  loading = true
  isTestMode = false
  dateRange: IDateRange = {
    from: '',
    to: '',
  }

  selectedFormId = ''

  reports: IFormReport[] = []

  csvHeader!: CSVHeader
  jsonData = [{}]

  get selectedAppId() {
    return this.$route.params.app_id
  }

  get forms() {
    return this.$store.getters.forms
  }

  @Watch('selectedAppId', { immediate: true })
  async updateApi() {
    if (!this.selectedAppId) {
      return
    }

    this.dateRange = {
      from: lastNMonth(1),
      to: now(),
    }
  }

  @Watch('isTestMode')
  @Watch('selectedFormId')
  handleChangeTargetSetting() {
    this.loadData()
  }

  async loadData() {
    this.loading = true
    let date_from
    let date_to
    if (this.dateRange) {
      if (this.dateRange.from === undefined) {
        date_from = this.dateRange
        date_to = this.dateRange
      } else {
        date_from = this.dateRange.from
        date_to = this.dateRange.to
      }
    }

    this.reports = await this.$store.dispatch(ACTION_ANSWER_REPORT.LOAD_USER_DETAILS, {
      app_id: this.selectedAppId,
      from_date: date_from,
      to_date: date_to,
      form_id: this.selectedFormId,
      is_test_mode: this.isTestMode,
    })

    this.loading = false
  }

  changeDateRange(value) {
    this.dateRange = value

    this.loadData()
  }

  exportFinished() {
    this.loading = false
  }

  onExportReport() {
    this.loading = true
    const data: IFormReportCSV[] = []
    this.reports.forEach((item) => {
      data.push({
        updated_at: item.updated_at,
        display_name: item.display_name,
        name_kanji: item.details?.name_kanji,
        name_hira: item.details?.name_hira,
        name_kana: item.details?.name_kana,
        phone_number: item.details?.phone_number,
        phone_number_no_dash: item.details?.phone_number_no_dash,
        address: item.details?.address,
        postcode_dash: item.details?.postcode_dash,
        postcode_no_dash: item.details?.postcode_no_dash,
        email: item.details?.email,
        freetext: item.details?.freetext,
      })
    })
    this.jsonData = data
    this.$nextTick(() => {
      this.$refs.downloadCSV.generate()
    })
  }

  async created() {
    // Download CSV header
    this.csvHeader = new CSVHeader({
      updated_at: this.$t('label.updated_at'),
      display_name: this.$t('label.audience_user.display_name'),
      name_kanji: this.$t('label.common.form.form_field_type_name_kanji'),
      name_hira: this.$t('label.common.form.form_field_type_name_hira'),
      name_kana: this.$t('label.common.form.form_field_type_name_kana'),
      phone_number: this.$t('label.common.form.form_field_type_phone_number_dash'),
      phone_number_no_dash: this.$t('label.common.form.form_field_type_phone_number_no_dash'),
      address: this.$t('label.common.form.form_field_type_address'),
      postcode_dash: this.$t('label.common.form.form_field_type_postcode_dash'),
      postcode_no_dash: this.$t('label.common.form.form_field_type_postcode_no_dash'),
      email: this.$t('label.common.form.form_field_type_email'),
      freetext: this.$t('label.common.form.form_field_type_freetext'),
    })

    await this.$store.dispatch(ACTION_FORM.LOAD_ITEMS, {
      app_id: this.selectedAppId,
    })
  }
}
</script>

<style scoped lang="scss">
</style>
