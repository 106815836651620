import { render } from "./ImagemapSelector.vue?vue&type=template&id=bf7ef728&scoped=true"
import script from "./ImagemapSelector.vue?vue&type=script&lang=ts"
export * from "./ImagemapSelector.vue?vue&type=script&lang=ts"

import "./ImagemapSelector.vue?vue&type=style&index=0&id=bf7ef728&lang=scss&scoped=true"
script.render = render
script.__scopeId = "data-v-bf7ef728"

export default script
import QList from 'quasar/src/components/item/QList.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QToggle from 'quasar/src/components/toggle/QToggle.js';
import QItemLabel from 'quasar/src/components/item/QItemLabel.js';
import QAvatar from 'quasar/src/components/avatar/QAvatar.js';
import QFile from 'quasar/src/components/file/QFile.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import qInstall from "../../../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QList,QItem,QItemSection,QBtn,QToggle,QItemLabel,QAvatar,QFile,QIcon});
