import { render } from "./MessageSelector.vue?vue&type=template&id=375dfc7d&scoped=true"
import script from "./MessageSelector.vue?vue&type=script&lang=ts"
export * from "./MessageSelector.vue?vue&type=script&lang=ts"

import "./MessageSelector.vue?vue&type=style&index=0&id=375dfc7d&scoped=true&lang=scss"
script.render = render
script.__scopeId = "data-v-375dfc7d"

export default script
import QList from 'quasar/src/components/item/QList.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QBtnToggle from 'quasar/src/components/btn-toggle/QBtnToggle.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import qInstall from "../../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QList,QItem,QItemSection,QIcon,QBtnToggle,QBtn});
