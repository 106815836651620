<template>
  <q-dialog v-model="visible" full-height position="right">
    <q-card style="width: 1100px; max-width: 90vw">
      <q-toolbar>
        <q-toolbar-title> {{ $t('label.push.delivered_log') }} </q-toolbar-title>
        <q-btn flat round dense icon="close" v-close-popup />
      </q-toolbar>
      <q-card-section>
        <CLoading :loading="loading" />
        <div class="row full-width q-mb-md test-mode">
          <TestModeSelector v-model="isTestMode" :isHideCopy="true" />
        </div>
        <q-table
          flat
          dense
          bordered
          v-if="deliver_logs"
          :title="$t('label.push.delivered_logs_list')"
          :rows="deliver_logs"
          row-key="created_at"
          :rows-per-page-options="[10]"
          separator="cell"
          :no-data-label="$t('messages.no_data_available')"
          class="text-grey-8"
        >
          <template v-slot:header="props">
            <q-tr>
              <q-th key="created_at" rowspan="2" class="text-left" style="width: 130px"
                ><span class="text-bold">{{ $t('label.push.created_at') }}</span></q-th
              >
              <q-th key="accepted_time" rowspan="2" class="text-left" style="width: 130px"
                ><span class="text-bold">{{ $t('label.push.updated_at') }}</span></q-th
              >
              <q-th :props="props" key="target_count" rowspan="2" style="width: 100px"
                ><span class="text-bold">Group ID</span></q-th
              >
              <q-th :props="props" key="target_count" rowspan="2" style="width: 100px"
                ><span class="text-bold">{{ $t('label.audience_user.user_id') }}</span></q-th
              >
              <q-th :props="props" key="target_count" rowspan="2" style="width: 50px"
                ><span class="text-bold">{{ $t('label.push.number_of_try') }}</span></q-th
              >
              <q-th :props="props" key="success_count" rowspan="2" style="width: 50px"
                ><span class="text-bold">{{ $t('label.push.succeeded') }} </span></q-th
              >
              <q-th :props="props" key="failure_count" rowspan="2" style="width: 50px"
                ><span class="text-bold">{{ $t('label.push.failed') }} </span></q-th
              >
              <q-th :props="props" key="phase" rowspan="2" style="width: 50px"
                ><span class="text-bold">{{ $t('label.push.status') }} </span></q-th
              >
              <q-th :props="props" key="failed_description" rowspan="2"
                ><span class="text-bold text-center">{{ $t('label.push.log_message_from_LINE_API') }} </span></q-th
              >
            </q-tr>
          </template>
          <template v-slot:body="props">
            <q-tr :props="props">
              <q-td key="created_at" :props="props.index"> {{ formatDateTime(props.row.created_at) }} </q-td>
              <q-td key="accepted_time" :props="props">
                {{
                  checkDiffirent(props.row.created_at, props.row.accepted_time)
                    ? formatDateTime(props.row.accepted_time)
                    : ''
                }}
              </q-td>
              <q-td key="audience_group_id" :props="props" class="text-center">
                {{ props.row.audience_group_id }}
              </q-td>
              <q-td key="user_id" :props="props" class="text-center">
                {{ props.row.user_id }}
              </q-td>
              <q-td key="target_count" :props="props">
                {{ props.row.target_count }}
              </q-td>
              <q-td key="success_count" :props="props"> {{ props.row.success_count }} </q-td
              ><q-td key="failure_count" :props="props">
                {{ props.row.failure_count }}
              </q-td>
              <q-td key="phase" :props="props" class="text-center">
                {{ props.row.phase }}
              </q-td>
              <q-td key="failed_description" :props="props" class="text-center">
                {{ audienceFailed(props.row) }}
              </q-td>
            </q-tr>
          </template>
        </q-table>
        <div class="row no-wrap items-center q-mt-md">
          <q-space />
          <div class="q-gutter-sm">
            <q-btn flat color="white" text-color="black" @click="onCancel">{{ $t('close') }}</q-btn>
          </div>
        </div>
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script lang="ts">
import { Vue, Options } from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'
import { maska } from 'maska'

import { formatDateTime } from '@/utils/helpers'
import { DELIVER_LOG } from '@/store/actions'
import cloneDeep from 'lodash/cloneDeep'
import { IDeliverLog } from '@/utils/types'
import { CAMPAIGN_TYPE } from '@/utils/constants'
import CLoading from '@/components/common/ui/CLoading.vue'
import TestModeSelector from '@/components/common/ui/TestModeSelector.vue'

@Options({
  components: { formatDateTime, CLoading, TestModeSelector },
  directives: { maska },
  emits: ['update:closeModal'],
})
export default class DeliveredLogModal extends Vue {
  @Prop()
  pushId!: string

  @Prop()
  modalVisible!: boolean

  isTestMode = false

  formatDateTime = formatDateTime

  loading = true

  get visible() {
    return this.modalVisible
  }

  set visible(value) {
    this.$emit('update:closeModal', value)
  }

  deliver_logs: IDeliverLog[] = []

  checkDiffirent(to: string, from: string) {
    return to !== from ? 1 : 0
  }

  audienceFailed(row: IDeliverLog) {
    // https://developers.line.biz/en/reference/messaging-api/#status-codes
    // 429 Exceeded the number of free messages.
    if (row.error_code === 429) {
      const msg = this.$t('messages.failed_recipients_due_quota', {
        audienceFailed: row.failure_count,
      })
      return msg
    } else {
      return row.failed_description
    }
  }

  @Watch('$store.getters.deliver_logs')
  deliverLogs() {
    this.deliver_logs = cloneDeep(this.$store.getters.deliver_logs)
  }

  @Watch('isTestMode', { immediate: true })
  @Watch('pushId', { immediate: true })
  async appIdChanged() {
    if (!this.$route.params.app_id) {
      return
    }

    await this.$store.dispatch(DELIVER_LOG.LOAD_ITEMS, {
      app_id: this.$route.params.app_id,
      campaign_id: this.pushId,
      campaign_type: CAMPAIGN_TYPE.PUSH,
      is_test_mode: this.isTestMode,
    })

    this.loading = false
  }

  onCancel() {
    this.visible = false
  }
}
</script>
