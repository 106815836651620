import { render } from "./LIFFHowToSetupFormModal.vue?vue&type=template&id=2a46884b"
import script from "./LIFFHowToSetupFormModal.vue?vue&type=script&lang=ts"
export * from "./LIFFHowToSetupFormModal.vue?vue&type=script&lang=ts"

import "./LIFFHowToSetupFormModal.vue?vue&type=style&index=0&id=2a46884b&lang=scss"
script.render = render

export default script
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QToolbar from 'quasar/src/components/toolbar/QToolbar.js';
import QToolbarTitle from 'quasar/src/components/toolbar/QToolbarTitle.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QForm from 'quasar/src/components/form/QForm.js';
import QStepper from 'quasar/src/components/stepper/QStepper.js';
import QStep from 'quasar/src/components/stepper/QStep.js';
import QBadge from 'quasar/src/components/badge/QBadge.js';
import QStepperNavigation from 'quasar/src/components/stepper/QStepperNavigation.js';
import QSpace from 'quasar/src/components/space/QSpace.js';
import QInput from 'quasar/src/components/input/QInput.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QDialog,QCard,QToolbar,QToolbarTitle,QBtn,QCardSection,QForm,QStepper,QStep,QBadge,QStepperNavigation,QSpace,QInput});
