
import { Options, Vue } from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'
import { maska } from 'maska'
import CLoading from '@/components/common/ui/CLoading.vue'
import * as clipboard from 'clipboard-polyfill'

@Options({
  components: { CLoading },
  directives: { maska },
  emits: ['update:closeModal'],
})
export default class MediaDetailsModal extends Vue {
  @Prop()
  modalVisible!: boolean

  @Prop()
  mediaUrl!: string

  @Prop()
  mediaName!: string

  loading = true
  currentBlob: Blob | null = null
  mediaInfo = {
    type: 'png',
    size: 0,
    height: 0,
    width: 0,
  }

  get visible() {
    return this.modalVisible
  }

  set visible(value) {
    this.$emit('update:closeModal', value)
  }

  onCancel() {
    this.visible = false
  }

  async copyToClipBoard() {
    if (this.currentBlob) {
      if (this.currentBlob.type === 'image/png') {
        await clipboard.write([new clipboard.ClipboardItem({ [this.currentBlob.type]: this.currentBlob })])
      } else {
        const img = new Image()
        img.src = URL.createObjectURL(this.currentBlob)

        await new Promise((resolve, reject) => {
          img.onload = resolve
          img.onerror = reject
        })

        const canvas = document.createElement('canvas')
        canvas.width = img.width
        canvas.height = img.height

        const ctx = canvas.getContext('2d')
        if (ctx) {
          ctx.drawImage(img, 0, 0)
          const pngBlob = await new Promise((resolve) =>
            canvas.toBlob(resolve, 'image/png')
          )
          if (pngBlob instanceof Blob) {
            await clipboard.write([new clipboard.ClipboardItem({ [pngBlob.type]: pngBlob })])
          }
        }
      }
    }
  }

  async onDownload() {
    const baseUrl = process.env.VUE_APP_API_ENDPOINT
    const proxyUrl = `${baseUrl}v1/proxy_image?url=` + encodeURIComponent(this.mediaUrl)
    const response = await fetch(proxyUrl)
    // const response = await fetch(this.mediaUrl)
    const blob = await response.blob()
    const link = document.createElement('a')
    link.href = window.URL.createObjectURL(blob)
    link.download = this.mediaName
    link.click()
  }

  @Watch('mediaUrl')
  async getMediaInfo() {
    const image = new Image()
    image.src = this.mediaUrl
    await new Promise((resolve, reject) => {
      image.onload = resolve
      image.onerror = reject
    })

    this.mediaInfo.width = image.width
    this.mediaInfo.height = image.height

    try {
      const baseUrl = process.env.VUE_APP_API_ENDPOINT
      const proxyUrl = `${baseUrl}v1/proxy_image?url=` + encodeURIComponent(this.mediaUrl)
      const response = await fetch(proxyUrl)
      // const response = await fetch(this.mediaUrl)
      const blob = await response.blob()
      this.currentBlob = blob

      this.mediaInfo.type = blob.type
      // this.mediaInfo.size = blob.size / (1024 * 1024)
      this.mediaInfo.size = blob.size / 1024
      console.log(this.mediaInfo.size)
    } catch (error) {
      console.error('Error loading image', error)
    }
  }

  async created() {
    this.loading = true
    await this.getMediaInfo()
    this.loading = false
  }
}
