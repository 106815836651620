<template>
  <div class="images q-mb-sm">
    <q-carousel
      v-model="activeSlide"
      transition-prev="slide-right"
      transition-next="slide-left"
      animated
      control-color="primary"
      class="rounded-borders"
      style="border-radius: 5px; height: auto"
    >
      <q-carousel-slide
        v-for="(carousel, index) in images"
        :key="index"
        :name="index"
        class="q-mb-sm q-pa-none"
        style="display: flex; flex-direction: column; align-items: stretch; height: auto"
      >
        <img
          :src="carousel.image_url"
          style="width: 270px"
          :class="carousel.click_url !== '' ? 'cursor-pointer' : ''"
          @click="handleClickImage(carousel.click_url)"
        />

        <div class="row justify-center">
          <q-btn-toggle glossy v-model="activeSlide" :options="optionsSlides" />
        </div>
      </q-carousel-slide>
    </q-carousel>
  </div>
</template>

<script lang="ts">
import { Vue, Options } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { maska } from 'maska'
import { DetailImageType } from '@/utils/types'

@Options({
  components: {},
  directives: { maska },
  emits: [],
})
export default class ImageCarouselColumn extends Vue {
  @Prop()
  images!: DetailImageType[]

  activeSlide = 0

  get optionsSlides() {
    const opts: {
      label: number
      value: number
    }[] = []

    for (let index = 0; index < this.images.length; index++) {
      opts.push({
        label: index + 1,
        value: index,
      })
    }

    return opts
  }

  handleClickImage(url) {
    if (url !== '') {
      window.open(url)
    }
  }
}
</script>
<style lang="scss" scoped>
@import '@/styles/quasar.variables.scss';

.images {
  max-width: 270px;
  padding-top: 8px;
  // overflow-x: auto;
  white-space: nowrap;
  overflow-x: scroll;
  overflow-y: hidden;
  will-change: transform;
  transition: all 0.2s;
}
</style>
