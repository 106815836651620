
import { Vue, Options } from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'
import { maska } from 'maska'
import cloneDeep from 'lodash/cloneDeep'
import { ICard } from 'bot-flow-maker/src/types'
import { EScenarioResourceType, IDateRange } from '@/utils/types'
import DatePicker from '@/components/common/ui/DatePicker.vue'

@Options({
  components: { DatePicker },
  directives: { maska },
  emits: [
    'update:modelValue',
    'update:onReUndoCards',
    'update:openSelectNextQuestion',
    'update:openSelectNextMessage',
    'update:openSelectNextGoal',
    'update:openSelectNextForm',
    'update:handleZoomInOut',
    'update:onChangeDirection',
    'update:onTransmissionLog',
    'update:onOpenPreview',
    'update:onOpenHistory',
    'update:initSessionScenarios',
  ],
})
export default class ScenarioHeader extends Vue {
  @Prop()
  modelValue!: IDateRange

  @Prop()
  currentScenario!: ICard[]

  @Prop()
  isReport!: boolean

  @Prop()
  titleLastHistory!: string

  sessionScenarios: ICard[][] = []
  currentIndex = 0
  isChanged = true
  currentDirection = '0'
  zoomLevel = 100
  ZOOM_SPEED = 10
  assetMenuShowing = false

  get displayZoom() {
    return Math.round(this.zoomLevel)
  }

  get value() {
    return this.modelValue
  }

  set value(value: IDateRange) {
    this.$emit('update:modelValue', value)
  }

  get isUndo() {
    return this.currentIndex > 0
  }

  get isRedo() {
    return this.currentIndex > 0 && this.currentIndex < this.sessionScenarios.length - 1
  }

  get appId() {
    return this.$route?.params?.appId
  }

  get campaignId() {
    return this.$route?.params?.campaignId
  }

  get isTestMode() {
    return this.$route?.params?.isTestMode === 'true'
  }

  changeDateRange(value) {
    this.value = value
  }

  @Watch('currentScenario')
  onChangeValue() {
    if (!this.isChanged || this.currentScenario.length <= 0) {
      this.isChanged = true
      return
    }
    this.sessionScenarios.push(cloneDeep(this.currentScenario))
    this.currentIndex = this.sessionScenarios.length - 1
  }

  @Watch('currentDirection')
  onChangeDirection() {
    this.$emit('update:onChangeDirection', this.currentDirection)
  }

  onUndo() {
    this.currentIndex = this.currentIndex - 1
    this.isChanged = false
    this.$emit('update:onReUndoCards', this.sessionScenarios[this.currentIndex])
  }

  onRedo() {
    this.currentIndex = this.currentIndex + 1
    this.isChanged = false
    this.$emit('update:onReUndoCards', this.sessionScenarios[this.currentIndex])
  }

  onZoomIn() {
    if (this.zoomLevel > 200) {
      this.zoomLevel = 200
    }
    this.zoomLevel = this.zoomLevel + this.ZOOM_SPEED
    this.$emit('update:handleZoomInOut', this.zoomLevel, 'in')
  }

  onZoomOut() {
    this.zoomLevel = this.zoomLevel - this.ZOOM_SPEED
    if (this.zoomLevel < 0) {
      this.zoomLevel = 0
    }
    this.$emit('update:handleZoomInOut', this.zoomLevel, 'out')
  }

  onExpand(side) {
    this.currentScenario.forEach((element) => {
      const card = document.getElementById(element.uniqueId) as HTMLElement
      const style = window.getComputedStyle(card)
      // eslint-disable-next-line no-undef
      const matrix = new WebKitCSSMatrix(style.transform)
      let transformX = matrix.m41
      let transformY = matrix.m42
      if (side === 'top') {
        transformY += 30
        element.top += 30
      } else {
        transformX += 30
        element.left += 30
      }
      card.style.transform = 'translate(' + transformX + 'px, ' + transformY + 'px)'
    })
    this.updateCardLine(side)
    this.onChangeValue()
  }

  updateCardLine(side) {
    const lines = document.getElementsByClassName('leader-line')
    for (let i = 0; i < lines.length; i++) {
      const line = document.getElementById(lines[i].id) as HTMLElement
      let top = parseFloat(line.style.top.replace('px', ''))
      let left = parseFloat(line.style.left.replace('px', ''))
      if (side === 'top') {
        top += 30
        line.style.top = top + 'px'
      } else {
        left += 30
        line.style.left = left + 'px'
      }
    }
  }

  openSelectNextQuestion() {
    this.$emit('update:openSelectNextQuestion', {
      cardType: EScenarioResourceType.question,
    })
    this.assetMenuShowing = false
  }

  openSelectNextMessage() {
    this.$emit('update:openSelectNextMessage', {
      cardType: EScenarioResourceType.message,
    })
    this.assetMenuShowing = false
  }

  openSelectNextGoal() {
    this.$emit('update:openSelectNextGoal', {
      cardType: EScenarioResourceType.goal,
    })
    this.assetMenuShowing = false
  }

  openSelectNextForm() {
    this.$emit('update:openSelectNextForm', {
      cardType: EScenarioResourceType.form,
    })
    this.assetMenuShowing = false
  }

  onOpenHistory() {
    this.$emit('update:onOpenHistory')
  }

  onTransmissionLog() {
    this.$emit('update:onTransmissionLog')
  }

  onOpenPreview() {
    this.$emit('update:onOpenPreview')
  }

  setDirectionType(value) {
    this.currentDirection = value
  }

  setTileHistory(value) {
    this.directionType = value
    this.$refs.scenarioHeader.setDirectionType(value)
  }

  resetData() {
    this.sessionScenarios = []
    this.currentIndex = 0
    this.isChanged = true
    this.currentDirection = '0'
    this.zoomLevel = 100
  }

  handleUndo() {
    if (!this.isReport && this.isUndo) {
      this.onUndo()
    }
  }
}
