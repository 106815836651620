
import { Vue, Options } from 'vue-class-component'
import { maska } from 'maska'
import { Prop } from 'vue-property-decorator'
import TagCodeSingle from '@/components/common/ui/TagCodeSingle.vue'
import TagCode from '@/components/common/ui/TagCode.vue'
import { ICarouselButton } from '@/utils/types'
import draggable from 'vuedraggable'
import { calcOrderingDragend } from '@/utils/helpers'

@Options({
  components: { draggable, TagCode, TagCodeSingle },
  directives: { maska },
  emits: ['update:closeModal', 'update:modelValue'],
})
export default class CarouselButtonListingModal extends Vue {
  @Prop()
  modalVisible!: boolean

  @Prop({})
  modelValue!: ICarouselButton[]

  @Prop()
  is_use_image!: string

  get visible() {
    return this.modalVisible
  }

  set visible(value) {
    this.$emit('update:closeModal', value)
  }

  get value() {
    return this.modelValue
  }

  set value(value: ICarouselButton[]) {
    this.$emit('update:modelValue', value)
  }

  filteredButton(button: ICarouselButton) {
    if (this.is_use_image === 'image' && (button.image_url === '' || !button.image_url)) {
      return false
    }
    if ((!button.text || button.text === '') && (!button.title || button.title === '')) {
      return false
    }

    if (
      !button.actions ||
      !button.actions[0].type ||
      button.actions[0].type === '' ||
      !button.actions[0].content ||
      button.actions[0].content === ''
    ) {
      return false
    }

    return true
  }

  // eslint-disable-next-line
  async onDragEnd(evt: any, finalList: ICarouselButton[]) {
    if (!evt.moved) {
      return
    }

    const record = evt.moved.element
    calcOrderingDragend(record, finalList)
  }

  onCancel() {
    this.visible = false
  }

  onSubmit() {
    this.visible = false
  }
}
