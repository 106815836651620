<template>
  <div>
    <CLoading :loading="loading" />
    <q-list class="imagemap-list" bordered separator :key="componentKey" v-if="value">
      <q-item>
        <q-item-section>
          <InputEmojiSelector
            v-model="value.alt_text"
            :placeholder="$t('label.common.answer.alt_text')"
            maxlength="400"
            :rules="[(val) => (val && val.length > 0) || requiredRule.altText]"
            ref="altText"
          />
        </q-item-section>
      </q-item>
      <q-item>
        <q-item-section>
          <div class="row items-center">
            <div class="column col-9">
              <label class="text-grey-9 text-bold">{{ $t('label.imagemap_requirements') }}</label>
              <span>{{ $t('label.imagemap_requirements_detail1') }}</span>
              <span>{{ $t('label.imagemap_requirements_detail2') }}</span>
            </div>
            <div class="column col">
              <q-btn
                no-caps
                class="btn-save"
                @click="onSetting()"
                :label="$t('label.common.answer.setting_tappable_areas')"
                v-if="hasImages"
              />
              <q-btn
                no-caps
                disable
                color="grey"
                @click="onSetting()"
                :label="$t('label.common.answer.setting_tappable_areas')"
                v-else
              />
              <span v-if="!isValidArea" class="q-pl-sm text-red">{{ requiredRule.settingTappableAreas }}</span>
            </div>
          </div>
        </q-item-section>
      </q-item>
      <q-item>
        <q-item-section>
          <div class="row q-gutter-sm">
            <q-toggle
              v-model="value.is_apply_all_device"
              val="value.is_apply_all_device"
              round
              :label="$t('label.common.answer.apply_for_all_devices')"
            />
            <q-item-label class="flex items-center" caption
              ><span>(縦横サイズ：240px, 300px, 460px, 700px, 1040px)</span></q-item-label
            >
          </div>
        </q-item-section>
      </q-item>
      <template v-for="(size, index) in imageUrlSizes" :key="index">
        <q-item v-if="handleDisbaleSize(size)">
          <q-item-section>
            <label class="text-grey-9">{{ $t('label.common.answer.image_width') }}: {{ size.width }}px</label>
            <span v-if="errorImageMessage !== '' && size.image_url === ''">
              <div class="text-red">{{ errorImageMessage }}</div>
            </span>
          </q-item-section>
          <q-item-section>
            <div class="row q-gutter-sm items-center" v-if="!value.is_apply_all_device || size.image_url">
              <img
                :src="size.image_url"
                v-if="size.image_url"
                @click="onOpenMediaDetails(size)"
                style="width: 90px"
                class="cursor-pointer"
              />
              <q-avatar
                v-else
                class="dotted-border radius-10 default-img"
                size="90px"
                icon="image"
                text-color="black"
                style="width: 1.5em"
              />
              <q-btn
                size="sm"
                round
                flat
                color="white"
                text-color="red"
                icon="delete"
                class="dotted-border"
                @click="onRemoveImage(index)"
                v-if="size.image_url && handleDisbaleSize(size)"
                style="border-color: red"
              />
            </div>
            <div class="column items-center" style="width: 120px" v-else>
              <div class="flex items-center">
                <q-btn round flat color="white" text-color="black" icon="add" class="dotted-border" />
              </div>
              <div class="q-my-sm text-grey">画像を追加する</div>
            </div>
          </q-item-section>
          <q-item-section v-if="handleDisbaleSize(size)">
            <div class="column q-gutter-sm">
              <q-file
                :label="$t('label.common.messages.add_image')"
                outlined
                dense
                accept=".jpg, image/*"
                v-model="file"
                bg-color="btn-upload"
                label-color="btn-upload"
                class="q-file-btn"
                @focus="handleFocusImage(index)"
              >
              </q-file>
              <div>
                <MediaUploader
                  :is-crop-image="true"
                  :isStatic="true"
                  :isImagemap="true"
                  :stencilWidth="size.width"
                  :stencilHeight="size.height"
                  :indexImagemap="index"
                  :light-style="true"
                  @on-select-media="onSelectMedia"
                ></MediaUploader>
              </div>
            </div>
          </q-item-section>
        </q-item>
      </template>
      <template v-for="(size, index) in imageUrlSizes" :key="index">
        <q-item v-if="!handleDisbaleSize(size)">
          <q-item-section>
            <label class="text-grey-9">{{ $t('label.common.answer.image_width') }}: {{ size.width }}px</label>
            <span v-if="errorImageMessage !== '' && size.image_url === ''">
              <div class="text-red">{{ errorImageMessage }}</div>
            </span>
          </q-item-section>
          <q-item-section>
            <div></div>
          </q-item-section>
          <q-item-section class="items-center">
            <div class="row q-gutter-sm items-center">
              <img
                :src="size.image_url"
                v-if="size.image_url"
                @click="onOpenMediaDetails(size)"
                style="width: 90px"
                class="cursor-pointer"
              />
              <div class="column items-center" v-else>
                <q-avatar class="dotted-border radius-10 default-img" size="90px" icon="image" text-color="black" />
                <div class="q-mt-sm text-grey">自動リサイズ</div>
              </div>
            </div>
          </q-item-section>
        </q-item>
      </template>
    </q-list>
  </div>

  <UploadingProgress :files="cropFiles" />

  <SettingTappableModal
    v-if="modalVisible"
    :modalVisible="modalVisible"
    v-model="value.tappable_area"
    :defaultImage="getDefaultSize"
    :isURL="isURL"
    :isMessage="isMessage"
    @update:closeModal="onCloseModal"
  />
  <CropperImageSelector
    v-if="cropperImageModalVisible"
    :modalVisible="cropperImageModalVisible"
    :file="file"
    @update:closeModal="onCloseModal"
    @update:onCropImage="onCropImage"
    :aspectRatio="1"
  />
  <MediaDetailsModal
    v-if="selectedMedia.url"
    :modalVisible="modalMediaVisible"
    :mediaUrl="selectedMedia.url"
    :mediaName="selectedMedia.name"
    @update:closeModal="modalMediaVisible = false"
  ></MediaDetailsModal>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'
import { maska } from 'maska'

import { IImagemapAnswer, IImageUrlSize, IMedia, IUploadFileMultiSizesResponse } from '@/utils/types'
import UploadApi from '@/api/upload'
import UploadingProgress from '@/components/common/ui/UploadingProgress.vue'
import InputEmojiSelector from '@/components/common/ui/InputEmojiSelector.vue'
import { IMAGEMAP_MESSAGE } from '@/utils/constants'
import CropperImageSelector from '@/components/tappable-area/selectors/CropperImageSelector.vue'
import MediaUploader from '@/components/media-manager/MediaUploader.vue'
import CLoading from '@/components/common/ui/CLoading.vue'
import SettingTappableModal from '@/components/story/common/tappable-area/SettingTappableModal.vue'
import MediaDetailsModal from '@/components/media-manager/MediaDetailsModal.vue'

@Options({
  components: {
    MediaDetailsModal,
    SettingTappableModal,
    UploadingProgress,
    InputEmojiSelector,
    CropperImageSelector,
    MediaUploader,
    CLoading,
  },
  directives: { maska },
  emits: ['update:modelValue'],
})
export default class ImagemapSelector extends Vue {
  @Prop({})
  modelValue!: IImagemapAnswer

  @Prop()
  isURL!: boolean

  @Prop()
  isMessage!: boolean

  errorImageMessage = ''
  isValidArea = true
  modalVisible = false
  cropperImageModalVisible = false
  selectedIndex = -1
  componentKey = 0
  file: File | null = null
  cropFiles: File[] = []
  loading = false
  modalMediaVisible = false
  selectedMedia = {
    url: '',
    name: '',
  }

  get requiredRule() {
    return {
      altText: this.$t('validate.this_field_is_required', {
        placeholder: this.$t('label.common.answer.alt_text'),
      }),
      messageImage: this.$t('validate.this_field_is_required', {
        placeholder: this.$t('label.common.messages.image'),
      }),
      settingTappableAreas: this.$t('validate.this_field_is_required', {
        placeholder: this.$t('label.common.answer.setting_tappable_areas'),
      }),
    }
  }

  get value() {
    return this.modelValue
  }

  set value(value: IImagemapAnswer) {
    this.$emit('update:modelValue', value)
  }

  get getDefaultSize() {
    if (this.value.image_url_sizes && this.value.image_url_sizes) {
      return this.value.image_url_sizes.find((item) => item.width === IMAGEMAP_MESSAGE.IMAGE_DEFAULT_SIZE)
    }
  }

  get imageUrlSizes() {
    if (this.value && this.value.image_url_sizes) {
      return this.value.image_url_sizes.sort((a, b) => {
        const widthA = a.width || 0
        const widthB = b.width || 0
        return widthB - widthA
      })
    }
    return []
  }

  get hasImages() {
    const images = this.value.image_url_sizes?.filter((item) => {
      if (item.image_url === '') {
        return false
      }
      return true
    })
    if (!images) {
      return false
    }
    return images?.length > 0
  }

  async onSelectMedia(media: IMedia, indexImagemap: number) {
    this.loading = true
    this.selectedIndex = indexImagemap
    if (this.value.image_url_sizes) {
      let selctedSize = this.value.image_url_sizes[this.selectedIndex]
      const baseUrl = process.env.VUE_APP_API_ENDPOINT
      const proxyUrl = `${baseUrl}v1/proxy_image?url=` + encodeURIComponent(media.url ?? '')
      const response = await fetch(proxyUrl)
      // const response = await fetch(media.url ?? '')
      const blob = await response.blob()
      const fileName = media.name ?? '' // Replace with your desired filename
      const file = new File([blob], fileName, { type: blob.type })

      const uploaded: IUploadFileMultiSizesResponse = await UploadApi.uploadFileMultiWidth(
        file,
        this.value.is_apply_all_device ?? true,
        this.value.folder_id ?? '',
        selctedSize?.width ?? 0
      )
      if (uploaded) {
        if (this.value.is_apply_all_device) {
          this.value.image_url_sizes = uploaded.image_url_sizes
        } else {
          if (uploaded.image_url_sizes && this.value.image_url_sizes) {
            selctedSize = uploaded.image_url_sizes[0]
            this.value.image_url_sizes.forEach((item) => {
              if (item.width === selctedSize.width) {
                item.image_url = uploaded.base_url + '/' + item.width
                item.height = selctedSize.height
              }
            })
          }
        }
        this.value.base_url = uploaded.base_url
        this.value.folder_id = uploaded.folder_id
        this.componentKey++
      }
    }

    this.loading = false
  }

  // async validateImageWidth(file: File) {
  //   const selectedSize = IMAGEMAP_MESSAGE.IMAGE_SIZES[this.selectedIndex]
  //   return new Promise<boolean>((resolve) => {
  //     const img = new Image()
  //     img.src = window.URL.createObjectURL(file)
  //     img.onload = (event: Event) => {
  //       const data = event.target as HTMLImageElement
  //       if (data.width !== selectedSize) {
  //         this.errorImageMessage = this.$t('validate.image_width_size_imagemap')
  //         resolve(false)
  //       }

  //       resolve(true)
  //     }
  //   })
  // }

  async validateMaxFileSize(file: File) {
    this.errorImageMessage = ''

    if (file && file.size > IMAGEMAP_MESSAGE.MAX_FILE_SIZE) {
      this.errorImageMessage = this.$t('validate.file_too_big', {
        placeholder: file.name,
      })

      return false
    }
    return true
  }

  validate() {
    this.$refs.altText.showErrorMessage()
    this.isValidArea = true
    this.errorImageMessage = ''
    const images = this.value.image_url_sizes?.filter((item) => {
      if (item.image_url !== '') {
        return false
      }
      return true
    })
    if (images && images.length > 0) {
      this.errorImageMessage = this.requiredRule.messageImage
    }
    const tappableAreas = this.value.tappable_area?.filter((item) => {
      if (item.action_type !== '' && Math.ceil(item.width ?? 0) !== 0 && Math.ceil(item.height ?? 0) !== 0) {
        return false
      }
      return true
    })
    if (tappableAreas && tappableAreas.length > 0) {
      this.isValidArea = false
    }
    if (this.errorImageMessage !== '' || !this.isValidArea) {
      return false
    }
    return true
  }

  async submitFile(file: File) {
    console.log(file, 'submitFile file')

    try {
      if (this.value.image_url_sizes) {
        let selctedSize = this.value.image_url_sizes[this.selectedIndex]
        const uploaded: IUploadFileMultiSizesResponse = await UploadApi.uploadFileMultiWidth(
          file,
          this.value.is_apply_all_device ?? true,
          this.value.folder_id ?? '',
          selctedSize?.width ?? 0
        )
        if (uploaded) {
          if (this.value.is_apply_all_device) {
            this.value.image_url_sizes = uploaded.image_url_sizes
          } else {
            if (uploaded.image_url_sizes && this.value.image_url_sizes) {
              selctedSize = uploaded.image_url_sizes[0]
              this.value.image_url_sizes.forEach((item) => {
                if (item.width === selctedSize.width) {
                  item.image_url = uploaded.base_url + '/' + item.width
                }
              })
            }
          }
          this.value.base_url = uploaded.base_url
          this.value.folder_id = uploaded.folder_id
          this.componentKey++
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  @Watch('modelValue', { immediate: true })
  showDefault() {
    if (!this.value || !this.value.image_url_sizes || this.value.image_url_sizes?.length === 0) {
      const imageSizes: IImageUrlSize[] = []
      IMAGEMAP_MESSAGE.IMAGE_SIZES.forEach((size) => {
        imageSizes.push({
          width: size,
          image_url: '',
        })
      })
      this.value = {
        _id: '',
        base_url: '',
        folder_id: '',
        is_apply_all_device: true,
        image_url_sizes: imageSizes,
        tappable_area: [],
      }
    }
  }

  @Watch('file')
  async handleUpload() {
    console.log('handleUpload')
    if (!this.file) {
      return false
    }

    if (!(await this.validateMaxFileSize(this.file))) {
      return false
    }

    this.loading = true
    // if (!(await this.validateImageWidth(this.file))) {
    //   await this.$q
    //     .dialog({
    //       title: this.$t('messages.confirm'),
    //       message: this.$t('messages.image_aspect_ratio_is_not_1_1', {
    //         ratio: '1:1',
    //       }),
    //       cancel: {
    //         flat: true,
    //         label: this.$t('messages.no'),
    //       },
    //       ok: {
    //         flat: true,
    //         label: this.$t('messages.yes'),
    //       },
    //       persistent: true,
    //     })
    //     .onOk(async () => {
    //       this.cropperImageModalVisible = true
    //     })
    //     .onCancel(async () => {
    //       this.onCloseModal()
    //     })
    // } else {
    //   await this.submitFile(this.file)
    //   this.file = null
    //   this.cropFiles = []
    // }
    await this.submitFile(this.file)
    this.file = null
    this.cropFiles = []

    this.loading = false
  }

  handleDisbaleSize(size: IImageUrlSize) {
    if (
      !this.value.is_apply_all_device ||
      (this.value.is_apply_all_device && size.width === IMAGEMAP_MESSAGE.IMAGEMAP_MAX_SIZE)
    ) {
      return true
    }

    return false
  }

  onRemoveImage(index: number) {
    if (this.value && this.value.image_url_sizes) {
      if (this.value.is_apply_all_device) {
        this.value.image_url_sizes.forEach((item) => {
          item.image_url = ''
        })
      } else {
        this.value.image_url_sizes[index].image_url = ''
      }

      this.componentKey++
    }
  }

  handleFocusImage(index: number) {
    if (this.value.image_url_sizes) {
      this.selectedIndex = index
    }
    console.log(this.selectedIndex, 'handleFocusImage')
  }

  onSetting() {
    this.modalVisible = true
  }

  onCloseModal() {
    this.modalVisible = false
    this.cropperImageModalVisible = false
    this.file = null
    this.cropFiles = []
    this.errorImageMessage = ''
  }

  async onCropImage(file) {
    this.cropperImageModalVisible = false
    this.cropFiles.push(file)
    await this.submitFile(file)
    this.onCloseModal()
  }

  onOpenMediaDetails(image) {
    this.selectedMedia.url = image.image_url
    this.selectedMedia.name = image.title ?? 'image'
    this.modalMediaVisible = true
  }
}
</script>

<style lang="scss" scoped>
.text-caption-hint {
  font-size: 9px;
}
:deep(.default-img .q-icon) {
  font-size: 26px;
}
</style>
