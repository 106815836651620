import { render } from "./ChatMessageSelector.vue?vue&type=template&id=3ee83e60&scoped=true"
import script from "./ChatMessageSelector.vue?vue&type=script&lang=ts"
export * from "./ChatMessageSelector.vue?vue&type=script&lang=ts"

import "./ChatMessageSelector.vue?vue&type=style&index=0&id=3ee83e60&lang=scss&scoped=true"
script.render = render
script.__scopeId = "data-v-3ee83e60"

export default script
import QChatMessage from 'quasar/src/components/chat/QChatMessage.js';
import QAvatar from 'quasar/src/components/avatar/QAvatar.js';
import qInstall from "../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QChatMessage,QAvatar});
