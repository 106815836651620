<template>
  <div class="row q-col-gutter-lg q-pb-md">
    <div class="col">
      <div class="text-subtitle2 text-grey-8">期間選択</div>
      <div class="row no-wrap items-center">
        <DateRangeSelectorV2
          opens="right"
          :disable="false"
          v-model="dateRange"
          @update:onChangeDateRange="changeDateRange"
          class="full-width"
        ></DateRangeSelectorV2>
      </div>
      <div class="row q-col-gutter-xs q-mt-xs">
        <div class="col">
          <q-btn
            outline
            dense
            size="xs"
            color="grey-6"
            label="今日"
            class="full-width"
            @click="changeDate('today')"
            :class="currentDateType === 'today' ? 'btn-save-grey' : ''"
          />
        </div>
        <div class="col">
          <q-btn
            outline
            dense
            size="xs"
            color="grey-6"
            label="昨日"
            class="full-width"
            @click="changeDate('yesterday')"
            :class="currentDateType === 'yesterday' ? 'btn-save-grey' : ''"
          />
        </div>
        <div class="col">
          <q-btn
            outline
            dense
            size="xs"
            color="grey-6"
            label="今週"
            class="full-width"
            @click="changeDate('thisWeek')"
            :class="currentDateType === 'thisWeek' ? 'btn-save-grey' : ''"
          />
        </div>
        <div class="col">
          <q-btn
            outline
            dense
            size="xs"
            color="grey-6"
            label="前週"
            class="full-width"
            @click="changeDate('lastWeek')"
            :class="currentDateType === 'lastWeek' ? 'btn-save-grey' : ''"
          />
        </div>
        <div class="col">
          <q-btn
            outline
            dense
            size="xs"
            color="grey-6"
            label="今月"
            class="full-width"
            @click="changeDate('thisMonth')"
            :class="currentDateType === 'thisMonth' ? 'btn-save-grey' : ''"
          />
        </div>
        <div class="col">
          <q-btn
            outline
            dense
            size="xs"
            color="grey-6"
            label="前月"
            class="full-width"
            @click="changeDate('lastMonth')"
            :class="currentDateType === 'lastMonth' ? 'btn-save-grey' : ''"
          />
        </div>
      </div>
    </div>
    <div class="col">
      <div class="text-subtitle2 text-grey-8">コンテンツ選択</div>
      <div class="row">
        <q-select
          outlined
          dense
          option-value="_id"
          option-label="label"
          multiple
          emit-value
          map-options
          v-model="selectedResources"
          :options="resources"
          label="コンテンツ"
          class="full-width"
          clearable
        />
      </div>
      <div class="row q-col-gutter-xs q-mt-xs">
        <div class="col">
          <q-btn
            outline
            dense
            size="xs"
            color="grey-6"
            label="テキスト"
            class="full-width"
            @click="changeResource('message')"
            :class="currentResourceType === 'message' ? 'btn-save-grey' : ''"
          />
        </div>
        <div class="col">
          <q-btn
            outline
            dense
            size="xs"
            color="grey-6"
            label="Q/A"
            class="full-width"
            @click="changeResource('question')"
            :class="currentResourceType === 'question' ? 'btn-save-grey' : ''"
          />
        </div>
        <div class="col">
          <q-btn
            outline
            dense
            size="xs"
            color="grey-6"
            label="ゴール"
            class="full-width"
            @click="changeResource('goal')"
            :class="currentResourceType === 'goal' ? 'btn-save-grey' : ''"
          />
        </div>
        <div class="col">
          <q-btn
            outline
            dense
            size="xs"
            color="grey-6"
            :label="$t('menu.form')"
            class="full-width"
            @click="changeResource('form')"
            :class="currentResourceType === 'form' ? 'btn-save-grey' : ''"
          />
        </div>
      </div>
    </div>
    <div class="col">
      <div class="text-subtitle2 text-grey-8">ステータス選択</div>
      <div class="row">
        <q-select
          outlined
          dense
          option-value="code"
          option-label="label"
          multiple
          emit-value
          map-options
          v-model="selectedPhases"
          :options="phases"
          label="ステータス"
          class="full-width"
          clearable
        />
      </div>
      <div class="row q-col-gutter-xs q-mt-xs">
        <div class="col">
          <q-btn
            outline
            dense
            size="xs"
            color="grey-6"
            label="予定"
            class="full-width"
            @click="changePhase('waiting')"
            :class="currentPhase === 'waiting' ? 'btn-save-grey' : ''"
          />
        </div>
        <div class="col">
          <q-btn
            outline
            dense
            size="xs"
            color="grey-6"
            label="成功"
            class="full-width"
            @click="changePhase('succeeded')"
            :class="currentPhase === 'succeeded' ? 'btn-save-grey' : ''"
          />
        </div>
        <div class="col">
          <q-btn
            outline
            dense
            size="xs"
            color="grey-6"
            label="失敗"
            class="full-width"
            @click="changePhase('error')"
            :class="currentPhase === 'error' ? 'btn-save-grey' : ''"
          />
        </div>
      </div>
    </div>
    <div class="col">
      <!-- <div class="text-subtitle2 text-grey-8">ユーザー選択</div>
      <div class="row">
        <q-select
          outlined
          dense
          option-value="user_id"
          option-label="display_name"
          multiple
          emit-value
          map-options
          v-model="selectedUsers"
          :options="users"
          label="コンテンツ"
          class="full-width"
          clearable
        />
      </div> -->
      <div class="text-subtitle2 text-grey-8">ユーザー選択</div>
      <div class="row" @click="onOpenModal">
        <q-input readonly outlined dense v-model="defaultUsers" class="full-width">
          <template v-slot:append>
            <q-icon name="filter_none" />
          </template>
        </q-input>
      </div>
      <div class="row justify-end items-baseline q-gutter-xs q-mt-xs">
        <span class="text-subtitle2 text-grey-8 text-bold">
          {{ selectedUsers.length ? selectedUsers.length : totalUsers }} / {{ totalUsers }}
        </span>
        <span class="text-grey-8" style="font-size: 8px">名を選択中</span>
      </div>
    </div>
  </div>
  <UserSelectionModal
    :modalVisible="modalVisible"
    :users="users"
    :filterUsers="filterUsers"
    :totalUsers="totalUsers"
    :totalPages="totalPages"
    :totalFilterPages="totalFilterPages"
    :perPages="perPages"
    :loading="modalLoading"
    @update:closeModal="onCloseModal"
    @update:submitUsers="onSubmitModal"
    @update:loadUsers="loadUsers"
    @update:filterUsers="loadUsers"
  ></UserSelectionModal>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'
import { maska } from 'maska'
import { IDateRangePicker, IUser } from '@/utils/types'
import { ACTION_APP_USER } from '@/store/actions'
import DateRangeSelectorV2 from '@/components/common/ui/DateRangeSelectorV2.vue'
import UserSelectionModal from '@/components/story/common/selectors/UserSelectionModal.vue'
import dayjs from 'dayjs'

@Options({
  components: { DateRangeSelectorV2, UserSelectionModal },
  directives: { maska },
  emits: ['update:modelValue', 'update:queryParams'],
})
export default class TransmissionSelector extends Vue {
  @Prop({})
  resources!: []

  @Prop()
  appId!: string

  @Prop()
  isTestMode!: boolean

  dateRange: IDateRangePicker = {
    startDate: '',
    endDate: '',
  }

  currentDateType = ''
  selectedResources = []
  currentResourceType = ''
  selectedPhases = []
  currentPhase = ''
  selectedUsers: (string | undefined)[] = []
  phases = [
    {
      code: 'waiting',
      label: '予定',
    },
    {
      code: 'succeeded',
      label: '成功',
    },
    {
      code: 'error',
      label: '失敗',
    },
  ]

  modalVisible = false
  modalLoading = false
  defaultUsers = 'ユーザー'
  totalUsers = 0
  totalPages = 0
  totalFilterPages = 0
  perPages = 32
  users: IUser[] = []
  filterUsers: IUser[] = []
  firstLoad = false

  onOpenModal() {
    this.modalVisible = true
  }

  onCloseModal() {
    this.modalVisible = false
  }

  onSubmitModal(val: [], all) {
    if (all) {
      this.selectedUsers = []
    } else {
      this.selectedUsers = val
    }

    this.updateParams()
  }

  async loadUsers(filter) {
    this.modalLoading = true
    const response = await this.$store.dispatch(ACTION_APP_USER.LOAD_ITEMS, {
      app_id: this.appId,
      is_test_mode: this.isTestMode,
      filter: filter,
    })
    if (response) {
      if (filter.state === 'selected' || filter.state === 'unselected') {
        this.filterUsers = response.items
        this.totalFilterPages = response.total_pages
      } else {
        this.users = response.items
        this.totalPages = response.total_pages
      }
    }
    this.modalLoading = false
  }

  changeDate(type) {
    if (type === this.currentDateType) {
      this.currentDateType = ''
    } else {
      this.currentDateType = type
    }
  }

  changeResource(type) {
    if (type === this.currentResourceType) {
      this.currentResourceType = ''
    } else {
      this.currentResourceType = type
    }
  }

  changePhase(type) {
    if (type === this.currentPhase) {
      this.currentPhase = ''
    } else {
      this.currentPhase = type
    }
  }

  @Watch('currentDateType')
  setDateRange() {
    const today = new Date()
    const formattedToday = today.toLocaleDateString('en-ZA')

    switch (this.currentDateType) {
      case 'today': {
        this.dateRange.startDate = formattedToday
        this.dateRange.endDate = formattedToday
        break
      }
      case 'yesterday': {
        const yesterday = new Date(today)
        yesterday.setDate(today.getDate() - 1)
        this.dateRange.startDate = yesterday.toLocaleDateString('en-ZA')
        this.dateRange.endDate = yesterday.toLocaleDateString('en-ZA')
        break
      }
      case 'thisWeek': {
        const currentDayOfWeek = today.getDay()
        const adjustedDayOfWeek = currentDayOfWeek === 0 ? 7 : currentDayOfWeek
        const startOfWeek = new Date(today)
        startOfWeek.setDate(today.getDate() - adjustedDayOfWeek + 1)
        const endOfWeek = new Date(today)
        endOfWeek.setDate(today.getDate() + (7 - adjustedDayOfWeek))
        this.dateRange.startDate = startOfWeek.toLocaleDateString('en-ZA')
        this.dateRange.endDate = endOfWeek.toLocaleDateString('en-ZA')
        break
      }
      case 'lastWeek': {
        const currentDayOfWeek = today.getDay()
        const adjustedDayOfWeek = currentDayOfWeek === 0 ? 7 : currentDayOfWeek
        const startOfPreviousWeek = new Date(today)
        startOfPreviousWeek.setDate(today.getDate() - adjustedDayOfWeek - 6)
        const endOfPreviousWeek = new Date(today)
        endOfPreviousWeek.setDate(today.getDate() - adjustedDayOfWeek)
        this.dateRange.startDate = startOfPreviousWeek.toLocaleDateString('en-ZA')
        this.dateRange.endDate = endOfPreviousWeek.toLocaleDateString('en-ZA')
        break
      }
      case 'thisMonth': {
        const year = today.getFullYear()
        const month = today.getMonth()
        const startOfMonth = new Date(year, month, 1)
        const endOfMonth = new Date(year, month + 1, 0)
        this.dateRange.startDate = startOfMonth.toLocaleDateString('en-ZA')
        this.dateRange.endDate = endOfMonth.toLocaleDateString('en-ZA')
        break
      }
      case 'lastMonth': {
        const year = today.getFullYear()
        const month = today.getMonth()
        const startOfPreviousMonth = new Date(year, month - 1, 1)
        const endOfPreviousMonth = new Date(year, month, 0)
        this.dateRange.startDate = startOfPreviousMonth.toLocaleDateString('en-ZA')
        this.dateRange.endDate = endOfPreviousMonth.toLocaleDateString('en-ZA')
        break
      }
      default: {
        this.dateRange.startDate = ''
        this.dateRange.endDate = ''
        break
      }
    }
    this.updateParams()
  }

  changeDateRange(value) {
    this.dateRange = value
    this.currentDateType = ''
    this.updateParams()
  }

  formatDate(date: string) {
    if (date) {
      return dayjs(date).format('YYYY-MM-DD')
    }

    return ''
  }

  @Watch('currentResourceType')
  @Watch('selectedResources')
  @Watch('currentPhase')
  @Watch('selectedPhases')
  updateParams() {
    const params = {
      start_date: this.formatDate(this.dateRange.startDate),
      end_date: this.formatDate(this.dateRange.endDate),
      resource_type: this.currentResourceType,
      resources: this.selectedResources,
      phase_type: this.currentPhase,
      phases: this.selectedPhases,
      users: this.selectedUsers,
    }
    this.$emit('update:queryParams', params)
  }

  @Watch('isTestMode')
  async onChangeTestMode() {
    this.selectedUsers = []

    const all = await this.$store.dispatch(ACTION_APP_USER.LOAD_ITEMS, {
      app_id: this.appId,
      is_test_mode: this.isTestMode,
      filter: {},
    })

    if (all) {
      this.totalUsers = all.total_items
    }

    const filter = {
      page: 1,
      per_page: this.perPages,
    }
    await this.loadUsers(filter)
    this.updateParams()
  }

  async created() {
    this.changeDate('thisMonth')
    await this.onChangeTestMode()

    this.firstLoad = true
  }
}
</script>

<style scoped lang="scss">
.q-field--with-bottom {
  padding-bottom: 0;
}
.text-subtitle2 {
  font-size: 0.75rem;
}
.q-card {
  height: 100%;
}
.cursor-pointer {
  font-size: 15px;
  padding-top: 12px;
}
:deep(.q-field--outlined .q-field__control) {
  padding: 0 8px;
}
:deep(.q-select--multiple .q-field__native > span) {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}
:deep(.q-btn.full-width span) {
  font-size: 8px;
  line-height: 1.25rem;
  white-space: nowrap;
}
:deep(.q-radio--dense .q-radio__label) {
  font-size: 12px;
  line-height: 1.5rem;
  margin-top: 8px;
}
:deep(.smaller-input) {
  font-size: initial;
}
:deep(.date-select .q-field__append) {
  font-size: 24px;
}
:deep(.q-field--outlined.q-field--readonly .q-field__control:before) {
  border-style: solid;
}
</style>
