
import { Options, Vue } from 'vue-class-component'
import { maska } from 'maska'
import { Prop, Watch } from 'vue-property-decorator'
import cloneDeep from 'lodash/cloneDeep'
import { IButtonAnswer, IButtonAnswerAction, ICarouselButton, IMedia } from '@/utils/types'
import { IMAGEMAP_MESSAGE } from '@/utils/constants'
import MediaUploader from '@/components/media-manager/MediaUploader.vue'
import UploadingProgress from '@/components/common/ui/UploadingProgress.vue'
import CropperImageSelector from '@/components/tappable-area/selectors/CropperImageSelector.vue'
import UploadApi from '@/api/upload'
import { isValidUrl } from '@/utils/validators'
import CarouselButtonListingModal from '@/components/story/common/selectors/CarouselButtonListingModal.vue'
import MediaDetailsModal from '@/components/media-manager/MediaDetailsModal.vue'

@Options({
  components: { CarouselButtonListingModal, CropperImageSelector, UploadingProgress, MediaUploader, MediaDetailsModal },
  directives: { maska },
  emits: ['update:modelValue'],
})
export default class ButtonSelector extends Vue {
  @Prop({})
  modelValue!: IButtonAnswer

  @Prop()
  isQuestion!: boolean

  cropFiles: File[] = []
  cropperImageModalVisible = false
  file: File | null = null
  carouselIndexSelected = 0
  currentButton: IButtonAnswer | ICarouselButton = {}
  carouselButtonsModalVisible = false
  errorImageMessage = ''

  modalMediaVisible = false
  selectedMedia = {
    url: '',
    name: '',
  }

  get value() {
    const buttonAnswer: IButtonAnswer = this.modelValue
    if (!buttonAnswer.actions || (buttonAnswer.actions && buttonAnswer.actions.length === 0)) {
      buttonAnswer.actions = []
      for (let i = 0; i < 4; i++) {
        buttonAnswer.actions.push({
          type: IMAGEMAP_MESSAGE.ACTION_TYPE.POSTBACK,
        })
      }
    }
    if (!buttonAnswer.image_aspect_ratio) {
      buttonAnswer.image_aspect_ratio = 1.51
    }
    if (!buttonAnswer.image_size) {
      buttonAnswer.image_size = 'cover'
    }
    if (!buttonAnswer.carousel_images || (buttonAnswer.carousel_images && buttonAnswer.carousel_images.length === 0)) {
      buttonAnswer.carousel_images = []
      const carouselActions: IButtonAnswerAction[] = []
      for (let i = 0; i < 4; i++) {
        carouselActions.push({
          type: IMAGEMAP_MESSAGE.ACTION_TYPE.POSTBACK,
        })
      }
      for (let i = 0; i < 10; i++) {
        buttonAnswer.carousel_images.push({
          actions: carouselActions,
          image_aspect_ratio: 1.51,
          image_size: 'cover',
        })
      }
    }
    return buttonAnswer
  }

  set value(value: IButtonAnswer) {
    this.$emit('update:modelValue', value)
  }

  get requiredRule() {
    return {
      title: this.$t('validate.this_field_is_required', {
        placeholder: this.$t('label.common.answer.title'),
      }),
      text: this.$t('validate.this_field_is_required', {
        placeholder: this.$t('label.common.answer.text'),
      }),
      actionType: this.$t('validate.this_field_is_required', {
        placeholder: this.$t('label.common.answer.select_action_type'),
      }),
      actionLabel: this.$t('validate.this_field_is_required', {
        placeholder: this.$t('label.common.answer.label'),
      }),
      actionUri: this.$t('validate.this_field_is_required', {
        placeholder: this.$t('label.common.answer.uri'),
      }),
      actionMessage: this.$t('validate.this_field_is_required', {
        placeholder: this.$t('label.common.answer.message'),
      }),
    }
  }

  get options() {
    const options: [
      {
        id?: string
        title?: string
        icon?: string
      }
    ] = [{}]
    options.pop()
    options.push({
      id: IMAGEMAP_MESSAGE.ACTION_TYPE.URI,
      title: this.$t('label.common.answer.uri'),
      icon: 'http',
    })

    options.push({
      id: IMAGEMAP_MESSAGE.ACTION_TYPE.POSTBACK,
      title: 'メッセージ',
      icon: 'message',
    })
    // options.push({
    //   id: IMAGEMAP_MESSAGE.ACTION_TYPE.POSTBACK,
    //   title: 'Postback',
    //   icon: 'message',
    // })
    return options
  }

  get aspectRatios() {
    return [
      {
        label: 'レクタングル（1.51:1）',
        value: 1.51,
      },
      {
        label: 'スクエア（1:1）',
        value: 1,
      },
    ]
  }

  get imageSizes() {
    return [
      {
        label: 'カバー',
        value: 'cover',
      },
      {
        label: 'コンテイン',
        value: 'contain',
      },
    ]
  }

  get lineButtonTemplate() {
    return window.location.origin + '/img/line-button-template.png'
  }

  onOpenMediaDetails(image) {
    this.selectedMedia.url = image.image_url
    this.selectedMedia.name = image.title ?? 'image'
    this.modalMediaVisible = true
  }

  customIsValidUrl(url) {
    if (!isValidUrl(url)) {
      return false || this.requiredRule.actionUri
    }

    return true || ''
  }

  async validateImageWidth(file: File) {
    return new Promise<boolean>((resolve) => {
      const img = new Image()
      img.src = window.URL.createObjectURL(file)
      img.onload = (event: Event) => {
        const data = event.target as HTMLImageElement
        if (data.width / data.height !== this.currentButton.image_aspect_ratio) {
          this.errorImageMessage = this.$t('validate.image_width_size_imagemap')
          resolve(false)
        }

        resolve(true)
      }
    })
  }

  onCloseModal() {
    this.cropperImageModalVisible = false
    this.file = null
    this.cropFiles = []
  }

  onCloseCarouselButtonsModal() {
    this.carouselButtonsModalVisible = false
    this.handleCurrentButton()
  }

  async onCropImage(file) {
    this.cropperImageModalVisible = false
    this.cropFiles.push(file)
    await this.submitFile(file)
    this.onCloseModal()
  }

  async submitFile(file: File) {
    try {
      const uploaded = await UploadApi.uploadFile(file)
      if (uploaded) {
        this.currentButton.image_url = uploaded.file_url_org || ''
      }
    } catch (error) {
      console.log(error)
    }
  }

  async onSelectMedia(file: IMedia) {
    if (file) {
      this.currentButton.image_url = file.url
    }
  }

  onRemoveImage() {
    if (this.currentButton) {
      this.currentButton.image_url = ''
    }
  }

  @Watch('file')
  async handleUpload() {
    if (!this.file) {
      return false
    }

    try {
      if (!(await this.validateImageWidth(this.file))) {
        await this.$q
          .dialog({
            title: this.$t('messages.confirm'),
            message: this.$t('messages.image_aspect_ratio_is_not_1_1', {
              ratio: this.currentButton.image_aspect_ratio + ':1',
            }),
            cancel: {
              flat: true,
              label: this.$t('messages.no'),
            },
            ok: {
              flat: true,
              label: this.$t('messages.yes'),
            },
            persistent: true,
          })
          .onOk(async () => {
            this.cropperImageModalVisible = true
          })
          .onCancel(async () => {
            this.onCloseModal()
          })
      } else {
        this.cropFiles.push(this.file)
        await this.submitFile(this.file)
        this.file = null
        this.cropFiles = []
      }
    } catch (error) {
      console.log(error)
    }
  }

  @Watch('value.is_carousel')
  @Watch('carouselIndexSelected')
  handleCurrentButton() {
    if (this.value.is_carousel && this.value.carousel_images) {
      this.currentButton = cloneDeep(this.value.carousel_images[this.carouselIndexSelected])
    } else {
      this.currentButton = cloneDeep(this.value)
    }
  }

  @Watch('currentButton', { deep: true })
  handleCarouselButton() {
    if (this.value.is_carousel && this.value.carousel_images) {
      this.value.carousel_images[this.carouselIndexSelected] = cloneDeep(this.currentButton)
    } else {
      this.value = { ...this.value, ...cloneDeep(this.currentButton) }
    }
  }

  validate() {
    if (this.value.is_carousel && this.value.carousel_images) {
      let maxAction = 0
      let index = 0

      for (const carousel of this.value.carousel_images) {
        if (
          carousel &&
          carousel.actions &&
          carousel.text &&
          carousel.text !== '' &&
          carousel.title &&
          carousel.title !== ''
        ) {
          let current = 0
          for (const action of carousel.actions) {
            if ((!action.content || action.content === '') && (!action.label || action.label === '')) {
              continue
            }
            current++
          }

          if (index === 0) {
            maxAction = current
          } else {
            if (maxAction !== current) {
              this.errorImageMessage =
                'アクション数が統一されていません、アクション数を統一させたうえで保存してください。'
              return false
            }
          }
          index++
        }
      }
    }
    return true
  }

  mounted() {
    this.handleCurrentButton()
  }
}
