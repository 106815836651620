
import { Vue, Options } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { maska } from 'maska'
import { DetailImageType } from '@/utils/types'

@Options({
  components: {},
  directives: { maska },
  emits: [],
})
export default class ImageCarouselColumn extends Vue {
  @Prop()
  images!: DetailImageType[]

  activeSlide = 0

  get optionsSlides() {
    const opts: {
      label: number
      value: number
    }[] = []

    for (let index = 0; index < this.images.length; index++) {
      opts.push({
        label: index + 1,
        value: index,
      })
    }

    return opts
  }

  handleClickImage(url) {
    if (url !== '') {
      window.open(url)
    }
  }
}
