import { render } from "./CampaignPopup.vue?vue&type=template&id=54968db2&scoped=true"
import script from "./CampaignPopup.vue?vue&type=script&lang=ts"
export * from "./CampaignPopup.vue?vue&type=script&lang=ts"

import "./CampaignPopup.vue?vue&type=style&index=0&id=54968db2&scoped=true&lang=scss"
script.render = render
script.__scopeId = "data-v-54968db2"

export default script
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QForm from 'quasar/src/components/form/QForm.js';
import QAvatar from 'quasar/src/components/avatar/QAvatar.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QFile from 'quasar/src/components/file/QFile.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QField from 'quasar/src/components/field/QField.js';
import qInstall from "../../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QBtn,QForm,QAvatar,QInput,QFile,QIcon,QField});
