import { DELIVER_LOG } from './actions'
import { IDeliverLog } from '@/utils/types'
import deliverLogApi from '@/api/deliverLog'
import cloneDeep from 'lodash/cloneDeep'
import { localStore } from '@/utils/localstore'
import { constant } from '@/utils/constants'

type TState = {
  items: IDeliverLog[]
  loading: boolean
}

export default {
  state: {
    items: [],
    loading: false,
  },
  getters: {
    deliver_logs: (state: TState) => {
      return state.items as IDeliverLog[]
    },
  },
  mutations: {
    // [single]
    [DELIVER_LOG.SET_ITEMS]: (state: TState, { items }: { items: IDeliverLog[] }) => {
      state.items = cloneDeep(items) as IDeliverLog[]
    },
  },
  actions: {
    [DELIVER_LOG.LOAD_ITEMS]: async ({ commit }, params: IDeliverLog) => {
      // const key = constant.LOCAL_KEYS.DELIVER_LOGS + params.app_id + JSON.stringify(params)
      // let resp
      // resp = localStore.getWithExpiry(key)
      // if (!resp) {
      //   resp = await deliverLogApi.list(params)
      //   localStore.setWithExpiry(key, resp, constant.LOCAL_EXPIRED)
      // }
      // commit(DELIVER_LOG.SET_ITEMS, { items: resp })
      // return resp
      const resp = await deliverLogApi.list(params)
      commit(DELIVER_LOG.SET_ITEMS, { items: resp })
      return resp
    },
    [DELIVER_LOG.LOAD_STORY_ITEMS]: async ({ commit }, params: IDeliverLog) => {
      // const key = constant.LOCAL_KEYS.DELIVER_LOGS + params.app_id + JSON.stringify(params)
      // let resp
      // resp = localStore.getWithExpiry(key)
      // if (!resp) {
      //   resp = await deliverLogApi.list(params)
      //   localStore.setWithExpiry(key, resp, constant.LOCAL_EXPIRED)
      // }
      // commit(DELIVER_LOG.SET_ITEMS, { items: resp })
      // return resp
      const resp = await deliverLogApi.story_list(params)
      return resp
    },
  },
}
