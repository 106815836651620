<template>
  <div class="q-pa-md" v-if="selectedTemplate._id">
    <div class="flex flex-col content-center">
      <div class="row justify-center q-mb-lg">
        <q-toggle
          ref="addTabToggle"
          v-model="isAddTab"
          :label="$t('label.rich_menu.tab_menu')"
          @update:model-value="handleAddTabToggle"
        />
        <q-option-group v-if="isAddTab" v-model="isBottom" :options="tabOptions" color="primary" inline />
      </div>
      <div class="row justify-center q-mb-lg" v-if="isAddTab">
        <q-btn-group rounded>
          <q-btn color="primary" rounded icon="remove" @click="removeTab" />
          <q-btn color="primary" rounded :label="totalTab" />
          <q-btn color="primary" rounded icon-right="add" @click="addTab" />
        </q-btn-group>
      </div>
      <div class="flex justify-center">
        <div
          class="template-container"
          :style="
            'width: ' +
            selectedTemplate.width * scaleImage +
            'px; height: ' +
            selectedTemplate.height * scaleImage +
            'px;'
          "
        >
          <div
            v-for="(img, index) in selectedTemplate.content"
            :key="index"
            class="target"
            @click="selectContentComponent(img, index)"
            :class="index === currentIndex ? 'active' : ''"
            :style="
              'left: ' +
              img.x * scaleImage +
              'px; top: ' +
              img.y * scaleImage +
              'px; width: ' +
              img.width * scaleImage +
              'px; height: ' +
              img.height * scaleImage +
              'px; background-image: url(' +
              img.src +
              ')'
            "
          >
            <span>{{ index + 1 }}</span>
          </div>
        </div>
      </div>
      <div class="row justify-center q-mt-lg">
        <div class="q-mb-sm q-mr-lg">
          <q-file
            ref="fileRef"
            name="image_file"
            :label="$t('label.common.messages.add_image')"
            outlined
            dense
            accept=".png, .jpeg"
            v-model="file"
            bg-color="btn-upload"
            label-color="btn-upload"
            class="q-file-btn"
          >
            <template v-slot:prepend>
              <q-icon name="attach_file" size="xs" color="white" style="font-weight: bold" />
            </template>
          </q-file>
        </div>
        <MediaUploader
          :is-crop-image="true"
          :crop-ratio="aspectRatio.toFixed(2)"
          :aspect-ratio="aspectRatio"
          :is-static="true"
          :stencil-width="stencilWidth"
          :stencil-height="stencilHeight"
          @on-select-media="onSelectMedia"
        ></MediaUploader>
      </div>
    </div>
  </div>
  <div class="q-pa-md full-height flex flex-center justify-center" v-else>
    <q-icon size="lg" color="grey-6" name="dashboard_customize" />
  </div>

  <UploadingProgress :files="cropFiles" />
  <CropperImageSelector
    v-if="cropperImageModalVisible"
    :modalVisible="cropperImageModalVisible"
    :file="file"
    @update:closeModal="onCloseModal"
    @update:onCropImage="onCropImage"
    :aspectRatio="aspectRatio"
    :isStatic="true"
    :stencilWidth="stencilWidth"
    :stencilHeight="stencilHeight"
  />
</template>

<script lang="ts">
import { Vue, Options } from 'vue-class-component'
import MediaUploader from '@/components/media-manager/MediaUploader.vue'
import CropperImageSelector from '@/components/tappable-area/selectors/CropperImageSelector.vue'
import UploadingProgress from '@/components/common/ui/UploadingProgress.vue'
import { Prop, Watch } from 'vue-property-decorator'
import {
  IImageSize,
  IMedia,
  IOriginRichMenuTemplate,
  IRichMenuTemplate,
  IRichMenuTemplateCompact,
  IRichMenuTemplateComponent,
  ITappableArea,
} from '@/utils/types'
import { constant } from '@/utils/constants'
import richMenuTemplate from '@/utils/richmenu-template.json'
import originRichMenuTemplate from '@/utils/origin-richmenu-template.json'
import { ACTION_MEDIA } from '@/store/actions'
import UploadApi from '@/api/upload'
import mergeImages from 'merge-images'

@Options({
  components: { UploadingProgress, CropperImageSelector, MediaUploader, mergeImages },
  emits: ['update:selectedTemplate', 'onSubmitTemplate'],
})
export default class TemplateSettingForm extends Vue {
  @Prop()
  selectedTemplateCompact!: IRichMenuTemplateCompact

  @Prop()
  visible!: boolean

  templates: IRichMenuTemplate[] = richMenuTemplate

  isEdit = false
  isAddTab = false
  isBottom = false
  totalTab = 1
  aspectRatio = 1
  stencilWidth = 1
  stencilHeight = 1
  backgroundImage = ''
  currentIndex = 0
  selectedIndex = 0
  defaultTabPosition = 'top'
  scaleImage = constant.RICH_MENU_IMAGE.IMAGE_DEFAULT_SIZE / 2500

  cropFiles: File[] = []
  file: File | null = null
  cropperImageModalVisible = false

  selectedTemplate: IRichMenuTemplate = {}

  originTemplates: readonly IOriginRichMenuTemplate[] = Object.freeze(
    originRichMenuTemplate.map((template) => {
      return {
        ...template,
        content: Object.freeze([...(template.content ?? [])]), // Deep copy and freeze content array
      }
    })
  )

  componentFiles: {
    index: number
    file: File | null
    url: string
  }[] = []

  originComponents: {
    idx: number
    src: string
  }[] = []

  components: {
    menu: IRichMenuTemplateComponent[]
    block: IRichMenuTemplateComponent[]
  } = {
    menu: [],
    block: [],
  }

  tappableAreas: {
    menu: ITappableArea[]
    block: ITappableArea[]
  } = {
    menu: [],
    block: [],
  }

  get appId() {
    return this.$route.params.app_id
  }

  get tabOptions() {
    return [
      {
        label: this.$t('label.rich_menu.top_tab'),
        value: false,
      },
      {
        label: this.$t('label.rich_menu.bottom_tab'),
        value: true,
      },
    ]
  }

  handleAddTabToggle(val, evt) {
    console.log(evt)
    if (this.selectedTemplateCompact._id && (this.components.menu.length || this.components.block.length)) {
      this.isAddTab = !val
      this.$q
        .dialog({
          title: this.$t('messages.confirm'),
          message: this.$t('messages.are_you_sure_to_turn_on_active_for_this_item'),
          cancel: {
            flat: true,
            label: this.$t('messages.no'),
          },
          ok: {
            flat: true,
            label: this.$t('messages.yes'),
          },
          persistent: true,
        })
        .onOk(async () => {
          this.components.menu = []
          this.components.block = []
          this.originComponents = []
          this.isAddTab = val
        })
        .onCancel(async () => {
          this.isAddTab = !val
        })
    }
  }

  removeTab() {
    if (this.totalTab > 1) {
      if (this.selectedTemplateCompact._id && this.components.menu.length) {
        this.$q
          .dialog({
            title: this.$t('messages.confirm'),
            message: this.$t('messages.are_you_sure_to_turn_on_active_for_this_item'),
            cancel: {
              flat: true,
              label: this.$t('messages.no'),
            },
            ok: {
              flat: true,
              label: this.$t('messages.yes'),
            },
            persistent: true,
          })
          .onOk(async () => {
            this.components.menu = []
            if (this.components.block.length && this.defaultTabPosition === 'top') {
              this.components.block.forEach((item) => {
                item.index--
                const component = this.originComponents.find((comp) => comp.src === item.img)
                if (component) {
                  component.idx = item.index
                }
              })
            }
            this.originComponents = this.originComponents.filter((originComponent) => {
              return this.components.block.some((item) => item.img === originComponent.src)
            })
            this.totalTab--
          })
          .onCancel(async () => {
            console.log('do nothing')
          })
      } else {
        if (this.components.block.length && this.defaultTabPosition === 'top') {
          this.components.block.forEach((item) => {
            item.index--
            const component = this.originComponents.find((comp) => comp.src === item.img)
            if (component) {
              component.idx = item.index
            }
          })
        }
        this.originComponents = this.originComponents.filter((originComponent) => {
          return this.components.block.some((item) => item.img === originComponent.src)
        })
        this.totalTab--
      }
    }
  }

  addTab() {
    if (this.selectedTemplateCompact._id && this.components.menu.length) {
      this.$q
        .dialog({
          title: this.$t('messages.confirm'),
          message: this.$t('messages.are_you_sure_to_turn_on_active_for_this_item'),
          cancel: {
            flat: true,
            label: this.$t('messages.no'),
          },
          ok: {
            flat: true,
            label: this.$t('messages.yes'),
          },
          persistent: true,
        })
        .onOk(async () => {
          this.components.menu = []
          if (this.components.block.length && this.defaultTabPosition === 'top') {
            this.components.block.forEach((item) => {
              item.index++
              const component = this.originComponents.find((comp) => comp.src === item.img)
              if (component) {
                component.idx = item.index
              }
            })
          }
          this.originComponents = this.originComponents.filter((originComponent) => {
            return this.components.block.some((item) => item.img === originComponent.src)
          })
          this.totalTab++
        })
        .onCancel(async () => {
          console.log('do nothing')
        })
    } else {
      if (this.components.block.length && this.defaultTabPosition === 'top') {
        this.components.block.forEach((item) => {
          item.index++
          const component = this.originComponents.find((comp) => comp.src === item.img)
          if (component) {
            component.idx = item.index
          }
        })
      }
      this.originComponents = this.originComponents.filter((originComponent) => {
        return this.components.block.some((item) => item.img === originComponent.src)
      })
      this.totalTab++
    }
  }

  selectContentComponent(img, index) {
    this.aspectRatio = img.width / img.height
    this.stencilWidth = img.width
    this.stencilHeight = img.height
    this.currentIndex = index
  }

  async onSelectMedia(file: IMedia) {
    if (file && file.url && file.name) {
      const baseUrl = process.env.VUE_APP_API_ENDPOINT
      const proxyUrl = `${baseUrl}v1/proxy_image?url=` + encodeURIComponent(file.url)
      const response = await fetch(proxyUrl)
      // const response = await fetch(file.url)
      const blob = await response.blob()
      const mediaFile = new File([blob], file.name, { type: blob.type })
      this.cropFiles.push(mediaFile)
      await this.submitImage(mediaFile)
      this.file = null
      this.cropFiles = []
    }
  }

  onCloseModal() {
    this.cropperImageModalVisible = false
    this.file = null
    this.cropFiles = []
  }

  async onCropImage(file) {
    this.cropperImageModalVisible = false
    this.cropFiles.push(file)
    await this.submitImage(file)
    this.onCloseModal()
  }

  @Watch('file')
  async handleUpload() {
    if (!this.file) {
      return false
    }

    try {
      if (!(await this.validateInputFile(this.file))) {
        await this.$q
          .dialog({
            title: this.$t('messages.confirm'),
            message: this.$t('messages.image_aspect_ratio_is_not_1_1', {
              ratio: this.aspectRatio.toFixed(2),
            }),
            cancel: {
              flat: true,
              label: this.$t('messages.no'),
            },
            ok: {
              flat: true,
              label: this.$t('messages.yes'),
            },
            persistent: true,
          })
          .onOk(async () => {
            this.cropperImageModalVisible = true
          })
          .onCancel(async () => {
            this.onCloseModal()
          })
      } else {
        this.cropFiles.push(this.file)
        await this.submitImage(this.file)
        this.file = null
        this.cropFiles = []
      }
    } catch (error) {
      console.log(error)
    }
  }

  async validateInputFile(file: File) {
    if (file.size > constant.RICH_MENU_IMAGE.MAX_FILE_SIZE) {
      return false
    }

    let result = true
    if (file) {
      result = await this.validateImageSize(file)
    }

    return result
  }

  async validateImageSize(file: File) {
    return new Promise<boolean>((resolve) => {
      const img = new Image()
      img.src = window.URL.createObjectURL(file)
      img.onload = (event: Event) => {
        const data = event.target as HTMLImageElement
        if (data.width / data.height !== this.aspectRatio) {
          resolve(false)
        }
        resolve(true)
      }
    })
  }

  async submitImage(file: File) {
    const url = URL.createObjectURL(file)
    if (this.selectedTemplate.content) {
      this.selectedTemplate.content.forEach((el, i) => {
        if (i === this.currentIndex) {
          el.src = url
          const component = {
            index: i,
            file: file,
            url: url,
          }
          const indexToReplace = this.componentFiles.findIndex((item) => item.index === component.index)
          if (indexToReplace !== -1) {
            this.componentFiles[indexToReplace] = component
          } else {
            this.componentFiles.push(component)
          }
          this.addComponent(el, i)
        }
      })
    }
  }

  addComponent(element, index) {
    const contentNumber = this.originTemplates[this.selectedIndex].content?.length ?? 1
    let isMenu: boolean
    let currentIndex = 0
    if (!this.isBottom) {
      isMenu = index <= this.totalTab - 1
    } else {
      isMenu = index > contentNumber - 1
    }
    if (isMenu) {
      if (this.defaultTabPosition === 'top') {
        if (!this.isBottom) {
          currentIndex = index
        } else {
          currentIndex = index - contentNumber
        }
      } else {
        if (!this.isBottom) {
          currentIndex = index + contentNumber
        } else {
          currentIndex = index
        }
      }
      const idx = this.components.menu.findIndex((item) => item.index === currentIndex)
      if (idx !== -1) {
        this.components.menu[idx].img = element.src
      } else {
        this.components.menu.push({
          index: currentIndex,
          img: element.src,
        })
      }
    } else {
      if (this.defaultTabPosition === 'top') {
        if (!this.isBottom) {
          currentIndex = index
        } else {
          currentIndex = index + contentNumber
        }
      } else {
        if (!this.isBottom) {
          currentIndex = index - contentNumber
        } else {
          currentIndex = index
        }
      }
      const idx = this.components.block.findIndex((item) => item.index === currentIndex)
      if (idx !== -1) {
        this.components.block[idx].img = element.src
      } else {
        this.components.block.push({
          index: currentIndex,
          img: element.src,
        })
      }
    }
  }

  handleComponents(components) {
    const menu: IRichMenuTemplateComponent[] = []
    const block: IRichMenuTemplateComponent[] = []
    const contentNumber = this.originTemplates[this.selectedIndex].content?.length ?? 1
    if (this.isAddTab) {
      if (!this.isBottom) {
        components.forEach((item) => {
          if (item.index <= this.totalTab - 1) {
            menu.push(item)
          } else {
            block.push(item)
          }
        })
      } else {
        components.forEach((item) => {
          if (item.index >= contentNumber) {
            menu.push(item)
          } else {
            block.push(item)
          }
        })
      }
    } else {
      components.forEach((item) => {
        block.push(item)
      })
    }
    return { menu: menu, block: block }
  }

  handleTappableAreas(tappableAreas: ITappableArea[]) {
    const menu: ITappableArea[] = []
    const block: ITappableArea[] = []
    const contentNumber = this.originTemplates[this.selectedIndex].content?.length ?? 1
    if (this.isAddTab) {
      if (!this.isBottom) {
        tappableAreas.forEach((item, index) => {
          if (index <= this.totalTab - 1) {
            menu.push(item)
          } else {
            block.push(item)
          }
        })
      } else {
        tappableAreas.forEach((item, index) => {
          if (index >= contentNumber) {
            menu.push(item)
          } else {
            block.push(item)
          }
        })
      }
    } else {
      tappableAreas.forEach((item) => {
        block.push(item)
      })
    }
    return { menu: menu, block: block }
  }

  @Watch('selectedTemplateCompact')
  @Watch('visible')
  async setupTemplate() {
    if (this.selectedTemplateCompact._id) {
      this.isAddTab = this.selectedTemplateCompact.add_menu ? this.selectedTemplateCompact.add_menu : false
      this.totalTab = this.selectedTemplateCompact.total_menu ? this.selectedTemplateCompact.total_menu : 1
      this.isBottom = this.selectedTemplateCompact.bottom_menu ? this.selectedTemplateCompact.bottom_menu : false
      if (this.selectedTemplateCompact.components) {
        const components = this.selectedTemplateCompact.components.sort((a, b) => a.index - b.index)
        components.forEach((item) => {
          this.originComponents.push({
            idx: item.index,
            src: item.img,
          })
        })
        this.components = this.handleComponents(components)
      }
      if (this.selectedTemplateCompact.tappable_area) {
        this.tappableAreas = this.handleTappableAreas(this.selectedTemplateCompact.tappable_area)
      }
      this.isEdit = true
      this.defaultTabPosition = this.isBottom ? 'bottom' : 'top'
      this.handleSelectedTemplate()
    }
  }

  @Watch('isAddTab')
  @Watch('totalTab')
  @Watch('isBottom')
  handleSelectedTemplate() {
    this.selectedTemplate.content = [
      ...(this.originTemplates[this.selectedIndex]?.content ?? []).map((item) => ({ ...item })),
    ]
    this.currentIndex = 0
    if (this.selectedTemplate.content) {
      this.selectContentComponent(this.selectedTemplate.content[this.currentIndex], this.currentIndex)
    }

    if (this.isAddTab) {
      const templateWidth = this.selectedTemplate.width ? this.selectedTemplate.width : 0
      const templateHeight = this.selectedTemplate.height ? this.selectedTemplate.height : 0
      const templateNumberBlock = this.selectedTemplate.content.length
      const content: IImageSize[] = []
      let menuScale = 0
      let blockScale = 0
      if (this.defaultTabPosition === 'top') {
        blockScale = this.totalTab
      } else {
        menuScale = templateNumberBlock
      }
      if (!this.isBottom) {
        for (let index = 0; index <= this.totalTab - 1; index++) {
          const tab: IImageSize = {
            x: (index * templateWidth) / this.totalTab,
            y: 0,
            width: templateWidth / this.totalTab,
            height: constant.RICH_MENU_IMAGE.TAB_HEIGHT,
            rowspan: 1,
          }
          const menu = this.components.menu.find((item) => item.index - menuScale === index)
          if (menu) {
            tab.src = menu.img
          }
          content.push(tab)
        }
        if (this.selectedTemplate.content) {
          const totalRow = this.selectedTemplate.total_row ? this.selectedTemplate.total_row : 1
          this.selectedTemplate.content.forEach((item, index) => {
            const rowspan = item.rowspan ? item.rowspan : 1
            const y = item.y ? item.y : 0
            const height = item.height ? item.height : 0
            if (item.y === 0) {
              item.y = y + constant.RICH_MENU_IMAGE.TAB_HEIGHT
            } else {
              item.y = y + (rowspan * constant.RICH_MENU_IMAGE.TAB_HEIGHT) / totalRow
            }
            item.height = height - (rowspan * constant.RICH_MENU_IMAGE.TAB_HEIGHT) / totalRow
            const block = this.components.block.find((item) => item.index - blockScale === index)
            if (block) {
              item.src = block.img
            }
            content.push(item)
          })
        }
      } else {
        if (this.selectedTemplate.content) {
          const totalRow = this.selectedTemplate.total_row ? this.selectedTemplate.total_row : 1
          this.selectedTemplate.content.forEach((item, index) => {
            const rowspan = item.rowspan ? item.rowspan : 1
            const y = item.y ? item.y : 0
            const height = item.height ? item.height : 0
            if (item.y === 0) {
              item.y = y + constant.RICH_MENU_IMAGE.TAB_HEIGHT - constant.RICH_MENU_IMAGE.TAB_HEIGHT
            } else {
              item.y =
                y + (rowspan * constant.RICH_MENU_IMAGE.TAB_HEIGHT) / totalRow - constant.RICH_MENU_IMAGE.TAB_HEIGHT
            }
            item.height = height - (rowspan * constant.RICH_MENU_IMAGE.TAB_HEIGHT) / totalRow
            const block = this.components.block.find((item) => item.index - blockScale === index)
            if (block) {
              item.src = block.img
            }
            content.push(item)
          })
        }
        for (let index = 0; index <= this.totalTab - 1; index++) {
          const tab: IImageSize = {
            x: (index * templateWidth) / this.totalTab,
            y: templateHeight - constant.RICH_MENU_IMAGE.TAB_HEIGHT,
            width: templateWidth / this.totalTab,
            height: constant.RICH_MENU_IMAGE.TAB_HEIGHT,
            rowspan: 1,
          }
          const menu = this.components.menu.find((item) => item.index - menuScale === index)
          if (menu) {
            tab.src = menu.img
          }
          content.push(tab)
        }
      }
      this.selectedTemplate.content = content
      this.currentIndex = 0
      if (this.selectedTemplate.content) {
        this.selectContentComponent(this.selectedTemplate.content[this.currentIndex], this.currentIndex)
        this.selectedTemplate.content.forEach((content, index) => {
          if (content.src) {
            const component = this.originComponents.find((item) => item.src === content.src)
            if (component) {
              component.idx = index
            }
          }
        })
      }
    } else {
      if (this.selectedTemplateCompact._id) {
        this.currentIndex = 0
        if (this.selectedTemplate.content) {
          this.selectContentComponent(this.selectedTemplate.content[this.currentIndex], this.currentIndex)
          this.selectedTemplate.content.forEach((item, index) => {
            const block = this.components.block.find((item) => item.index === index)
            if (block) {
              const component = this.originComponents.find((item) => item.src === block.img)
              if (component) {
                component.idx = index
              }
              item.src = block.img
            }
          })
        }
      }
    }
    this.handleFileIndexChange()
  }

  handleFileIndexChange() {
    this.componentFiles.forEach((file) => {
      if (this.selectedTemplate.content) {
        const index = this.selectedTemplate.content.findIndex((content) => content.src === file.url)
        if (index !== -1) {
          file.index = index
        }
      }
    })
  }

  async onTemplateSelected(item) {
    this.resetAll()
    this.selectedTemplate = item
    this.selectedIndex = this.templates.findIndex((template) => template === item)
    if (this.selectedTemplate.content) {
      this.selectContentComponent(this.selectedTemplate.content[this.currentIndex], this.currentIndex)
    }
    await this.createBackgroundImage(item.width, item.height)
      .then((blob) => {
        this.backgroundImage = URL.createObjectURL(blob)
      })
      .catch((error) => {
        console.error('Error:', error)
      })
  }

  async createBackgroundImage(width: number, height: number): Promise<Blob> {
    const canvas = document.createElement('canvas')
    canvas.width = width
    canvas.height = height

    const context = canvas.getContext('2d')

    if (context) {
      context.fillStyle = 'white'
      context.fillRect(0, 0, width, height)

      return new Promise<Blob>((resolve, reject) => {
        canvas.toBlob((blob) => {
          if (blob) {
            resolve(blob)
          } else {
            reject(new Error('Failed to create blob.'))
          }
        })
      })
    } else {
      return Promise.reject(new Error('Canvas context not available.'))
    }
  }

  async mergeImages() {
    type Image = string
    type ImageSource = {
      src: Image
      x?: number | undefined
      y?: number | undefined
      opacity?: number | undefined
    }
    const images: ImageSource[] = []
    const background: ImageSource = {
      src: this.backgroundImage,
      x: 0,
      y: 0,
    }
    images.push(background)
    if (this.selectedTemplate.content) {
      for (const el of this.selectedTemplate.content) {
        if (el.src) {
          let src = el.src
          if (!src.includes('blob:')) {
            const baseUrl = process.env.VUE_APP_API_ENDPOINT
            const proxyUrl = `${baseUrl}v1/proxy_image?url=` + encodeURIComponent(src)
            const response = await fetch(proxyUrl)
            // const response = await fetch(el.src)
            const blob = await response.blob()
            src = URL.createObjectURL(blob)
          }
          const image: ImageSource = {
            src: src,
            x: el.x,
            y: el.y,
          }
          images.push(image)
        }
      }
    }
    let url = ''
    await mergeImages(images, {
      format: 'image/jpeg',
      width: this.selectedTemplate.width,
      height: this.selectedTemplate.height,
      crossOrigin: 'anonymous',
    }).then((b64) => {
      url = b64
    })
    if (url) {
      const baseUrl = process.env.VUE_APP_API_ENDPOINT
      const proxyUrl = `${baseUrl}v1/proxy_image?url=` + encodeURIComponent(url)
      const response = await fetch(proxyUrl)
      // const response = await fetch(url)
      const blob = await response.blob()
      const file = new File([blob], 'rich-menu-template.jpg', { type: blob.type })
      await this.submitFile(file)
    }
  }

  async submitFile(file: File) {
    try {
      const resizedFile = await this.resizeImage(file)
      if (resizedFile) {
        const uploaded = await UploadApi.uploadFile(resizedFile)
        if (uploaded) {
          const components = await this.submitComponents()
          await this.submitMedia(file, uploaded)
          await this.submitTemplate(file, uploaded, components)
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  async submitComponents() {
    type Component = {
      idx?: number
      src?: string
    }
    const components: Component[] = []
    for (const component of this.componentFiles) {
      if (component.file) {
        const uploaded = await UploadApi.uploadFile(component.file)
        if (uploaded) {
          components.push({
            idx: component.index,
            src: uploaded.file_url_org,
          })
        }
      }
    }
    if (this.originComponents.length) {
      const originComponents: Component[] = this.originComponents
      const merged = [...originComponents, ...components]
      const map = new Map()
      merged.forEach((obj) => {
        map.set(obj.idx, obj)
      })
      const templateComponents = Array.from(map.values())
      return this.uniqueComponents(templateComponents)
    }
    return this.uniqueComponents(components)
  }

  async submitMedia(file, uploaded) {
    const media: IMedia[] = []
    const item: IMedia = {}
    item.app_id = this.appId
    item.name = uploaded.filename
    item.url = uploaded.file_url_org
    item.thumb = uploaded.file_url_thumb
    item.file_type = uploaded.extension
    item.image_width = this.selectedTemplate.width
    item.image_height = this.selectedTemplate.height
    item.content_type = file.type
    media.push(item)
    if (media.length) {
      await this.$store.dispatch(ACTION_MEDIA.ADD, media)
    }
  }

  async submitTemplate(file, uploaded, components) {
    type RichMenuContent = {
      url?: string
      name?: string
      content_type?: string
      image_width?: number
      image_height?: number
      tappable_area?: ITappableArea[]
      selected_template?: IRichMenuTemplateCompact
    }
    const saveComponents: IRichMenuTemplateComponent[] = []
    const template: RichMenuContent = {}
    template.url = uploaded.file_url_org || ''
    template.name = uploaded.filename
    template.content_type = file.type
    template.image_width = this.selectedTemplate.width
    template.image_height = this.selectedTemplate.height
    if (this.selectedTemplate.content) {
      const tappableArea: ITappableArea[] = []
      let scale = 1
      this.selectedTemplate.content.forEach((el) => {
        if (this.selectedTemplate.width) {
          scale = constant.RICH_MENU_IMAGE.IMAGE_DEFAULT_SIZE / this.selectedTemplate.width
        }
        const tappable: ITappableArea = {
          _id: '',
          action_type: '',
          link_uri: '',
          title: '',
          label: '',
          x: el.x ? el.x * scale : el.x,
          y: el.y ? el.y * scale : el.y,
          width: el.width ? el.width * scale : el.width,
          height: el.height ? el.height * scale : el.height,
        }
        tappableArea.push(tappable)
      })
      if (this.isEdit) {
        let originAreas: ITappableArea[]
        if (!this.isBottom) {
          originAreas = [...this.tappableAreas.menu, ...this.tappableAreas.block]
        } else {
          originAreas = [...this.tappableAreas.block, ...this.tappableAreas.menu]
        }
        tappableArea.forEach((area, index) => {
          const originArea = originAreas[index]
          if (originArea) {
            area._id = originArea._id
            area.action_type = originArea.action_type
            area.link_uri = originArea.link_uri
            area.title = originArea.title
            area.label = originArea.label
          }
        })
      }
      template.tappable_area = tappableArea
      components.forEach((item) => {
        saveComponents.push({
          index: item.idx,
          img: item.src,
        })
      })
      template.selected_template = {
        _id: this.selectedTemplate._id,
        add_menu: this.isAddTab,
        total_menu: this.totalTab,
        bottom_menu: this.isBottom,
        components: saveComponents,
      }
    }
    this.$emit('onSubmitTemplate', template)
  }

  async resizeImage(inputFile: File): Promise<File | null> {
    const maxSizeInBytes = constant.RICH_MENU_IMAGE.MAX_FILE_SIZE

    if (inputFile.size <= maxSizeInBytes) {
      return inputFile
    }

    let quality = 0.95

    while (quality >= 0.1) {
      const resizedFile = await this.attemptResize(inputFile, quality)

      if (resizedFile && resizedFile.size <= maxSizeInBytes) {
        return resizedFile
      }

      quality -= 0.05
    }

    return null
  }

  async attemptResize(inputFile: File, quality: number): Promise<File | null> {
    return new Promise<File | null>((resolve) => {
      const reader = new FileReader()

      reader.onload = async () => {
        const dataURL = reader.result as string

        const image = new Image()
        image.src = dataURL

        image.onload = () => {
          const canvas = document.createElement('canvas')
          const ctx = canvas.getContext('2d')

          canvas.width = image.width
          canvas.height = image.height

          if (ctx) {
            canvas.width = image.width
            canvas.height = image.height

            ctx.drawImage(image, 0, 0, image.width, image.height)

            canvas.toBlob(
              (blob) => {
                if (blob) {
                  const resizedFile = new File([blob], inputFile.name, { type: inputFile.type })
                  resolve(resizedFile)
                } else {
                  resolve(null)
                }
              },
              inputFile.type,
              quality
            )
          } else {
            resolve(null)
          }
        }
      }

      reader.readAsDataURL(inputFile)
    })
  }

  uniqueComponents(components) {
    type Component = {
      idx?: number
      src?: string
    }
    const uniqueComponents: Component[] = []
    const seen = new Set()

    components.forEach((obj) => {
      const key = JSON.stringify(obj)
      if (!seen.has(key)) {
        seen.add(key)
        uniqueComponents.push(obj)
      }
    })
    return uniqueComponents
  }

  resetAll() {
    this.selectedIndex = 0
    this.isAddTab = false
    this.isBottom = false
    this.totalTab = 1
    this.aspectRatio = 1
    this.stencilWidth = 1
    this.stencilHeight = 1
    this.backgroundImage = ''
    this.originComponents = []
    this.components = { menu: [], block: [] }
    this.tappableAreas = { menu: [], block: [] }
    this.isEdit = false
    this.defaultTabPosition = 'top'
  }

  mounted() {
    this.setupTemplate()
  }
}
</script>

<style scoped lang="scss">
.template-container {
  width: 700px;
  min-height: 200px;
  position: relative;
}
.target {
  position: absolute;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  border: 1px dashed #44aaff;
  white-space: nowrap;
  background-repeat: no-repeat;
  cursor: pointer;
  background-size: cover;
}
.target.active {
  border: 2px solid #0048ff !important;
}
</style>
