import { render } from "./ImageCarouselColumn.vue?vue&type=template&id=1a3739cd&scoped=true"
import script from "./ImageCarouselColumn.vue?vue&type=script&lang=ts"
export * from "./ImageCarouselColumn.vue?vue&type=script&lang=ts"

import "./ImageCarouselColumn.vue?vue&type=style&index=0&id=1a3739cd&lang=scss&scoped=true"
script.render = render
script.__scopeId = "data-v-1a3739cd"

export default script
import QCarousel from 'quasar/src/components/carousel/QCarousel.js';
import QCarouselSlide from 'quasar/src/components/carousel/QCarouselSlide.js';
import QBtnToggle from 'quasar/src/components/btn-toggle/QBtnToggle.js';
import qInstall from "../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QCarousel,QCarouselSlide,QBtnToggle});
