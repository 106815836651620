
import { Options, Vue } from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'
import { maska } from 'maska'
import { IAnswer, IQuestion, IValidRequest } from '@/utils/types'
import { ACTION_APP, ACTION_QUESTION } from '@/store/actions'
import { calcOrderingDragend } from '@/utils/helpers'

import QuestionFormModal from '@/components/question/QuestionFormModal.vue'
import draggable from 'vuedraggable'
import cloneDeep from 'lodash/cloneDeep'
import { constant } from '@/utils/constants'
import { Formater } from '@/utils/formater'
import CLoading from '@/components/common/ui/CLoading.vue'
import TagContent from '@/components/common/ui/TagContent.vue'

@Options({
  components: {
    draggable,
    QuestionFormModal,
    CLoading,
    TagContent,
  },
  directives: { maska },
})
export default class Questions extends Vue {
  @Prop()
  appId!: string

  modalVisible = false
  loading = true
  selectedQuestion: IQuestion = {}

  get questions(): IQuestion[] {
    return this.$store.getters.questions
  }

  getAnswers(data: IAnswer) {
    /* eslint-disable  @typescript-eslint/no-explicit-any */
    let result: any[] = []
    if (data.type === constant.ANSWER_TYPE.TEXT) {
      result = data.text_answers ?? []
    } else if (data.type === constant.ANSWER_TYPE.IMAGE) {
      result = data.image_answers ?? []
    } else if (data.type === constant.ANSWER_TYPE.BUTTON) {
      if (data.button_answer?.is_carousel) {
        const carousel_images = data.button_answer?.carousel_images ?? []
        result = []
        for (let index = 0; index < carousel_images.length; index++) {
          const element = carousel_images[index]
          if (element.actions) {
            const ac = element.actions.filter((p) => p.content !== undefined && p.content !== '')
            result = result.concat(ac)
          }
        }
      } else {
        const actions = data.button_answer?.actions ?? []
        result = []
        if (actions) {
          result = actions.filter((p) => p.content !== undefined && p.content !== '')
        }
      }
    } else {
      result = data.imagemap_answer?.tappable_area ?? []
    }
    return result
  }

  @Watch('appId', { immediate: true })
  async appIdChanged() {
    if (!this.appId) {
      return
    }

    await this.$store.dispatch(ACTION_QUESTION.LOAD_ITEMS, {
      app_id: this.appId,
      is_include_data: true,
    })

    this.loading = false
  }

  answerName(id: string) {
    for (const element of this.questions) {
      if (id === element._id) {
        return element.title
      }
    }
  }

  onAdd() {
    this.selectedQuestion = {
      app_id: this.appId,
    }

    this.modalVisible = true
  }

  onCopy(data: IQuestion) {
    const questionCopy = cloneDeep(data)
    questionCopy._id = ''
    questionCopy.title = questionCopy.title + ' Copy'
    this.selectedQuestion = questionCopy
    this.modalVisible = true
  }

  onEdit(data: IQuestion) {
    this.selectedQuestion = data
    this.modalVisible = true
  }

  async onDelete(question: IQuestion) {
    this.$q
      .dialog({
        title: this.$t('confirm'),
        message: this.$t('are_you_sure_you_want_to_delete'),
        cancel: true,
        persistent: true,
      })
      .onOk(async () => {
        const exists = await this.checkItemExists(question)
        if (!exists) {
          const success = await this.$store.dispatch(ACTION_QUESTION.DELETE, question._id)
          if (success) {
            this.$q.notify({
              message: this.$t('messages.deleted'),
              color: 'positive',
            })
          }
        }
      })
  }

  onCloseModal() {
    this.modalVisible = false
    this.selectedQuestion = {}
  }

  // eslint-disable-next-line
  async onDragEnd(evt: any, finalList: IQuestion[]) {
    if (!evt.moved) {
      return
    }

    const record = evt.moved.element
    const finalPosition = calcOrderingDragend(record, finalList)

    await this.$store.dispatch(ACTION_QUESTION.UPDATE, {
      ...record,
      ordering: finalPosition,
    })
  }

  numberWithCommas(num) {
    return Formater.numberFormat(num)
  }

  async checkItemExists(question: IQuestion) {
    const { dispatch } = this.$store
    const params: IValidRequest = {
      app_id: this.appId,
      initial_id: '',
      push_id: '',
      card_id: question._id ?? '',
      card_type: 'question',
      total_messages: 0,
      campaign: {},
    }
    const resp = await dispatch(ACTION_APP.CHECK_ITEM_EXISTS, params)

    if (resp.campaign_title) {
      this.$q.dialog({
        title: this.$t('error'),
        message: this.$t('messages.item_exists_in_campaign', { names: resp.campaign_title.toString() }),
        persistent: true,
        html: true,
      })
      return true
    }
    return false
  }
}
