import { render } from "./AnswerForm.vue?vue&type=template&id=506486a6&scoped=true"
import script from "./AnswerForm.vue?vue&type=script&lang=ts"
export * from "./AnswerForm.vue?vue&type=script&lang=ts"

import "./AnswerForm.vue?vue&type=style&index=0&id=506486a6&lang=scss&scoped=true"
script.render = render
script.__scopeId = "data-v-506486a6"

export default script
import QBtnToggle from 'quasar/src/components/btn-toggle/QBtnToggle.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QList from 'quasar/src/components/item/QList.js';
import qInstall from "../../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QBtnToggle,QInput,QList});
