
import { mixins, Options } from 'vue-class-component'
import { maska } from 'maska'
import AppMixin from '@/components/app/mixins/AppMixin.vue'
import BaseFormMixin from '@/components/common/mixins/BaseFormMixin.vue'
import { constant } from '@/utils/constants'
import { IDateRangePicker } from '@/utils/types'
import { Prop, Watch } from 'vue-property-decorator'
import moment from 'moment'
import useQuasar from 'quasar/src/composables/use-quasar.js';
import DateRangePicker from 'vue3-daterange-picker'
import { localeData, localeRanges } from '@/utils/datetime'
@Options({
  components: { DateRangePicker },
  directives: { maska },
  emits: ['update:onChangeDateRange', 'update:modelValue'],
})
export default class DateRangeSelectorV2 extends mixins(AppMixin, BaseFormMixin) {
  @Prop()
  modelValue!: IDateRangePicker[]

  @Prop()
  disable!: boolean

  @Prop({ default: 'left' })
  opens!: string

  @Prop()
  displayLabel!: boolean

  @Prop({ default: 240 })
  inputWidth!: number

  @Prop({ default: 24 })
  iconSize!: number

  @Prop({ default: 14 })
  fontSize!: number

  customLocale = localeData()
  customRanges = localeRanges()

  date_range = constant.DATE_RANGE
  quasar = useQuasar()

  get value() {
    return this.modelValue
  }

  changeDateRange(value: IDateRangePicker) {
    const check = this.checkRange(value.startDate, value.endDate)
    console.log(value, 'value')

    if (check) {
      this.$emit('update:onChangeDateRange', value)
    }
  }

  checkRange(start, end) {
    const from = moment(start, 'YYYY-MM-DD')
    const to = moment(end, 'YYYY-MM-DD')
    const diff = to.diff(from, 'days')
    if (diff > this.date_range) {
      this.quasar.notify({
        type: 'negative',
        message: this.$t('errors.over_92_days'),
      })
      return false
    } else {
      return true
    }
  }

  formatDate(date) {
    if (date.startDate && date.endDate) {
      return moment(date.startDate).format('YYYY-MM-DD') + ' - ' + moment(date.endDate).format('YYYY-MM-DD')
    } else {
      return 'YYYY-MM-DD' + ' - ' + 'YYYY-MM-DD'
    }
  }

  onSelect(data) {
    data.clickApply()
    this.changeDateRange(this.parseDateRange(data.rangeText))
  }

  parseDateRange(range) {
    const [startDate, endDate] = range.split(' - ')
    return { startDate, endDate }
  }
}
