
import { Options, Vue } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { IMedia } from '@/utils/types'
import { maska } from 'maska'

@Options({
  components: {},
  directives: { maska },
})
export default class MediaDetails extends Vue {
  @Prop()
  selectedMedia: IMedia = {}

  @Prop()
  isReviewImage!: boolean

  copyToClipBoard(textToCopy) {
    navigator.clipboard.writeText(textToCopy)
  }

  async onDownload(url, name) {
    const baseUrl = process.env.VUE_APP_API_ENDPOINT
    const proxyUrl = `${baseUrl}v1/proxy_image?url=` + encodeURIComponent(url)
    const response = await fetch(proxyUrl)
    //  const response = await fetch(url)
    const blob = await response.blob()
    const link = document.createElement('a')
    link.href = window.URL.createObjectURL(blob)
    link.download = name
    link.click()
  }
}
