<template>
  <q-dialog v-model="visible" full-height position="right">
    <q-card style="min-width: 80vw">
      <q-toolbar>
        <q-toolbar-title> {{ $t('label.push.delivered_log') }} </q-toolbar-title>
        <TestModeSelector v-model="isTestMode" :isHideCopy="true" />
        <q-btn flat round dense icon="close" v-close-popup />
      </q-toolbar>
      <CLoading :loading="loading" />
      <q-card-section style="padding-top: 0 !important">
        <div class="horizontal-line q-mb-sm"></div>
        <TransmissionSelector
          :resources="resources"
          @update:queryParams="updateQueryParams"
          :appId="appId"
          :isTestMode="isTestMode"
        ></TransmissionSelector>
        <q-table
          flat
          dense
          bordered
          v-if="deliver_logs"
          :title="$t('label.push.delivered_logs_list')"
          :rows="deliver_logs"
          row-key="created_at"
          :rows-per-page-options="[10]"
          separator="cell"
          :no-data-label="$t('messages.no_data_available')"
          class="text-grey-8"
        >
          <template v-slot:header="props">
            <q-tr>
              <q-th key="created_at" class="text-left"><span class="text-bold">作成時間</span></q-th>
              <q-th key="updated_at" class="text-left"><span class="text-bold">配信時間</span></q-th>
              <q-th :props="props" key="target_count"><span class="text-bold">コンテンツ</span></q-th>
              <q-th :props="props" key="target_count"><span class="text-bold">コンテンツID</span></q-th>
              <q-th :props="props" key="target_count"><span class="text-bold">コンテンツ名</span></q-th>
              <q-th :props="props" key="success_count"><span class="text-bold">ユーザーID</span></q-th>
              <q-th :props="props" key="failure_count"><span class="text-bold">ユーザー名</span></q-th>
              <q-th :props="props" key="phase"><span class="text-bold">試行回数</span></q-th>
              <q-th :props="props" key="phase"><span class="text-bold">成功</span></q-th>
              <q-th :props="props" key="phase"><span class="text-bold">失敗</span></q-th>
              <q-th :props="props" key="phase" style="width: 30px"><span class="text-bold">ステータス</span></q-th>
            </q-tr>
          </template>
          <template v-slot:body="props">
            <q-tr :props="props">
              <q-td key="created_at" :props="props.index"> {{ props.row.created_at }} </q-td>
              <q-td key="completed_time" :props="props">
                {{ props.row.completed_time }}
              </q-td>
              <q-td key="resource_type" :props="props" class="text-center">{{
                getResourceTypeName(props.row.resource_type)
              }}</q-td>
              <q-td key="display_id" :props="props" class="text-center">{{ props.row.display_id }}</q-td>
              <q-td key="resource_name" :props="props">{{ props.row.resource_name }}</q-td>
              <q-td key="user_id" :props="props"> {{ props.row.user_id }} </q-td>
              <q-td key="user_name" :props="props">{{ props.row.user_name }}</q-td>
              <q-td key="target_count" :props="props" class="text-center">{{ props.row.target_count }}</q-td>
              <q-td key="success_count" :props="props" class="text-center">{{ props.row.success_count }}</q-td>
              <q-td key="failure_count" :props="props" class="text-center">{{ props.row.failure_count }}</q-td>
              <q-td key="phase" :props="props" style="text-align: center">
                <q-chip clickable size="sm" class="btn-waiting" label="予定" v-if="props.row.phase == 'waiting'" />
                <q-chip clickable size="sm" class="btn-error" label="失敗" v-if="props.row.phase == 'error'" />
                <q-chip clickable size="sm" class="btn-succeeded" label="成功" v-if="props.row.phase == 'succeeded'" />
              </q-td>
            </q-tr>
          </template>
        </q-table>
        <div class="row no-wrap items-center q-mt-md">
          <q-space />
          <div class="q-gutter-sm">
            <q-btn flat color="white" text-color="black" @click="onCancel">{{ $t('close') }}</q-btn>
          </div>
        </div>
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script lang="ts">
import { Vue, Options } from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'
import { maska } from 'maska'
import { ACTION_FORM, ACTION_GOAL, ACTION_MESSAGES, ACTION_QUESTION, DELIVER_LOG } from '@/store/actions'
import { IDeliverLog, IStory } from '@/utils/types'
import CLoading from '@/components/common/ui/CLoading.vue'
import TestModeSelector from '@/components/common/ui/TestModeSelector.vue'
import TransmissionSelector from '@/components/story/common/selectors/TransmissionSelector.vue'

@Options({
  components: { CLoading, TestModeSelector, TransmissionSelector },
  directives: { maska },
  emits: ['update:closeModal'],
})
export default class TransmissionLogModal extends Vue {
  @Prop()
  selectedStory!: IStory

  @Prop()
  modalVisible!: boolean

  @Prop()
  campaignId!: string

  loading = false
  isTestMode = false
  queryParams = {}
  deliver_logs: IDeliverLog[] = []
  firstLoad = false

  get visible() {
    return this.modalVisible
  }

  set visible(value) {
    this.$emit('update:closeModal', value)
  }

  get appId() {
    return this.$route?.params?.app_id
  }

  get messages() {
    return this.$store.getters.messages
  }

  get questions() {
    return this.$store.getters.questions
  }

  get goals() {
    return this.$store.getters.goals
  }

  get forms() {
    return this.$store.getters.forms
  }

  get resources() {
    const results: {
      _id?: string
      type?: string
      label?: string
    }[] = []
    for (const item of this.messages) {
      results.push({
        _id: item._id,
        type: 'message',
        label: item.title,
      })
    }
    for (const item of this.questions) {
      results.push({
        _id: item._id,
        type: 'question',
        label: item.title,
      })
    }
    for (const item of this.goals) {
      results.push({
        _id: item._id,
        type: 'goal',
        label: item.title,
      })
    }

    for (const item of this.forms) {
      results.push({
        _id: item._id,
        type: 'form',
        label: item.title,
      })
    }
    return results
  }

  async loadResources() {
    // TODO: this should load base on form.story_id
    await this.$store.dispatch(ACTION_QUESTION.LOAD_ITEMS, {
      app_id: this.appId,
    })

    await this.$store.dispatch(ACTION_GOAL.LOAD_ITEMS, {
      app_id: this.appId,
    })

    await this.$store.dispatch(ACTION_MESSAGES.LOAD_ITEMS, {
      app_id: this.appId,
    })

    await this.$store.dispatch(ACTION_FORM.LOAD_ITEMS, {
      app_id: this.appId,
    })
  }

  checkDiffirent(to: string, from: string) {
    return to !== from ? 1 : 0
  }

  getResourceTypeName(value) {
    if (value === 'message') {
      return this.$t('menu.message')
    } else if (value === 'question') {
      return this.$t('menu.q_a')
    } else if (value === 'goal') {
      return this.$t('menu.goal')
    } else if (value === 'form') {
      return this.$t('menu.form')
    }

    return ''
  }

  async updateQueryParams(params) {
    this.queryParams = params
    this.loadData()
  }

  async loadData() {
    let story_id = ''
    if (this.selectedStory) {
      story_id = this.selectedStory._id ?? ''
    }

    this.deliver_logs = await this.$store.dispatch(DELIVER_LOG.LOAD_STORY_ITEMS, {
      app_id: this.appId,
      story_id: story_id,
      campaign_id: this.campaignId,
      is_test_mode: this.isTestMode,
      filter: this.queryParams,
    })
  }

  onCancel() {
    this.visible = false
  }

  async created() {
    if (!this.appId) {
      return
    }
    await this.loadResources()
    // await this.loadData()
    this.firstLoad = true
  }
}
</script>
<style scoped>
.btn-succeeded {
  background: #59b755 !important;
  color: white !important;
}

.btn-waiting {
  background: #bdcb50 !important;
  color: white !important;
}

.btn-error {
  background: #f42b4f !important;
  color: white !important;
}
</style>
