
import { Options, Vue } from 'vue-class-component'
import CLoading from '@/components/common/ui/CLoading.vue'
import { maska } from 'maska'
import { Prop, Watch } from 'vue-property-decorator'
import { ACTION_MEDIA } from '@/store/actions'
import { IMedia } from '@/utils/types'
import UploaderComponent from '@/components/media-manager/UploaderComponent.vue'
import CropperImageSelector from '@/components/tappable-area/selectors/CropperImageSelector.vue'
import UploadingProgress from '@/components/common/ui/UploadingProgress.vue'
import { constant } from '@/utils/constants'
import UploadApi from '@/api/upload'

@Options({
  components: {
    UploadingProgress,
    CropperImageSelector,
    UploaderComponent,
    CLoading,
  },
  directives: { maska },
  emits: ['onSelectMedia'],
})
export default class MediaUploader extends Vue {
  @Prop()
  isCropImage!: boolean

  @Prop({ default: '.png, .jpeg, .jpg, image/*' })
  acceptFileTypes!: string

  @Prop({ default: '1:1' })
  cropRatio!: string

  @Prop({ default: 1 })
  aspectRatio!: number

  @Prop({ default: false })
  isStatic!: boolean

  @Prop({ default: false })
  isImagemap!: boolean

  @Prop({ default: 0 })
  indexImagemap!: number

  @Prop({ default: 1040 })
  stencilWidth!: number

  @Prop({ default: 1040 })
  stencilHeight!: number

  @Prop({ default: false })
  lightStyle!: boolean

  media: IMedia[] = []
  selectedMedia: IMedia = {}
  totalPages = 0
  perPage = 18
  perPageOptions = [18, 24, 36, 60]
  searchName = ''
  currentPage = 1
  loading = true
  isAdd = false

  cropFiles: File[] = []
  file: File | null = null
  cropperImageModalVisible = false
  currentImageWidth = 1
  currentImageHeight = 1

  get appId() {
    return this.$route.params.app_id
  }

  get modalTitle() {
    return this.$t('menu.media_manager')
  }

  @Watch('appId', { immediate: true })
  async appIdChanged() {
    if (!this.appId) {
      return
    }
    this.loading = true
    await this.fetchMedia()
  }

  @Watch('currentPage')
  async currentPageChanged() {
    this.loading = true
    await this.fetchMedia()
  }

  @Watch('perPage')
  async currentPerPageChanged() {
    this.loading = true
    await this.fetchMedia()
  }

  async fetchMedia() {
    const result = await this.$store.dispatch(ACTION_MEDIA.LOAD_ITEMS, {
      app_id: this.appId,
      filter: {
        name: this.searchName,
        page: this.currentPage,
        per_page: this.perPage,
      },
    })
    this.media = result.items
    this.totalPages = result.total_pages

    this.loading = false
  }

  async searchMedia() {
    this.loading = true
    await this.fetchMedia()
  }

  async selectMedia(item) {
    if (this.isCropImage) {
      const baseUrl = process.env.VUE_APP_API_ENDPOINT
      const proxyUrl = `${baseUrl}v1/proxy_image?url=` + encodeURIComponent(item.url)
      const response = await fetch(proxyUrl)
      // const response = await fetch(item.url)
      const blob = await response.blob()
      this.file = new File([blob], item.name, { type: blob.type })
      await this.handleSelectMedia(item)
    } else {
      this.selectedMedia = item
      this.file = null
      this.cropFiles = []
      this.onSelectMedia()
    }
  }

  async handleSelectMedia(item) {
    if (!this.file) {
      return false
    }

    try {
      if (!this.isAcceptedFileType(this.file)) {
        // File type is not accepted
        console.error('File type not accepted:', this.file.type)
        this.$q.notify({
          type: 'negative',
          message: this.$t('validate.imagemap_requirements_detail1', {
            placeholder: this.file.type,
          }),
        })
        return
      }

      if (!(await this.validateImageWidth(this.file))) {
        await this.$q
          .dialog({
            title: this.$t('messages.confirm'),
            message: this.$t('messages.image_aspect_ratio_is_not_1_1', {
              ratio: this.cropRatio,
            }),
            cancel: {
              flat: true,
              label: this.$t('messages.no'),
            },
            ok: {
              flat: true,
              label: this.$t('messages.yes'),
            },
            persistent: true,
          })
          .onOk(async () => {
            this.cropperImageModalVisible = true
          })
          .onCancel(async () => {
            this.onCloseModal()
          })
      } else {
        this.selectedMedia = item
        this.selectedMedia.content_type = this.file.type
        this.selectedMedia.image_width = this.currentImageWidth
        this.selectedMedia.image_height = this.currentImageHeight
        this.file = null
        this.cropFiles = []
        this.onSelectMedia()
      }
    } catch (error) {
      console.log(error)
    }
  }

  async validateImageWidth(file: File) {
    return new Promise<boolean>((resolve) => {
      const img = new Image()
      img.src = window.URL.createObjectURL(file)
      img.onload = (event: Event) => {
        const data = event.target as HTMLImageElement

        if (this.isStatic) {
          if (data.width / data.height !== this.aspectRatio) {
            resolve(false)
          }

          if (this.isImagemap) {
            if (data.width < this.stencilWidth || data.height < this.stencilHeight) {
              resolve(false)
            }
          }
          resolve(true)
        } else {
          if (this.aspectRatio === 1) {
            if (data.width !== data.height) {
              resolve(false)
            }

            resolve(true)
          } else {
            if (data.width / data.height < constant.RICH_MENU_IMAGE.MIN_ASPECT_RATIO) {
              this.$q.notify({
                type: 'negative',
                message: this.$t('validate.image_aspect_ratio', {
                  placeholder: file.name,
                }),
              })
              resolve(false)
            }

            if (
              data.width < constant.RICH_MENU_IMAGE.MIN_IMAGE_WIDTH ||
              data.width > constant.RICH_MENU_IMAGE.MAX_IMAGE_WIDTH
            ) {
              this.$q.notify({
                type: 'negative',
                message: this.$t('validate.image_width_size', {
                  placeholder: file.name,
                }),
              })
              resolve(false)
            }

            if (data.height < constant.RICH_MENU_IMAGE.MIN_IMAGE_HEIGHT) {
              this.$q.notify({
                type: 'negative',
                message: this.$t('validate.image_height_size', {
                  placeholder: file.name,
                }),
              })
              resolve(false)
            }

            this.currentImageWidth = data.width
            this.currentImageHeight = data.height
            resolve(true)
          }
        }
      }
    })
  }

  onSelectMedia() {
    this.$emit('onSelectMedia', this.selectedMedia, this.indexImagemap)
    this.isAdd = false
  }

  onCancel() {
    this.isAdd = false
  }

  onCloseModal() {
    this.cropperImageModalVisible = false
    this.file = null
    this.cropFiles = []
  }

  isAcceptedFileType(file: File): boolean {
    const fileType = file.type
    const fileExtension = `.${file.name.split('.').pop()?.toLowerCase()}`
    const acceptedFileTypes = this.acceptFileTypes.split(',').map((type) => type.trim())

    return (
      acceptedFileTypes.includes(fileType) ||
      acceptedFileTypes.includes(fileExtension) ||
      acceptedFileTypes.includes('image/*')
    )
  }

  async onCropImage(file, width, height) {
    this.currentImageWidth = width
    this.currentImageHeight = height
    this.cropperImageModalVisible = false
    this.cropFiles.push(file)
    await this.submitFile(file)
    this.onCloseModal()
    this.onSelectMedia()
  }

  async submitFile(file: File) {
    this.loading = true
    try {
      const uploaded = await UploadApi.uploadFile(file)
      if (uploaded) {
        this.selectedMedia.url = uploaded.file_url_org || ''
        this.selectedMedia.name = uploaded.filename
        this.selectedMedia.content_type = file.type
        this.selectedMedia.image_width = this.currentImageWidth
        this.selectedMedia.image_height = this.currentImageHeight
        this.loading = false
      }
    } catch (error) {
      console.log(error)
    }
  }
}
