
import { Options, Vue } from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'
import { maska } from 'maska'
import { IDateRangePicker, IUser } from '@/utils/types'
import { ACTION_APP_USER } from '@/store/actions'
import DateRangeSelectorV2 from '@/components/common/ui/DateRangeSelectorV2.vue'
import UserSelectionModal from '@/components/story/common/selectors/UserSelectionModal.vue'
import dayjs from 'dayjs'

@Options({
  components: { DateRangeSelectorV2, UserSelectionModal },
  directives: { maska },
  emits: ['update:modelValue', 'update:queryParams'],
})
export default class TransmissionSelector extends Vue {
  @Prop({})
  resources!: []

  @Prop()
  appId!: string

  @Prop()
  isTestMode!: boolean

  dateRange: IDateRangePicker = {
    startDate: '',
    endDate: '',
  }

  currentDateType = ''
  selectedResources = []
  currentResourceType = ''
  selectedPhases = []
  currentPhase = ''
  selectedUsers: (string | undefined)[] = []
  phases = [
    {
      code: 'waiting',
      label: '予定',
    },
    {
      code: 'succeeded',
      label: '成功',
    },
    {
      code: 'error',
      label: '失敗',
    },
  ]

  modalVisible = false
  modalLoading = false
  defaultUsers = 'ユーザー'
  totalUsers = 0
  totalPages = 0
  totalFilterPages = 0
  perPages = 32
  users: IUser[] = []
  filterUsers: IUser[] = []
  firstLoad = false

  onOpenModal() {
    this.modalVisible = true
  }

  onCloseModal() {
    this.modalVisible = false
  }

  onSubmitModal(val: [], all) {
    if (all) {
      this.selectedUsers = []
    } else {
      this.selectedUsers = val
    }

    this.updateParams()
  }

  async loadUsers(filter) {
    this.modalLoading = true
    const response = await this.$store.dispatch(ACTION_APP_USER.LOAD_ITEMS, {
      app_id: this.appId,
      is_test_mode: this.isTestMode,
      filter: filter,
    })
    if (response) {
      if (filter.state === 'selected' || filter.state === 'unselected') {
        this.filterUsers = response.items
        this.totalFilterPages = response.total_pages
      } else {
        this.users = response.items
        this.totalPages = response.total_pages
      }
    }
    this.modalLoading = false
  }

  changeDate(type) {
    if (type === this.currentDateType) {
      this.currentDateType = ''
    } else {
      this.currentDateType = type
    }
  }

  changeResource(type) {
    if (type === this.currentResourceType) {
      this.currentResourceType = ''
    } else {
      this.currentResourceType = type
    }
  }

  changePhase(type) {
    if (type === this.currentPhase) {
      this.currentPhase = ''
    } else {
      this.currentPhase = type
    }
  }

  @Watch('currentDateType')
  setDateRange() {
    const today = new Date()
    const formattedToday = today.toLocaleDateString('en-ZA')

    switch (this.currentDateType) {
      case 'today': {
        this.dateRange.startDate = formattedToday
        this.dateRange.endDate = formattedToday
        break
      }
      case 'yesterday': {
        const yesterday = new Date(today)
        yesterday.setDate(today.getDate() - 1)
        this.dateRange.startDate = yesterday.toLocaleDateString('en-ZA')
        this.dateRange.endDate = yesterday.toLocaleDateString('en-ZA')
        break
      }
      case 'thisWeek': {
        const currentDayOfWeek = today.getDay()
        const adjustedDayOfWeek = currentDayOfWeek === 0 ? 7 : currentDayOfWeek
        const startOfWeek = new Date(today)
        startOfWeek.setDate(today.getDate() - adjustedDayOfWeek + 1)
        const endOfWeek = new Date(today)
        endOfWeek.setDate(today.getDate() + (7 - adjustedDayOfWeek))
        this.dateRange.startDate = startOfWeek.toLocaleDateString('en-ZA')
        this.dateRange.endDate = endOfWeek.toLocaleDateString('en-ZA')
        break
      }
      case 'lastWeek': {
        const currentDayOfWeek = today.getDay()
        const adjustedDayOfWeek = currentDayOfWeek === 0 ? 7 : currentDayOfWeek
        const startOfPreviousWeek = new Date(today)
        startOfPreviousWeek.setDate(today.getDate() - adjustedDayOfWeek - 6)
        const endOfPreviousWeek = new Date(today)
        endOfPreviousWeek.setDate(today.getDate() - adjustedDayOfWeek)
        this.dateRange.startDate = startOfPreviousWeek.toLocaleDateString('en-ZA')
        this.dateRange.endDate = endOfPreviousWeek.toLocaleDateString('en-ZA')
        break
      }
      case 'thisMonth': {
        const year = today.getFullYear()
        const month = today.getMonth()
        const startOfMonth = new Date(year, month, 1)
        const endOfMonth = new Date(year, month + 1, 0)
        this.dateRange.startDate = startOfMonth.toLocaleDateString('en-ZA')
        this.dateRange.endDate = endOfMonth.toLocaleDateString('en-ZA')
        break
      }
      case 'lastMonth': {
        const year = today.getFullYear()
        const month = today.getMonth()
        const startOfPreviousMonth = new Date(year, month - 1, 1)
        const endOfPreviousMonth = new Date(year, month, 0)
        this.dateRange.startDate = startOfPreviousMonth.toLocaleDateString('en-ZA')
        this.dateRange.endDate = endOfPreviousMonth.toLocaleDateString('en-ZA')
        break
      }
      default: {
        this.dateRange.startDate = ''
        this.dateRange.endDate = ''
        break
      }
    }
    this.updateParams()
  }

  changeDateRange(value) {
    this.dateRange = value
    this.currentDateType = ''
    this.updateParams()
  }

  formatDate(date: string) {
    if (date) {
      return dayjs(date).format('YYYY-MM-DD')
    }

    return ''
  }

  @Watch('currentResourceType')
  @Watch('selectedResources')
  @Watch('currentPhase')
  @Watch('selectedPhases')
  updateParams() {
    const params = {
      start_date: this.formatDate(this.dateRange.startDate),
      end_date: this.formatDate(this.dateRange.endDate),
      resource_type: this.currentResourceType,
      resources: this.selectedResources,
      phase_type: this.currentPhase,
      phases: this.selectedPhases,
      users: this.selectedUsers,
    }
    this.$emit('update:queryParams', params)
  }

  @Watch('isTestMode')
  async onChangeTestMode() {
    this.selectedUsers = []

    const all = await this.$store.dispatch(ACTION_APP_USER.LOAD_ITEMS, {
      app_id: this.appId,
      is_test_mode: this.isTestMode,
      filter: {},
    })

    if (all) {
      this.totalUsers = all.total_items
    }

    const filter = {
      page: 1,
      per_page: this.perPages,
    }
    await this.loadUsers(filter)
    this.updateParams()
  }

  async created() {
    this.changeDate('thisMonth')
    await this.onChangeTestMode()

    this.firstLoad = true
  }
}
