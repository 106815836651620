<template>
  <div class="images q-mb-sm">
    <div v-if="msg.button_answer?.is_carousel">
      <q-carousel
        v-model="activeSlide"
        transition-prev="slide-right"
        transition-next="slide-left"
        animated
        control-color="primary"
        class="rounded-borders"
        style="border-radius: 5px; height: auto"
      >
        <q-carousel-slide
          v-for="(carousel, index) in filteredButtons"
          :key="index"
          :name="index"
          class="q-mb-sm q-pa-none"
          style="display: flex; flex-direction: column; align-items: stretch; height: auto"
        >
          <div
            v-if="msg.button_answer?.is_use_image === 'image'"
            :style="'background-color:' + carousel.image_background_color"
            class="text-bold"
            style="height: 100px; border-radius: 5px; display: flex; align-items: center; justify-content: center"
          >
            <q-img
              :src="carousel.image_url"
              style="height: 100px; max-width: 100px; border-radius: 5px"
              fit="cover"
              v-if="carousel.image_url"
            />
          </div>
          <div class="text-subtitle2 text-bold q-pl-md" style="text-align: left">
            {{ carousel.title }}
          </div>
          <div class="text-subtitle2 q-pl-md text-subtitle2" style="text-align: left">
            {{ carousel.text }}
          </div>

          <q-card-actions vertical align="center" style="margin-top: auto; width: 100%">
            <q-btn
              flat
              no-caps
              class="btn-action"
              style="width: 100%"
              v-for="(btn, i) in getCarouselActions(carousel)"
              :key="i"
              @click="handleAnswerClick(btn)"
            >
              {{ btn.label }}
            </q-btn>
          </q-card-actions>
          <div class="row justify-center">
            <q-btn-toggle glossy v-model="activeSlide" :options="optionsSlides" />
          </div>
        </q-carousel-slide>
      </q-carousel>
    </div>
    <div style="background-color: white" v-else>
      <div
        v-if="msg.button_answer?.is_use_image === 'image'"
        :class="imageId"
        class="text-bold"
        style="
          height: 100px;
          background-color: #d9d9d9;
          border-radius: 5px;
          display: flex;
          align-items: center;
          justify-content: center;
        "
      >
        <q-img
          :src="msg.button_answer?.image_url"
          style="height: 100px; max-width: 100px; border-radius: 5px"
          fit="cover"
          v-if="msg.button_answer?.image_url"
        />
      </div>
      <div class="text-subtitle2 text-bold q-pl-md" style="text-align: left">{{ msg.button_answer?.title }}</div>
      <div class="text-subtitle2 q-pl-md text-subtitle2" style="text-align: left">
        {{ msg.button_answer?.text }}
      </div>
      <q-card-actions vertical align="center">
        <q-btn
          flat
          no-caps
          class="btn-action"
          style="width: 100%"
          v-for="(btn, i) in actions"
          :key="i"
          @click="handleAnswerClick(btn)"
          >{{ btn.label }}</q-btn
        >
      </q-card-actions>
    </div>
  </div>
</template>

<script lang="ts">
import { Vue, Options } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { maska } from 'maska'
import { IButtonAnswer, IButtonAnswerAction, ICarouselButton, IChat } from '@/utils/types'
import { constant, IMAGEMAP_MESSAGE } from '@/utils/constants'

@Options({
  components: {},
  directives: { maska },
  emits: ['update:handleAnswerClick'],
})
export default class ButtonsTemplatePreview extends Vue {
  @Prop()
  msg!: IChat

  activeSlide = 0
  imageId = 'button-template-image-x-' + Math.floor(Math.random() * 100)

  get filteredButtons() {
    console.log(this.button, 'this.button')

    const carouselImages: ICarouselButton[] = []
    if (this.msg.button_answer?.carousel_images) {
      for (const carousel of this.msg.button_answer.carousel_images) {
        if (!carousel.text || carousel.text === '' || !carousel.title || carousel.title === '') {
          continue
        }

        if (
          carousel.actions &&
          carousel.actions[0].type &&
          carousel.actions[0].type !== '' &&
          carousel.actions[0].content &&
          carousel.actions[0].content !== ''
        ) {
          carouselImages.push(carousel)
        }
      }
    }

    console.log(carouselImages, 'carouselImages')

    return carouselImages
  }

  get optionsSlides() {
    const opts: {
      label: number
      value: number
    }[] = []

    for (let index = 0; index < this.filteredButtons.length; index++) {
      opts.push({
        label: index + 1,
        value: index,
      })
    }

    return opts
  }

  get actions() {
    if (this.msg.button_answer) {
      return this.msg.button_answer.actions?.filter((p) => p.content !== undefined && p.content !== '')
    }
    return []
  }

  getCarouselActions(carousel: ICarouselButton) {
    if (carousel) {
      return carousel.actions?.filter((p) => p.content !== undefined && p.content !== '')
    }
    return []
  }

  handleAnswerClick(btn: IButtonAnswerAction) {
    if (btn.type === IMAGEMAP_MESSAGE.ACTION_TYPE.URI) {
      window.open(btn.content)
    } else if (
      btn.type === IMAGEMAP_MESSAGE.ACTION_TYPE.MESSAGE ||
      btn.type === IMAGEMAP_MESSAGE.ACTION_TYPE.POSTBACK
    ) {
      // const msg: IChat = {
      //   text: [btn.content ?? ''],
      //   sent: true,
      //   type: constant.ANSWER_TYPE.TEXT,
      // }
      this.$emit('update:handleAnswerClick', this.msg.uniqueId, btn._id ?? '', btn.content ?? '')
    }
  }

  mounted() {
    const img = document.querySelector('.' + this.imageId) as HTMLElement
    if (img && this.msg.button_answer?.image_background_color) {
      img.style.backgroundColor = this.msg.button_answer?.image_background_color
    }
  }
}
</script>
<style lang="scss" scoped>
@import '@/styles/quasar.variables.scss';

.text-subtitle2 {
  white-space: normal; /* Ensures text wraps to the next line */
  word-wrap: break-word; /* Allows breaking words if they are too long */
  overflow-wrap: break-word; /* Alternative to word-wrap */
}

.card-radius {
  border-radius: 4px !important;
}

.btn-action {
  color: #3982c2 !important;
}

.images {
  max-width: 270px;
  padding-top: 8px;
  // overflow-x: auto;
  white-space: nowrap;
  overflow-x: scroll;
  overflow-y: hidden;
  will-change: transform;
  transition: all 0.2s;
}
</style>
