<template>
  <q-dialog v-model="visible">
    <q-card style="width: 400px; max-width: 90vw">
      <q-toolbar class="auto-height horizontal-line">
        <q-toolbar-title class="q-pa-sm">並び替え</q-toolbar-title>
        <q-btn flat round dense icon="close" @click="onCancel" />
      </q-toolbar>
      <q-card-section>
        <draggable
          v-if="value.length"
          :list="value"
          itemKey="image"
          v-bind="{
            animation: 200,
            group: 'welcome',
            disabled: false,
          }"
          @change="(evt) => onDragEnd(evt, value)"
          handle=".cursor-move"
          :component-data="{
            type: 'transition-group',
            name: 'drag-area',
            class: 'q-list q-list--dense q-list--separator',
          }"
          :forceFallback="true"
          fallbackClass="dragging-item"
          :disabled="value.length <= 1"
        >
          <template #item="{ element: detail, index }">
            <div class="bg-white" v-if="filteredButton(detail)">
              <q-item class="q-pa-none">
                <q-item-section avatar class="cursor-move q-pr-none">
                  <q-icon name="swap_vert" color="grey" size="sm" />
                </q-item-section>
                <q-item-section>
                  <div class="row q-gutter-sm items-center">
                    <q-btn
                      size="xs"
                      class="btn-cancel btn-open btn-carousel"
                      outline
                      :label="index + 1"
                      style="width: 48px"
                    />
                    <div class="text-bold" v-if="detail.title">{{ detail.title }}</div>
                    <div class="text-grey-9" v-else>Non-title</div>
                  </div>
                </q-item-section>
              </q-item>
            </div>
          </template>
        </draggable>
        <div class="row no-wrap items-center">
          <q-space />
          <div class="q-gutter-sm">
            <q-btn no-caps flat class="btn-cancel" @click="onCancel">{{ $t('cancel') }} </q-btn>
            <q-btn no-caps class="btn-save" @click="onSubmit"> {{ $t('save') }} </q-btn>
          </div>
        </div>
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script lang='ts'>
import { Vue, Options } from 'vue-class-component'
import { maska } from 'maska'
import { Prop } from 'vue-property-decorator'
import TagCodeSingle from '@/components/common/ui/TagCodeSingle.vue'
import TagCode from '@/components/common/ui/TagCode.vue'
import { ICarouselButton } from '@/utils/types'
import draggable from 'vuedraggable'
import { calcOrderingDragend } from '@/utils/helpers'

@Options({
  components: { draggable, TagCode, TagCodeSingle },
  directives: { maska },
  emits: ['update:closeModal', 'update:modelValue'],
})
export default class CarouselButtonListingModal extends Vue {
  @Prop()
  modalVisible!: boolean

  @Prop({})
  modelValue!: ICarouselButton[]

  @Prop()
  is_use_image!: string

  get visible() {
    return this.modalVisible
  }

  set visible(value) {
    this.$emit('update:closeModal', value)
  }

  get value() {
    return this.modelValue
  }

  set value(value: ICarouselButton[]) {
    this.$emit('update:modelValue', value)
  }

  filteredButton(button: ICarouselButton) {
    if (this.is_use_image === 'image' && (button.image_url === '' || !button.image_url)) {
      return false
    }
    if ((!button.text || button.text === '') && (!button.title || button.title === '')) {
      return false
    }

    if (
      !button.actions ||
      !button.actions[0].type ||
      button.actions[0].type === '' ||
      !button.actions[0].content ||
      button.actions[0].content === ''
    ) {
      return false
    }

    return true
  }

  // eslint-disable-next-line
  async onDragEnd(evt: any, finalList: ICarouselButton[]) {
    if (!evt.moved) {
      return
    }

    const record = evt.moved.element
    calcOrderingDragend(record, finalList)
  }

  onCancel() {
    this.visible = false
  }

  onSubmit() {
    this.visible = false
  }
}
</script>

<style scoped lang='scss'>
.btn-open {
  padding: 2px 6px;
  :deep(span) {
    font-weight: unset;
    line-height: unset;
    font-size: unset;
    letter-spacing: unset;
  }
}
</style>
