import { render } from "./PreviewSelectorModal.vue?vue&type=template&id=8f183b92&scoped=true"
import script from "./PreviewSelectorModal.vue?vue&type=script&lang=ts"
export * from "./PreviewSelectorModal.vue?vue&type=script&lang=ts"

import "./PreviewSelectorModal.vue?vue&type=style&index=0&id=8f183b92&lang=scss&scoped=true"
script.render = render
script.__scopeId = "data-v-8f183b92"

export default script
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QToolbar from 'quasar/src/components/toolbar/QToolbar.js';
import QToolbarTitle from 'quasar/src/components/toolbar/QToolbarTitle.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QBanner from 'quasar/src/components/banner/QBanner.js';import ClosePopup from 'quasar/src/directives/ClosePopup.js';
import qInstall from "../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QDialog,QCard,QToolbar,QToolbarTitle,QBtn,QCardSection,QBanner});qInstall(script, 'directives', {ClosePopup});
