<template>
  <CLoading :loading="loading" />
  <q-table
    bordered
    flat
    dense
    title="Treats"
    ref="welcomeList"
    v-if="campaigns"
    :rows="campaigns"
    row-key="title"
    :rows-per-page-options="[10]"
    separator="cell"
    :no-data-label="$t('messages.no_data_available')"
    class="text-grey-8"
  >
    <template v-slot:header="props">
      <q-tr>
        <q-th rowspan="2" key="action" class="text-center" style="width: 50px"
          ><span class="text-bold">{{ $t('label.prod') }}</span></q-th
        >
        <q-th rowspan="2" key="action" class="text-center" style="width: 50px"
          ><span class="text-bold">{{ $t('label.test') }}</span></q-th
        >
        <q-th rowspan="2" key="action" class="text-center" style="width: 100px"></q-th>
        <q-th :props="props" rowspan="2" key="campaign_name">
          <span class="text-bold">{{ $t('label.campaign.campaign_name') }}</span>
        </q-th>
      </q-tr>
    </template>
    <template v-slot:body="props">
      <q-tr :props="props" :index="props.rowKey">
        <q-td>
          <q-toggle
            round
            v-model="props.row.is_active"
            val="props.row.is_active"
            @click="handleToggleActive(props.row)"
          />
        </q-td>
        <q-td>
          <q-toggle
            round
            v-model="props.row.is_test_mode"
            val="props.row.is_test_mode"
            @click="handleToggleTest(props.row)"
          />
        </q-td>
        <q-td key="action" :props="props.index">
          <q-btn size="sm" round outline icon="code" @click="handleDisplayTagCodeModal(props.row)" class="q-mr-sm" />
          <q-btn size="sm" round outline icon="edit" @click="onEdit(props.row)" class="q-mr-sm" />
          <q-btn size="sm" round outline color="grey" icon="content_copy" @click="onCopy(props.row)" class="q-mr-sm" />
          <q-btn size="sm" round outline color="red" icon="delete" @click="onDelete(props.row)" class="q-mr-sm" />
        </q-td>
        <q-td key="campaign_name" :props="props" @click="gotoPopups(props.row)" class="cursor-pointer">
          <span class="hyper-link">{{ props.row.campaign_name }}</span>
        </q-td>
      </q-tr>
    </template>

    <template v-slot:top>
      <h2 class="text-h6 text-black">{{ $t('label.campaign.campaign_list') }}</h2>
      <q-space />
      <q-btn no-caps color="primary" @click="onAdd(0)" :label="$t('add_new')" />
    </template>
  </q-table>

  <CampaignFormModal
    v-if="modalVisible"
    :modalVisible="modalVisible"
    :data="selectedCampaign"
    @update:closeModal="onCloseModal"
  />
  <TagCodeModal
    v-if="tagCodeModalVisible"
    :modalVisible="tagCodeModalVisible"
    :trackingCode="selectedCampaign._id"
    :campaignTagJsUrl="campaignTagJsUrl"
    @update:closeModal="onCloseTagCodeModal"
  />
</template>

<script lang="ts">
import { Options, mixins } from 'vue-class-component'
import { maska } from 'maska'
import { ICampaign } from '@/utils/types'
import { Watch } from 'vue-property-decorator'
import cloneDeep from 'lodash/cloneDeep'
import { ACTION_CAMPAIGN } from '@/store/actions'
import CLoading from '@/components/common/ui/CLoading.vue'
import CampaignFormModal from './CampaignFormModal.vue'
import TagCodeModal from '@/components/common/ui/TagCodeModal.vue'
import BaseFormMixin from '../common/mixins/BaseFormMixin.vue'

@Options({
  components: { CLoading, CampaignFormModal, TagCodeModal },
  directives: { maska },
  emits: [],
})
export default class CampaignList extends mixins(BaseFormMixin) {
  loading = true
  modalVisible = false
  tagCodeModalVisible = false
  selectedCampaign: ICampaign = {}

  get selectedAppId() {
    return this.$route.params.app_id
  }

  get campaigns() {
    return cloneDeep(this.$store.getters.campaigns) || []
  }

  get campaignTagJsUrl(): boolean {
    return this.$store.getters.tagjsUrl
  }

  @Watch('selectedAppId', { immediate: true })
  async appIdChanged() {
    if (!this.selectedAppId) {
      return
    }

    await this.$store.dispatch(ACTION_CAMPAIGN.LOAD_ITEMS, {
      app_id: this.selectedAppId,
      is_traffic_source: false,
    })
    await this.$store.dispatch(ACTION_CAMPAIGN.LOADED_TAG_JS)
    this.loading = false
  }

  async updateIsActive(item: ICampaign) {
    const isSuccess = await this.$store.dispatch(ACTION_CAMPAIGN.IS_ACTIVE, item)
    if (isSuccess) {
      this.$q.notify({
        message: this.$t('messages.saved'),
        color: 'positive',
      })
    }

    return isSuccess
  }

  async updateTestMode(item: ICampaign) {
    const isSuccess = await this.$store.dispatch(ACTION_CAMPAIGN.TEST_MODE, item)
    if (isSuccess) {
      this.$q.notify({
        message: this.$t('messages.saved'),
        color: 'positive',
      })
    }

    return isSuccess
  }

  async handleToggleActive(record: ICampaign) {
    if (!record.is_active) {
      this.updateIsActive(record)
    } else {
      this.$q
        .dialog({
          title: this.$t('messages.confirm'),
          message: this.$t('messages.are_you_sure_to_turn_on_active_for_this_item'),
          cancel: {
            flat: true,
            label: this.$t('messages.no'),
          },
          ok: {
            flat: true,
            label: this.$t('messages.yes'),
          },
          persistent: true,
        })
        .onOk(async () => {
          this.updateIsActive(record)
        })
        .onCancel(async () => {
          record.is_active = false
        })
    }
  }

  async handleToggleTest(record: ICampaign) {
    if (!record.is_test_mode) {
      this.updateTestMode(record)
    } else {
      this.$q
        .dialog({
          title: this.$t('messages.confirm'),
          message: this.$t('messages.are_you_sure_to_turn_on_active_for_this_item'),
          cancel: {
            flat: true,
            label: this.$t('messages.no'),
          },
          ok: {
            flat: true,
            label: this.$t('messages.yes'),
          },
          persistent: true,
        })
        .onOk(async () => {
          this.updateTestMode(record)
        })
        .onCancel(async () => {
          record.is_test_mode = false
        })
    }
  }

  onAdd() {
    this.selectedCampaign = {
      app_id: this.selectedAppId,
    }

    this.modalVisible = true
  }

  onCopy(data: ICampaign) {
    const campaignCopy: ICampaign = cloneDeep(data)
    campaignCopy._id = ''
    campaignCopy.copy_campaign_id = data._id
    campaignCopy.is_active = false
    campaignCopy.is_test_mode = false
    campaignCopy.is_traffic_source = false
    campaignCopy.story_id = ''
    campaignCopy.campaign_name = campaignCopy.campaign_name + ' Copy'
    this.selectedCampaign = campaignCopy
    this.modalVisible = true
  }

  onEdit(data: ICampaign) {
    this.selectedCampaign = data
    this.modalVisible = true
  }

  async onDelete(data: ICampaign) {
    if (data.story_id) {
      this.$q.dialog({
        title: this.$t('error'),
        message: this.$t('messages.campaign_created_from_story', {
          name: data.campaign_name,
        }),
        persistent: true,
        html: true,
      })
      return
    }
    this.$q
      .dialog({
        title: this.$t('confirm'),
        message: this.$t('are_you_sure_you_want_to_delete'),
        cancel: true,
        persistent: true,
      })
      .onOk(async () => {
        const success = await this.$store.dispatch(ACTION_CAMPAIGN.DELETE, data._id)
        if (success) {
          this.$q.notify({
            message: this.$t('messages.deleted'),
            color: 'positive',
          })
        }
      })
  }

  onCloseModal() {
    this.modalVisible = false
    this.selectedCampaign = {}
  }

  handleDisplayTagCodeModal(data: ICampaign) {
    this.selectedCampaign = data
    this.tagCodeModalVisible = true
  }

  onCloseTagCodeModal() {
    this.tagCodeModalVisible = false
    this.selectedCampaign = {}
  }

  gotoPopups(data: ICampaign) {
    this.goto('popups', {
      app_id: this.selectedAppId,
      campaign_id: data._id,
    })
  }

  goto(name: string, params = {}) {
    this.$router.push({
      name,
      params,
    })
  }
}
</script>
<style lang="sass" scoped></style>
