<template>
  <CLoading :loading="loading" />
  <TestModeSelector
    v-model="isTestMode"
    @update:detailCopy="onDetailCopy"
    v-if="form._id !== ''"
    :isHideCopy="tabs != 'scenarios'"
  />
  <q-tabs no-caps dense align="left" v-model="tabs" class="text-grey" active-color="primary" indicator-color="primary">
    <q-tab name="setting" :label="$t('setting')" :disable="isScenarioEditingMode" />
    <q-tab name="scenarios" :label="$t('scenarios')" />
    <q-tab name="report" :label="$t('report')" v-if="form._id !== ''" :disable="isScenarioEditingMode" />
    <q-tab name="edit" :label="$t('editing')" class="edit-info" content-class="text-blue" v-if="stateEdit" disable />
  </q-tabs>
  <q-form ref="formRef" greedy>
    <q-tab-panels v-model="tabs" animated>
      <q-tab-panel name="setting">
        <div class="q-my-md">
          <label class="text-grey-9 text-bold">{{ $t('label.initial.campaign_name') }}</label>
          <div class="q-mt-sm">
            <q-input
              lazy-rules
              v-model="form.title"
              dense
              outlined
              :placeholder="$t('label.initial.campaign_name')"
              class="text-h6"
              :rules="[(val) => (val && val.length > 0) || requiredRule.campaignName]"
            />
          </div>
        </div>

        <div class="q-my-md">
          <InitQuickReply v-model="form.test_detail.init_quick_reply" v-if="isTestMode" />
          <InitQuickReply v-model="form.prod_detail.init_quick_reply" v-else />
        </div>
        <div class="q-my-md">
          <WelcomeMessageForm v-model="form.test_detail.welcomes" v-if="isTestMode" />
          <WelcomeMessageForm v-model="form.prod_detail.welcomes" v-else />
        </div>
      </q-tab-panel>
      <q-tab-panel name="scenarios" class="q-pa-none bg-grey-5">
        <ScenariosMaker
          v-model="scenarios"
          :campaignId="form._id"
          :directionType="directionType"
          :isTestMode="isTestMode"
          :isReport="false"
          :isCopy="isCopy"
          :campaign="form"
          @update:editingMode="scenarioEditingMode"
          @update:onChangeDirection="onChangeDirection"
          @update:onChangeScenarios="onChangeScenarios"
          @update:editingStart="editStart"
          :key="componentKey"
          ref="scenarioEditor"
        />
      </q-tab-panel>
      <q-tab-panel name="report" class="q-pa-none bg-grey-5" v-if="form._id !== ''">
        <ScenariosMaker
          v-model="scenarios"
          :directionType="directionType"
          @update:editingMode="scenarioEditingMode"
          @update:editingStart="editStart"
          :isReport="true"
          :campaignId="form._id"
          :isTestMode="isTestMode"
          :key="componentKey"
        />
      </q-tab-panel>
    </q-tab-panels>

    <q-footer class="bg-grey-2 q-py-sm q-mx-xs" v-if="tabs !== 'preview'">
      <q-toolbar class="q-gutter-sm">
        <q-space />
        <q-btn :disable="isScenarioEditingMode" no-caps flat color="white" text-color="black" @click="onCancel">
          {{ $t('cancel') }}
        </q-btn>
        <q-btn
          :disable="isScenarioEditingMode || !stateEdit"
          no-caps
          color="primary"
          @click="onRevert"
          v-if="bar === 'scenarios'"
        >
          {{ $t('revert') }}</q-btn
        >
        <q-btn :disable="isScenarioEditingMode" no-caps color="primary" @click="onSubmit" v-if="bar !== 'report'">
          {{ saveBtnText }}
        </q-btn>
      </q-toolbar>
    </q-footer>
  </q-form>
</template>

<script lang="ts">
import { mixins, Options } from 'vue-class-component'
import { maska } from 'maska'
import cloneDeep from 'lodash/cloneDeep'
import { Watch } from 'vue-property-decorator'
import { constant } from '@/utils/constants'
import { IInitialCampaign, IValidRequest, IScenarioTemplate, IInitialCampaignDetail } from '@/utils/types'
import { ACTION_APP, ACTION_INITIAL, ACTION_SCENARIO_TEMPLATE } from '@/store/actions'
import ScenariosMaker from '@/components/scenarios/ScenariosMaker.vue'
import InitialCampaignMixin from './mixins/InitialCampaignMixin.vue'
import InitQuickReply from './setting/InitQuickReply.vue'
import WelcomeMessageForm from './setting/WelcomeMessageForm.vue'
import TestModeSelector from '@/components/common/ui/TestModeSelector.vue'
import BaseFormMixin from '../common/mixins/BaseFormMixin.vue'
import { ICard } from 'bot-flow-maker/src/types'
import CLoading from '@/components/common/ui/CLoading.vue'
import { ObjectUtils } from '@/utils/objects'
import { randomString } from '@/utils/helpers'

@Options({
  components: {
    ScenariosMaker,
    InitQuickReply,
    WelcomeMessageForm,
    TestModeSelector,
    CLoading,
  },
  directives: { maska },
  emits: [],
  async beforeRouteLeave() {
    if (!this) {
      return
    }
    this.isCancel = false
    const result = await this.checkDraftValue()
    if (result) {
      return true
    } else {
      return false
    }
  },
})
export default class CampaignSettingForm extends mixins(InitialCampaignMixin, BaseFormMixin) {
  isScenarioEditingMode = false
  isTestMode = false
  isCopy = false
  loading = true
  componentKey = 0
  bar = 'setting'
  scenarios: ICard[] = []
  directionType = '0'
  form: IInitialCampaign = {
    _id: '',
    app_id: this.selectedAppId,
    title: '',
    delivered: 0,
    is_active: false,
    is_test_mode: false,
    prod_detail: {
      direction_type: 1,
      welcomes: [
        {
          name: '',
          order: 1,
          fixed: false,
        },
      ],
      init_quick_reply: {
        is_auto_start: true,
        start_survey: '',
        is_start_survey: false,
        restart_survey: '',
        is_restart_survey: false,
      },
      scenarios: [],
    },
    test_detail: {
      direction_type: 1,
      welcomes: [
        {
          name: '',
          order: 1,
          fixed: false,
        },
      ],
      init_quick_reply: {
        is_auto_start: false,
        start_survey: '',
        is_start_survey: true,
        restart_survey: '',
        is_restart_survey: true,
      },
      scenarios: [],
    },
  }

  get tabs() {
    return this.bar
  }

  set tabs(tab: string) {
    this.bar = tab
  }

  get requiredRule() {
    const requiredRule = {
      campaignName: this.$t('validate.this_field_is_required', {
        placeholder: this.$t('label.initial.campaign_name'),
      }),
    }
    return requiredRule
  }

  get saveBtnText() {
    // if (this.bar === 'scenarios') {
    //   return this.$t('reflect')
    // }
    return this.$t('reflect')
  }

  get stateEdit() {
    return Object.keys(this.initForm).length && ObjectUtils.isDifference(this.parentForm, this.initForm)
  }

  async checkSizeCardsOfScenarios() {
    if (this.isNew) {
      return true
    }
    const { dispatch } = this.$store
    const params: IValidRequest = {
      app_id: this.form.app_id ?? '',
      initial_id: this.form._id ?? '',
      push_id: '',
      card_id: '',
      card_type: '',
      total_messages: 0,
      campaign: this.form,
    }
    const resp = await dispatch(ACTION_APP.CHECK_LIMIT_CARDS, params)
    if (resp.count > 5) {
      const card = resp.card
      this.$refs.scenarioEditor.handleErrorCard(card.uniqueId)
      this.$q.dialog({
        title: this.$t('error'),
        message: this.$t('messages.size_must_be_between_1_and_5', {
          card_title: card.title,
          count: resp.count,
          campaign_type: resp.campaign_type,
          campaign_title: resp.campaign_title,
        }),
        persistent: true,
        html: true,
      })
      return false
    }
    return true
  }

  scenarioEditingMode(editing: boolean) {
    this.isScenarioEditingMode = editing
  }

  async updateTestMode(item: IInitialCampaign) {
    const isSuccess = await this.$store.dispatch(ACTION_INITIAL.TEST_MODE, item)

    return isSuccess
  }

  onCancel() {
    this.testMode = this.isTestMode
    this.goto('initials', { app_id: this.selectedAppId })
  }

  async onRevert() {
    this.testMode = this.isTestMode
    this.isCancel = true
    const result = await this.checkDraftValueOnCampaign()
    if (result) {
      this.fetchSelectingIntial()
    }
  }

  onSubmit() {
    this.$refs.formRef
      .validate()
      .then(async (success: boolean) => {
        if (!success) {
          return
        }
        this.loading = true
        // check limit 5 cards
        if (!(await this.checkSizeCardsOfScenarios())) {
          this.loading = false
          return
        }
        await this.doSave()
        this.loading = false
      })
      .catch((error: unknown) => {
        console.log('error', error)
      })
  }

  async doSave() {
    const { dispatch } = this.$store
    let success = false

    if (!this.form._id) {
      this.form.is_active = false
      this.form.app_id = this.selectedAppId
      this.form.test_detail = this.form.prod_detail
      success = await dispatch(ACTION_INITIAL.ADD, {
        ...this.form,
      })
    } else {
      success = await dispatch(ACTION_INITIAL.UPDATE, {
        ...this.form,
      })
    }

    if (success) {
      this.$q.notify({
        message: this.$t('messages.saved'),
        color: 'positive',
      })

      this.initForm = cloneDeep(this.parentForm)
      this.goto('initials', { app_id: this.selectedAppId })
    }
  }

  onDetailCopy() {
    this.isCopy = !this.isCopy
  }

  @Watch('actionType', { immediate: true })
  async fetchSelectingIntial() {
    if (!this.selectedAppId) {
      return
    }

    if (this.selectedCampaignId !== '') {
      const data: IInitialCampaign = await this.$store.dispatch(ACTION_INITIAL.LOAD_ITEM, this.selectedCampaignId)
      if (data) {
        this.form = { ...this.form, ...data }
        if (this.actionType === constant.ACTION_TYPE.COPY) {
          this.form._id = ''
          this.form.title = this.form.title + ' Copy'
          this.form.is_test_mode = false
          this.form.is_active = false
          this.form.story_id = ''
          if (this.form.prod_detail) {
            this.form.prod_detail = this.getDetail(this.form.prod_detail)
          }
          if (this.form.test_detail) {
            this.form.test_detail = this.getDetail(this.form.test_detail)
          }
        }
      }
    }

    if (this.selectedScenarioTemplateId !== '') {
      const scenario_template: IScenarioTemplate = await this.$store.dispatch(
        ACTION_SCENARIO_TEMPLATE.LOAD_ITEM,
        this.selectedScenarioTemplateId
      )
      if (scenario_template) {
        if (this.form.test_detail) {
          this.form.test_detail.scenarios = scenario_template.scenario
        }
        if (this.form.prod_detail) {
          this.form.prod_detail.scenarios = scenario_template.scenario
        }
      }
    }

    this.parentForm = this.form
    this.initForm = cloneDeep(this.parentForm)

    this.onChangeTestMode()
  }

  getDetail(detail: IInitialCampaignDetail) {
    let scenarios = detail.scenarios
    if (scenarios) {
      for (const s of scenarios) {
        if (s.cardType !== 'start' && s.uniqueId !== 'welcome') {
          // eslint-disable-next-line dot-notation
          s['old_uniqueId'] = cloneDeep(s.uniqueId)
          s.uniqueId = randomString()
        }
      }

      scenarios = this.updateUniqueIdNextCard(scenarios)
    }

    detail.scenarios = scenarios
    return detail
  }

  updateUniqueIdNextCard(scenarios: ICard[]) {
    for (const scenario of scenarios) {
      for (const answer of scenario.answers) {
        for (const nextCard of answer.nextCards) {
          const uniqueId = this.getUniqueIdNextCard(scenarios, nextCard.uniqueId)
          if (uniqueId) {
            // eslint-disable-next-line dot-notation
            nextCard['old_uniqueId'] = cloneDeep(nextCard.uniqueId)
            nextCard.uniqueId = uniqueId
          }
        }
      }
    }
    return scenarios
  }

  getUniqueIdNextCard(scenarios: ICard[], uniqueId) {
    for (const scenario of scenarios) {
      // eslint-disable-next-line dot-notation
      if (scenario['old_uniqueId'] === uniqueId) {
        return scenario.uniqueId
      }
    }
    return null
  }

  onChangeDirection(value) {
    this.directionType = value
    if (this.isTestMode) {
      if (this.form.test_detail) {
        this.form.test_detail.direction_type = Number.parseInt(value)
      }
    } else {
      if (this.form.prod_detail) {
        this.form.prod_detail.direction_type = Number.parseInt(value)
      }
    }
  }

  onChangeScenarios(value) {
    this.scenarios = value
    if (this.isTestMode) {
      if (this.form.test_detail) {
        this.form.test_detail.scenarios = value
      }
    } else {
      if (this.form.prod_detail) {
        this.form.prod_detail.scenarios = value
      }
    }
  }

  editStart() {
    this.bar = 'setting'
  }

  @Watch('isTestMode', { immediate: true })
  async onChangeTestMode() {
    if (this.isTestMode) {
      if (this.form.test_detail) {
        this.scenarios = this.form.test_detail.scenarios ?? []
        this.directionType = this.form.test_detail.direction_type?.toString() ?? '0'
      }
    } else {
      if (this.form.prod_detail) {
        this.scenarios = this.form.prod_detail.scenarios ?? []
        this.directionType = this.form.prod_detail.direction_type?.toString() ?? '0'
      }
    }

    this.componentKey = this.componentKey + 1
  }

  async created() {
    this.loading = true
    this.fetchSelectingIntial()

    if (this.selectedCampaignId === '') {
      this.$nextTick(() => {
        this.$refs.formRef.validate()
      })
    }
    this.isCancel = false
    this.loading = false
  }
}
</script>

<style lang="scss" scoped>
:deep(.edit-info.disabled),
:deep(.edit-info.disabled *) {
  cursor: default !important;
}
</style>
