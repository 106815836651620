<template>
  <div class="row" style="height: 100%">
    <div class="col" style="border-right: 1px solid #d0d2e0; overflow-y: auto; max-height: calc(100vh - 200px)">
      <div class="flex justify-between" style="border-bottom: 1px solid #d0d2e0">
        <div class="text-h5 q-ml-md q-pa-sm" style="border-bottom: 1px solid black">
          {{ $t('label.story.popup_selection') }}
        </div>
        <div class="flex" v-if="popupList.length">
          <q-btn
            flat
            padding="xs"
            :color="viewState === 'grid' ? 'primary' : 'grey'"
            icon="grid_view"
            @click="viewState = 'grid'"
          />
          <q-btn
            flat
            padding="xs"
            :color="viewState === 'list' ? 'primary' : 'grey'"
            icon="view_list"
            @click="viewState = 'list'"
          />
        </div>
      </div>
      <div class="q-mx-md q-mt-sm" v-if="campaign?._id">
        <TagCode :trackingCode="trackingCode" :tagJsUrl="campaignTagJsUrl" v-if="campaignTagJsUrl"></TagCode>
      </div>
      <div class="q-mx-md q-mt-sm">
        上記タグを設置したページ内で表示させるポップアップを追加し、使用するポップアップを選択してください
      </div>
      <div v-if="!setting" class="flex justify-center items-center q-mx-md q-mt-sm bg-white" style="height: 260px">
        <div class="text-h5 text-grey">{{ $t('label.story.please_select_popup') }}</div>
      </div>
      <div v-else class="q-mx-md q-mt-sm q-px-md q-py-sm bg-white">
        <q-form ref="popupForm" greedy>
          <div>
            <div style="float: left" class="q-mr-sm">
              <q-avatar rounded size="110px" v-if="selectedPopup.images.length" class="dotted-border">
                <img
                  :src="selectedPopup.images[0].pc.img_thumb"
                  @click="modalMediaVisible = true"
                  class="cursor-pointer"
                />
              </q-avatar>
              <q-avatar
                rounded
                size="110px"
                icon="image"
                color="grey-3"
                text-color="grey"
                v-else
                class="dotted-border"
              />
              <div v-if="errorImageMessage !== ''">
                <span class="text-red">{{ errorImageMessage }}</span>
              </div>
            </div>
            <div class="q-mt-sm">
              <div>
                <label class="text-grey-8">{{ $t('label.popup.popup_name') }}</label>
                <q-input
                  lazy-rules
                  :rules="[(val) => (val && val.length > 0) || requiredRule.popup_name]"
                  outlined
                  v-model="selectedPopup.popup_name"
                  dense
                  :placeholder="$t('label.popup.popup_name')"
                >
                </q-input>
              </div>
              <div class="q-mt-sm row" v-if="selectedPopup.images">
                <div class="q-mr-sm q-mb-sm">
                  <q-file
                    name="image_file"
                    :label="$t('label.common.messages.add_image')"
                    multiple
                    outlined
                    dense
                    accept=".png, .jpeg, .jpg"
                    v-model="files"
                    bg-color="btn-upload"
                    label-color="btn-upload"
                    class="q-file-btn"
                    style="max-width: 130px"
                  />
                </div>
                <div style="flex: 1">
                  <MediaUploader :light-style="true" @on-select-media="onSelectMedia"></MediaUploader>
                </div>
              </div>
            </div>
          </div>
          <div>
            <ul style="padding-left: 20px; margin-top: 4px">
              <li>画像形式: jpeg / png / gif</li>
              <li>推奨サイズ: 1024px * 1024px (width * height = 1:1)</li>
              <li>画像サイズ: 10MB</li>
            </ul>
          </div>
          <q-btn
            no-caps
            :disable="statusMode(selectedPopup)"
            class="full-width q-mb-sm"
            :class="
              statusMode(selectedPopup) || selectedPopup.images.length === 0
                ? 'btn-save-grey'
                : selectedPopup._id === ''
                ? 'btn-save'
                : 'btn-save-green'
            "
            @click="onSelectPopup"
            >{{ selectPopupState }}</q-btn
          >
        </q-form>
      </div>
      <div class="q-mx-md q-mt-sm">
        <q-btn unelevated color="white" text-color="black" class="full-width dotted-grey-border" @click="addNew">
          <q-icon left size="1em" name="add" />
          <div>新規ポップアップ追加</div>
        </q-btn>
      </div>
      <div class="q-ma-md" v-if="popupList.length" style="max-height: 250px; overflow-y: auto">
        <div class="grid-view" v-if="viewState === 'grid'">
          <PopupGridView v-model="selectedPopup" :popupList="popupList" :isTestMode="isTestMode"></PopupGridView>
        </div>
        <div class="list-view" v-if="viewState === 'list'">
          <PopupListView v-model="selectedPopup" :popupList="popupList" :isTestMode="isTestMode"></PopupListView>
        </div>
      </div>
    </div>
    <div
      class="col-xl-5 col-lg-5 col-md-5 col-sm-12 col-xs-12"
      style="overflow-y: auto; max-height: calc(100vh - 200px); min-width: 450px"
    >
      <div class="flex justify-between" style="border-bottom: 1px solid #d0d2e0">
        <div class="text-h5 q-ml-md q-pa-sm" style="border-bottom: 1px solid black">
          {{ $t('label.story.line_guidance_banner') }}
        </div>
        <div style="width: 10px"></div>
      </div>
      <div class="q-ml-md q-mt-sm">
        <div class="flex items-center">
          <div class="text-subtitle1 text-grey-8 q-mr-md">
            ページ内でポップアップを表示させる条件を設定してください。
          </div>
        </div>
        <q-form ref="campaignForm" greedy>
          <TriggerSettingSelector v-model="campaign.test_detail.events_setting" :in-modal="false" v-if="isTestMode" />
          <TriggerSettingSelector v-model="campaign.prod_detail.events_setting" :in-modal="false" v-else />
        </q-form>
      </div>
      <div class="q-ml-md q-mt-sm">
        <div class="text-h5">
          {{ $t('label.story.line_guidance_banner') }}
        </div>
        <div class="text-subtitle1 text-grey-8 q-mr-md">このストーリーからLINEチャンネルへ誘導するQRコード／URL</div>
        <div style="display: flex; justify-content: center">
          <div v-if="qrCodeUrl">
            <img :src="qrCodeUrl" width="210" />
          </div>
          <div v-else class="empty-qr-code">
            ポップアップを選択して、<br />
            保存してください
          </div>
        </div>
        <div style="display: flex; justify-content: center">
          <q-btn
            no-caps
            style="width: 210px"
            class="q-mt-sm"
            :disable="qrCodeUrl === ''"
            :class="qrCodeUrl === '' ? 'btn-save-grey' : 'btn-save'"
            @click="onCopyLinkPopup"
            >URLコピー
          </q-btn>
        </div>
      </div>
    </div>
  </div>
  <UploadingProgress :files="files" />
  <MediaDetailsModal
    v-if="selectedPopup.images.length"
    :modalVisible="modalMediaVisible"
    :mediaUrl="selectedPopup.images[0].pc.img_org"
    :mediaName="selectedPopup.images[0].pc.filename"
    @update:closeModal="modalMediaVisible = false"
  ></MediaDetailsModal>
</template>

<script lang="ts">
import { Vue, Options } from 'vue-class-component'
import { IApp, ICampaign, IMedia, IPopup, IStory, IUploadFileResponse } from '@/utils/types'
import { ACTION_APP, ACTION_CAMPAIGN, ACTION_POPUP } from '@/store/actions'
import { Prop, Watch } from 'vue-property-decorator'
import { constant } from '@/utils/constants'
import cloneDeep from 'lodash/cloneDeep'
import UploadApi from '@/api/upload'
import { maska } from 'maska'
import QRCode from 'qrcode'
import PopupGridView from '@/components/story/common/PopupGridView.vue'
import MediaUploader from '@/components/media-manager/MediaUploader.vue'
import TagCode from '@/components/story/common/TagCode.vue'
import PopupListView from '@/components/story/common/PopupListView.vue'
import TriggerSettingSelector from '@/components/campaign/selectors/TriggerSettingSelector.vue'
import UploadingProgress from '@/components/common/ui/UploadingProgress.vue'
import MediaDetailsModal from '@/components/media-manager/MediaDetailsModal.vue'

@Options({
  components: {
    MediaDetailsModal,
    UploadingProgress,
    TriggerSettingSelector,
    PopupListView,
    TagCode,
    MediaUploader,
    PopupGridView,
  },
  directives: { maska },
  emits: ['update:modelValue', 'update:isSettingValue', 'update:onSave', 'update:onNextStep'],
})
export default class CampaignPopup extends Vue {
  @Prop({})
  modelValue!: IStory

  @Prop()
  isSetting!: boolean

  @Prop({})
  isTestMode!: boolean

  qrCodeUrl = ''
  selectingApp: IApp = {}
  popupList: IPopup[] = []
  viewState = 'grid'
  files: File[] = []
  errorImageMessage = ''
  modalMediaVisible = false

  blankPopup: IPopup = {
    _id: '',
    app_id: '',
    campaign_id: '',
    is_active: false,
    is_test_mode: false,
    display_id: '',
    popup_name: '',
    images: [],
    animation_type: constant.animation_type.fade_in,
  }

  campaign: ICampaign = {
    _id: '',
    app_id: '',
    is_active: false,
    is_test_mode: false,
    is_traffic_source: false,
    campaign_name: '',
    prod_detail: {
      events_setting: {
        back_button_click: {
          is_active: true,
          force_display: true,
          seconds: 0,
        },
        mouse_out_screen: {
          is_active: true,
          seconds: 2,
        },
        after_page_loaded: {
          is_active: true,
          seconds: 50,
        },
        scroll_up: {
          is_active: true,
          position: 1,
        },
        scroll_down: {
          is_active: true,
          position: 1,
        },
        number_display_trigger: {
          is_active: true,
          number: 1,
        },
      },
    },
    test_detail: {
      events_setting: {
        back_button_click: {
          is_active: true,
          force_display: true,
          seconds: 0,
        },
        mouse_out_screen: {
          is_active: true,
          seconds: 2,
        },
        after_page_loaded: {
          is_active: true,
          seconds: 50,
        },
        scroll_up: {
          is_active: true,
          position: 1,
        },
        scroll_down: {
          is_active: true,
          position: 1,
        },
        number_display_trigger: {
          is_active: true,
          number: 1,
        },
      },
    },
  }

  selectedPopup: IPopup = cloneDeep(this.blankPopup)

  get trackingCode() {
    return this.campaign._id + ';' + this.isTestMode + ';' + this.selectedAppId
  }

  get selectedAppId() {
    return this.$route.params.app_id
  }

  get form() {
    return this.modelValue
  }

  set form(value: IStory) {
    this.$emit('update:modelValue', value)
  }

  get setting() {
    return this.isSetting
  }

  set setting(value: boolean) {
    this.$emit('update:isSettingValue', value)
  }

  statusMode(item: IPopup) {
    if (this.isTestMode) {
      return item.is_test_mode ?? false
    } else {
      return item.is_active ?? false
    }
  }

  get requiredRule() {
    return {
      campaign_name: this.$t('validate.this_field_is_required', {
        placeholder: this.$t('label.campaign.campaign_name'),
      }),
      popup_name: this.$t('validate.this_field_is_required', {
        placeholder: this.$t('label.popup.popup_name'),
      }),
      messageImage: this.$t('validate.this_field_is_required', {
        placeholder: this.$t('label.common.messages.image'),
      }),
    }
  }

  get campaignTagJsUrl(): boolean {
    return this.$store.getters.tagjsUrl
  }

  get selectPopupState() {
    if (this.selectedPopup._id !== '' && this.statusMode(this.selectedPopup)) {
      return 'このポップアップを使用中'
    }
    if (this.selectedPopup._id !== '' && !this.statusMode(this.selectedPopup)) {
      return 'このポップアップを使用する'
    }

    return 'このポップアップを追加'
  }

  addNew() {
    this.selectedPopup = cloneDeep(this.blankPopup)
    this.setting = true
  }

  async onSelectMedia(file: IMedia) {
    this.errorImageMessage = ''
    if (file) {
      const imageSetting = {
        img_org: file.url ?? '',
        img_thumb: file.thumb ?? '',
        filename: file.name ?? '',
      }
      this.selectedPopup.images = [
        {
          pc: imageSetting,
          sp: JSON.parse(JSON.stringify(imageSetting)),
        },
      ]
    }
  }

  validate(isSave = false) {
    if (this.setting) {
      this.$refs.campaignForm
        .validate()
        .then(async (success: boolean) => {
          if (!success) {
            return
          }

          this.form.campaign = this.campaign
          this.$refs.popupForm
            .validate()
            .then(async (success: boolean) => {
              if (!success || !this.validatePopup()) {
                return
              }
              if (this.form.popups && !this.selectedPopup._id) {
                const existingPopup = this.form.popups.find(
                  (popup) => popup._id === '' && popup.popup_name === this.selectedPopup.popup_name
                )
                if (existingPopup) {
                  Object.assign(existingPopup, this.selectedPopup)
                } else {
                  this.selectedPopup.campaign_id = this.campaign._id
                  this.selectedPopup.app_id = this.campaign.app_id
                  this.form.popups.push(this.selectedPopup)
                }
              }
              if (isSave) {
                this.$emit('update:onSave')
              } else {
                this.$emit('update:onNextStep')
              }
            })
            .catch((error: unknown) => {
              console.log('error', error)
            })
        })
        .catch((error: unknown) => {
          console.log('error', error)
        })
    } else {
      this.$q.dialog({
        title: this.$t('error'),
        message: this.$t('messages.please_add_popup'),
        persistent: true,
        html: true,
      })
    }
  }

  validatePopup() {
    this.errorImageMessage = ''
    if (!this.selectedPopup.images || this.selectedPopup.images.length === 0) {
      this.errorImageMessage = this.requiredRule.messageImage
      return false
    }

    return true
  }

  @Watch('files')
  async handleUpload() {
    this.errorImageMessage = ''
    if (!this.files.length) {
      return false
    }
    try {
      const uploaded: IUploadFileResponse = await UploadApi.uploadFile(this.files[0])
      if (uploaded) {
        const imageSetting = {
          img_org: uploaded.file_url_org ?? '',
          img_thumb: uploaded.file_url_thumb ?? '',
          filename: uploaded.filename ?? '',
        }
        this.selectedPopup.images = [
          {
            pc: imageSetting,
            sp: JSON.parse(JSON.stringify(imageSetting)),
          },
        ]
      }
    } catch (error) {
      console.log('error', error)
    } finally {
      this.files = []
    }
  }

  @Watch('selectedPopup', { immediate: true })
  async onChangePopupName() {
    this.qrCodeUrl = ''
    this.selectedPopup.redirect_url = this.getTrackingURL()
    if (this.selectedPopup.redirect_url) {
      this.qrCodeUrl = await QRCode.toDataURL(this.selectedPopup.redirect_url, { errorCorrectionLevel: 'H' })
    }
  }

  getTrackingURL() {
    if (this.selectedPopup.popup_name === '') {
      return ''
    }

    let accountId = this.selectingApp.account_id
    let liffId = this.selectingApp.liff_id
    if (this.isTestMode) {
      accountId = this.selectingApp.account_id_test
      liffId = this.selectingApp.liff_id_test
    }

    const params = encodeURIComponent(
      `account_id=${accountId}&liff_id=${liffId}&is_test_mode=${this.isTestMode}&story_id=${this.form._id}&campaign_id=${this.form.campaign?._id}&popup_id=${this.selectedPopup._id}&source_id=${this.selectedPopup._id}`
    )
    const url = `https://liff.line.me/${liffId}?${params}`
    return url
  }

  async onSelectPopup() {
    if (!this.selectedPopup._id) {
      this.validate(true)
      return
    }
    if (this.form.popups) {
      this.$q
        .dialog({
          title: this.$t('messages.confirm_please_note'),
          message: this.$t('messages.please_diasble_popup_story'),
          cancel: {
            flat: true,
            label: this.$t('cancel'),
          },
          persistent: true,
          html: true,
        })
        .onOk(async () => {
          if (this.form.popups) {
            let items: IPopup[] = []
            if (this.isTestMode) {
              items = this.form.popups.filter((item) => item.is_test_mode === true)
            } else {
              items = this.form.popups.filter((item) => item.is_active === true)
            }

            for (const item of items) {
              if (this.isTestMode) {
                item.is_test_mode = false
              } else {
                item.is_active = false
              }
              await this.updateStatusMode(item, this.isTestMode)
            }
          }

          if (this.isTestMode) {
            this.selectedPopup.is_test_mode = true
          } else {
            this.selectedPopup.is_active = true
          }

          await this.updateStatusMode(this.selectedPopup, this.isTestMode)
        })
    }
  }

  onCopyLinkPopup(): void {
    const myFluffyTextarea = document.createElement('textarea')

    myFluffyTextarea.innerHTML = this.selectedPopup.redirect_url ?? ''

    const parentElement = document.getElementById('tabcode')
    if (!parentElement) {
      return
    }

    parentElement.appendChild(myFluffyTextarea)
    myFluffyTextarea.select()

    document.execCommand('copy')

    parentElement.removeChild(myFluffyTextarea)
    this.tagCopySuccess()
  }

  tagCopySuccess(): void {
    this.$q.notify({
      message: this.$t('label.copied'),
      color: 'positive',
    })
  }

  async updateStatusMode(item: IPopup, statusMode) {
    let mod = ACTION_POPUP.IS_ACTIVE
    if (statusMode) {
      mod = ACTION_POPUP.TEST_MODE
    }
    const isSuccess = await this.$store.dispatch(mod, item)
    if (isSuccess) {
      this.$q.notify({
        message: this.$t('messages.saved'),
        color: 'positive',
      })
    }

    return isSuccess
  }

  async created() {
    this.selectingApp = await this.$store.dispatch(ACTION_APP.LOAD_ITEM, this.selectedAppId)
    await this.$store.dispatch(ACTION_CAMPAIGN.LOADED_TAG_JS)
    if (this.form._id) {
      this.campaign = { ...this.campaign, ...cloneDeep(this.form.campaign) }
      this.popupList = this.form.popups ?? []
      let item
      if (this.isTestMode) {
        item = this.popupList.find((item) => item.is_test_mode === true)
      } else {
        item = this.popupList.find((item) => item.is_active === true)
      }

      if (item) {
        this.selectedPopup = cloneDeep(item)
      }
    }
  }
}
</script>

<style scoped lang="scss">
.q-field--with-bottom {
  padding-bottom: 0;
}
.q-field--with-bottom:has(.text-negative) {
  padding-bottom: 20px;
}
.empty-qr-code {
  width: 210px;
  height: 210px;
  border: 1px solid black;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 12px;
  margin-bottom: 4px;
}
</style>
