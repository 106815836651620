<template>
  <q-dialog v-model="visible" full-height position="right">
    <q-card style="width: 1000px; max-width: 90vw">
      <q-toolbar>
        <q-toolbar-title>
          {{ modalTitle }}
        </q-toolbar-title>
        <q-btn flat round dense icon="close" @click="onCancel" />
      </q-toolbar>
      <q-card-section>
        <q-form ref="formRef" greedy>
          <div class="max-height-dialog">
            <div>
              <label class="text-grey-9 text-bold" v-if="form._id !== ''">{{ $t('label.display_id') }}</label>
              <TagInputContent :tagValue="form.display_id" v-if="form._id !== ''"></TagInputContent>
              <label class="text-grey-9 text-bold">{{ $t('label.form.form_name') }}</label>
              <q-input
                lazy-rules
                :rules="[(val) => (val && val.length > 0) || requiredRule.formName]"
                outlined
                v-model="form.title"
                dense
                :placeholder="$t('label.form.form_name')"
                ref="refTitle"
              >
              </q-input>
            </div>
            <div>
              <FormDetail v-model="form.details" ref="detailsForm"></FormDetail>
            </div>
            <q-space class="q-my-md" />
          </div>
          <div class="row no-wrap items-center">
            <q-space />
            <div class="q-gutter-sm">
              <q-btn no-caps flat color="white" text-color="black" @click="onCancel">{{ $t('cancel') }}</q-btn>
              <q-btn no-caps color="primary" @click="onSubmit"> {{ $t('save') }} </q-btn>
            </div>
          </div>
        </q-form>
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script lang="ts">
import { mixins, Options } from 'vue-class-component'
import BaseFormDialogMixin from '@/components/common/mixins/BaseFormDialogMixin.vue'
import { Prop } from 'vue-property-decorator'
import { IForm, IInitialCampaign, IPushCampaign } from '@/utils/types'
import { ACTION_FORM } from '@/store/actions'
import cloneDeep from 'lodash/cloneDeep'
import TagInputContent from '@/components/common/ui/TagInputContent.vue'
import InputEmojiSelector from '@/components/common/ui/InputEmojiSelector.vue'
import FormDetail from '@/components/form/FormDetail.vue'

@Options({
  components: { FormDetail, InputEmojiSelector, TagInputContent },
  emits: ['update:onSelect', 'update:onCreate'],
})
export default class FormFormModal extends mixins(BaseFormDialogMixin) {
  @Prop()
  data!: IForm

  @Prop()
  campaign!: IInitialCampaign | IPushCampaign

  @Prop({ default: '' })
  appId!: string

  form: IForm = {
    _id: '',
    title: '',
    app_id: this.appId,
    message: '',
    details: [],
    applied_campain: 0,
  }

  get requiredRule() {
    return {
      formName: this.$t('validate.this_field_is_required', {
        placeholder: this.$t('label.form.form_name'),
      }),
    }
  }

  get modalTitle() {
    return this.isNew ? this.$t('label.form.add_new_form') : this.$t('label.form.edit_form')
  }

  onSubmit() {
    this.$refs.formRef
      .validate()
      .then(async (success: boolean) => {
        if (!success) {
          return
        }

        // check limit 5 cards
        if (!(await this.checkSizeCardsOfScenarios(this.campaign))) {
          return
        }

        await this.doSave()
      })
      .catch((error: unknown) => {
        console.log('error', error)
      })
  }

  async doSave() {
    const { dispatch } = this.$store
    let form = null
    const isNew = this.isNew
    if (isNew) {
      form = await dispatch(ACTION_FORM.ADD_NEW, {
        ...this.form,
      })
    } else {
      form = await dispatch(ACTION_FORM.UPDATE, {
        _id: this.data._id,
        ...this.form,
      })
    }

    if (form) {
      if (isNew) {
        this.$emit('update:onCreate', form)
      } else {
        this.$emit('update:onSelect', form)
      }
      this.$q.notify({
        message: this.$t('messages.saved'),
        color: 'positive',
      })
    }

    this.visible = false
  }

  mounted() {
    this.$nextTick(() => {
      this.$refs.formRef.validate()
      this.$refs.refTitle.focus()
    })
  }

  async created() {
    this.form = { ...this.form, ...cloneDeep(this.data) }
    this.parentForm = this.form
    this.initForm = cloneDeep(this.parentForm)
  }
}
</script>

<style scoped lang='scss'>
</style>
