<template>
  <q-list :bordered="!isQuestion" separator>
    <q-item>
      <div class="full-width">
        <div class="text-h6 text-bold q-mb-sm">テンプレート選択</div>
        <div class="q-mb-sm">以下のパターンから選択してください</div>
        <div class="row q-gutter-md" style="min-width: 400px">
          <div
            class="col cursor-pointer"
            @click="value.is_use_image = 'image'"
            :class="value.is_use_image === 'image' ? 'selected-block' : ''"
          >
            <q-card class="q-pa-sm card-radius">
              <q-card-section>
                <div class="text-caption q-pb-sm">画像あり</div>
                <div
                  class="text-bold"
                  style="
                    height: 100px;
                    background-color: #d9d9d9;
                    border-radius: 5px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                  "
                >
                  <q-img
                    :src="lineButtonTemplate"
                    style="max-width: 306px; height: 100px; border-radius: 5px"
                    fit="cover"
                  />
                </div>
                <div class="text-subtitle2 text-bold q-pl-md">タイトル</div>
                <div class="text-subtitle2 q-pl-md text-subtitle2">ここに文章を入力してください</div>
              </q-card-section>

              <q-card-actions vertical align="center">
                <q-btn flat no-caps class="btn-action">アクションを設定</q-btn>
                <q-btn flat no-caps class="btn-action">アクションを設定</q-btn>
              </q-card-actions>
            </q-card>
          </div>
          <div
            class="col cursor-pointer"
            style="background-color: #d9d9d91a"
            :class="value.is_use_image === 'no-image' ? 'selected-block' : ''"
            @click="value.is_use_image = 'no-image'"
          >
            <q-card class="q-pa-sm card-radius">
              <q-card-section>
                <div class="text-caption q-pb-sm">テキストのみ</div>
                <div class="text-subtitle2 text-bold q-pl-md">タイトル</div>
                <div class="text-subtitle2 q-pl-md text-subtitle2">ここに文章を入力してください</div>
              </q-card-section>

              <q-card-actions vertical align="center">
                <q-btn flat no-caps class="btn-action">アクションを設定</q-btn>
                <q-btn flat no-caps class="btn-action">アクションを設定</q-btn>
              </q-card-actions>
            </q-card>
          </div>
        </div>
        <div v-if="value.is_use_image && value.is_use_image !== ''">
          <div class="horizontal-line q-my-md"></div>
          <div class="row justify-between">
            <q-toggle label="カルーセル（最大10）" v-model="value.is_carousel" class="text-bold" />
            <q-btn
              size="xs"
              class="btn-cancel btn-sort"
              outline
              label="並び替え"
              @click="carouselButtonsModalVisible = true"
              v-if="value.is_carousel"
              no-caps
            />
          </div>
          <div class="row q-mt-xs" v-if="value.is_carousel">
            <div
              class="col q-pa-xs"
              :class="carouselIndexSelected === index ? 'selected-block' : ''"
              v-for="(item, index) in value.carousel_images"
              :key="index"
            >
              <q-btn
                size="xs"
                class="btn-cancel btn-open btn-carousel full-width"
                :class="carouselIndexSelected === index ? 'selected' : ''"
                outline
                :label="index + 1"
                @click="carouselIndexSelected = index"
              />
            </div>
          </div>
          <div v-if="value.is_use_image === 'image'">
            <div class="text-h6 text-bold q-mb-sm">画像</div>
            <div class="row q-gutter-md">
              <div class="col">
                <div
                  class="justify-around items-center q-mb-sm q-ml-sm q-pa-lg"
                  style="
                    border: 1px dotted #333333;
                    border-radius: 12px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                  "
                >
                  <div v-if="currentButton.image_url" style="position: relative; display: inline-block">
                    <!-- Button positioned above the image -->
                    <q-btn
                      size="sm"
                      no-caps
                      round
                      outline
                      icon="delete"
                      color="red"
                      @click="onRemoveImage()"
                      style="position: absolute; top: -15px; right: -10px; z-index: 1"
                    />
                    <!-- Image displayed below the button -->
                    <img
                      :src="currentButton.image_url"
                      style="height: 100px; display: block; margin-top: 15px"
                      class="cursor-pointer"
                      @click="onOpenMediaDetails(currentButton)"
                    />
                  </div>

                  <div class="column q-gutter-sm items-center" v-else>
                    <q-btn round flat color="white" text-color="black" icon="add" class="dotted-border" />
                    <div class="text-center q-mb-sm">さらに画像を追加する</div>
                  </div>
                </div>
                <div>
                  <q-file
                    :label="$t('label.common.messages.add_image')"
                    outlined
                    dense
                    accept=".jpg, image/*"
                    v-model="file"
                    bg-color="btn-upload"
                    label-color="btn-upload"
                    class="q-file-btn"
                  >
                  </q-file>
                  <div class="q-pt-sm q-pb-sm">
                    <MediaUploader
                      :light-style="true"
                      :is-crop-image="true"
                      :crop-ratio="(currentButton.image_aspect_ratio ?? 1.51) + ':1'"
                      :aspect-ratio="currentButton.image_aspect_ratio ?? 1.51"
                      @on-select-media="onSelectMedia"
                    ></MediaUploader>
                  </div>
                  <div class="q-ml-sm">
                    <label class="text-grey-8 text-bold">使用する画像は規定内のものに限ります</label>
                    <div class="text-grey-8 q-pl-sm">・フォーマット: JPEG または PNG</div>
                    <div class="text-grey-8 q-pl-sm">・最大横幅サイズ：1,024px</div>
                    <div class="text-grey-8 q-pl-sm">・容量上限: 10 MB</div>
                  </div>
                </div>
              </div>
              <div class="col image-atribute">
                <div class="text-caption q-ml-sm q-pb-sm">オプション</div>
                <div class="text-bold q-ml-sm">画像比率</div>
                <div>
                  <q-list class="image-opt">
                    <q-item tag="image_aspect_ratio">
                      <q-item-section avatar>
                        <q-radio v-model="currentButton.image_aspect_ratio" :val="1.51" color="black" />
                      </q-item-section>
                      <q-item-section>
                        <q-item-label class="text-bold">レクタングル（1.51:1）</q-item-label>
                      </q-item-section>
                    </q-item>

                    <q-item tag="image_aspect_ratio">
                      <q-item-section avatar top>
                        <q-radio v-model="currentButton.image_aspect_ratio" :val="1" color="black" />
                      </q-item-section>
                      <q-item-section>
                        <q-item-label class="text-bold">スクエア（1:1）</q-item-label>
                      </q-item-section>
                    </q-item>
                  </q-list>
                </div>
                <div class="text-bold q-ml-sm">画像サイズ</div>
                <div class="flex justify-between q-mb-sm">
                  <q-list class="image-opt">
                    <q-item tag="image_size">
                      <q-item-section avatar>
                        <q-radio v-model="currentButton.image_size" val="cover" color="black" />
                      </q-item-section>
                      <q-item-section>
                        <q-item-label class="text-bold">カバー</q-item-label>
                        <q-item-label style="font-size: 8px"
                          >（画像領域に収まらない部分は切り詰められます）</q-item-label
                        >
                      </q-item-section>
                    </q-item>

                    <q-item tag="image_size">
                      <q-item-section avatar top>
                        <q-radio v-model="currentButton.image_size" val="contain" color="black" />
                      </q-item-section>
                      <q-item-section>
                        <q-item-label class="text-bold">コンテイン</q-item-label>
                        <q-item-label style="font-size: 8px"
                          >（縦長の画像では左右に、横長の画像では上下に余白が表示されます）</q-item-label
                        >
                      </q-item-section>
                    </q-item>
                  </q-list>
                </div>
                <div class="text-bold q-ml-sm q-mb-sm">画像背景色</div>
                <q-input outlined v-model="currentButton.image_background_color" dense class="q-mb-sm q-ml-sm">
                  <template v-slot:append>
                    <q-icon name="colorize" class="cursor-pointer">
                      <q-popup-proxy cover transition-show="scale" transition-hide="scale">
                        <q-color v-model="currentButton.image_background_color" />
                      </q-popup-proxy>
                    </q-icon>
                  </template>
                </q-input>
              </div>
            </div>
            <div class="horizontal-line q-my-md"></div>
          </div>

          <div class="text-h6 text-bold q-mb-sm">説明</div>
          <div class="q-mb-sm">ボタンのアクションを説明するタイトルやテキストを設定してください</div>
          <div class="text-bold q-mb-sm">タイトル</div>
          <q-input
            lazy-rules
            outlined
            v-model="currentButton.title"
            dense
            :placeholder="$t('label.common.answer.button_title')"
            class="col q-mb-sm"
            ref="refTitle"
          />
          <div class="text-bold q-mb-sm">テキスト*</div>
          <q-input
            type="textarea"
            lazy-rules
            :rules="[(val) => (val && val.length) || requiredRule.text]"
            outlined
            v-model="currentButton.text"
            dense
            :placeholder="$t('label.common.answer.button_text')"
            class="col"
            ref="refText"
          />
          <div class="horizontal-line q-my-md"></div>
          <div class="text-h6 text-bold q-mb-sm">アクション</div>
          <div class="q-mb-sm" v-if="!value.is_carousel">ボタンに設定するアクションを編集してください（最大４件）</div>
          <div class="q-mb-sm" v-if="value.is_carousel">ボタンに設定するアクションを編集してください（最大３件）</div>
          <div class="q-mb-sm" v-if="value.is_carousel">
            ※カルーセルの場合は、アクション数を統一する必要があります。
          </div>
          <template v-for="(action, index) in currentButton.actions" :key="index">
            <q-expansion-item
              :label="index === 0 ? 'アクション：1*' : 'アクション：' + (index + 1)"
              dense
              class="cus-expansion"
              :default-opened="index === 0 ? true : false"
              v-if="!value.is_carousel || (value.is_carousel && index < 3)"
            >
              <q-select
                v-if="!isQuestion"
                outlined
                dense
                option-value="id"
                option-label="title"
                v-model="action.type"
                :options="options"
                emit-value
                map-options
                :label="$t('label.common.answer.select_action_type')"
                :rules="index === 0 ? [(val) => (val && val.length > 0) || requiredRule.actionType] : []"
                class="q-mb-sm"
              >
                <template v-slot:option="scope">
                  <q-item v-bind="scope.itemProps">
                    <q-item-section avatar>
                      <q-icon :name="scope.opt.icon" />
                    </q-item-section>
                    <q-item-section>
                      <q-item-label>{{ scope.opt.title }}</q-item-label>
                    </q-item-section>
                  </q-item>
                </template>
                <template v-slot:selected-item="scope">
                  <q-icon :name="scope.opt.icon" />
                  {{ scope.opt.title }}
                </template>
              </q-select>
              <div class="text-bold q-mb-sm">表示ラベル</div>
              <q-input
                lazy-rules
                :rules="index === 0 ? [(val) => (val && val && val.length > 0) || requiredRule.actionMessage] : []"
                maxlength="20"
                outlined
                v-model="action.label"
                dense
                class="col q-mb-sm"
              />
              <div class="text-bold q-mb-sm" v-if="action.type === 'uri'">URL</div>
              <div class="text-bold q-mb-sm" v-else>テキスト</div>
              <q-input
                v-if="action.type === 'uri'"
                lazy-rules
                type="url"
                :rules="[customIsValidUrl]"
                outlined
                v-model="action.content"
                dense
                class="col q-mb-sm"
                maxlength="1000"
              />
              <q-input
                v-else
                lazy-rules
                :rules="index === 0 ? [(val) => (val && val && val.length > 0) || requiredRule.actionMessage] : []"
                outlined
                v-model="action.content"
                dense
                class="col q-mb-sm"
                maxlength="40"
              />
            </q-expansion-item>
          </template>
          <span v-if="errorImageMessage !== '' && value.is_carousel">
            <div class="text-bold text-red">{{ errorImageMessage }}</div>
          </span>
        </div>
      </div>
    </q-item>
  </q-list>

  <UploadingProgress :files="cropFiles" />
  <CropperImageSelector
    v-if="cropperImageModalVisible"
    :modalVisible="cropperImageModalVisible"
    :file="file"
    @update:closeModal="onCloseModal"
    @update:onCropImage="onCropImage"
    :aspectRatio="currentButton.image_aspect_ratio ?? 1.51"
  />
  <CarouselButtonListingModal
    v-model="value.carousel_images"
    :is_use_image="value.is_use_image"
    :modalVisible="carouselButtonsModalVisible"
    @update:closeModal="onCloseCarouselButtonsModal"
  ></CarouselButtonListingModal>

  <MediaDetailsModal
    v-if="selectedMedia.url"
    :modalVisible="modalMediaVisible"
    :mediaUrl="selectedMedia.url"
    :mediaName="selectedMedia.name"
    @update:closeModal="modalMediaVisible = false"
  ></MediaDetailsModal>
</template>

<script lang='ts'>
import { Options, Vue } from 'vue-class-component'
import { maska } from 'maska'
import { Prop, Watch } from 'vue-property-decorator'
import cloneDeep from 'lodash/cloneDeep'
import { IButtonAnswer, IButtonAnswerAction, ICarouselButton, IMedia } from '@/utils/types'
import { IMAGEMAP_MESSAGE } from '@/utils/constants'
import MediaUploader from '@/components/media-manager/MediaUploader.vue'
import UploadingProgress from '@/components/common/ui/UploadingProgress.vue'
import CropperImageSelector from '@/components/tappable-area/selectors/CropperImageSelector.vue'
import UploadApi from '@/api/upload'
import { isValidUrl } from '@/utils/validators'
import CarouselButtonListingModal from '@/components/story/common/selectors/CarouselButtonListingModal.vue'
import MediaDetailsModal from '@/components/media-manager/MediaDetailsModal.vue'

@Options({
  components: { CarouselButtonListingModal, CropperImageSelector, UploadingProgress, MediaUploader, MediaDetailsModal },
  directives: { maska },
  emits: ['update:modelValue'],
})
export default class ButtonSelector extends Vue {
  @Prop({})
  modelValue!: IButtonAnswer

  @Prop()
  isQuestion!: boolean

  cropFiles: File[] = []
  cropperImageModalVisible = false
  file: File | null = null
  carouselIndexSelected = 0
  currentButton: IButtonAnswer | ICarouselButton = {}
  carouselButtonsModalVisible = false
  errorImageMessage = ''

  modalMediaVisible = false
  selectedMedia = {
    url: '',
    name: '',
  }

  get value() {
    const buttonAnswer: IButtonAnswer = this.modelValue
    if (!buttonAnswer.actions || (buttonAnswer.actions && buttonAnswer.actions.length === 0)) {
      buttonAnswer.actions = []
      for (let i = 0; i < 4; i++) {
        buttonAnswer.actions.push({
          type: IMAGEMAP_MESSAGE.ACTION_TYPE.POSTBACK,
        })
      }
    }
    if (!buttonAnswer.image_aspect_ratio) {
      buttonAnswer.image_aspect_ratio = 1.51
    }
    if (!buttonAnswer.image_size) {
      buttonAnswer.image_size = 'cover'
    }
    if (!buttonAnswer.carousel_images || (buttonAnswer.carousel_images && buttonAnswer.carousel_images.length === 0)) {
      buttonAnswer.carousel_images = []
      const carouselActions: IButtonAnswerAction[] = []
      for (let i = 0; i < 4; i++) {
        carouselActions.push({
          type: IMAGEMAP_MESSAGE.ACTION_TYPE.POSTBACK,
        })
      }
      for (let i = 0; i < 10; i++) {
        buttonAnswer.carousel_images.push({
          actions: carouselActions,
          image_aspect_ratio: 1.51,
          image_size: 'cover',
        })
      }
    }
    return buttonAnswer
  }

  set value(value: IButtonAnswer) {
    this.$emit('update:modelValue', value)
  }

  get requiredRule() {
    return {
      title: this.$t('validate.this_field_is_required', {
        placeholder: this.$t('label.common.answer.title'),
      }),
      text: this.$t('validate.this_field_is_required', {
        placeholder: this.$t('label.common.answer.text'),
      }),
      actionType: this.$t('validate.this_field_is_required', {
        placeholder: this.$t('label.common.answer.select_action_type'),
      }),
      actionLabel: this.$t('validate.this_field_is_required', {
        placeholder: this.$t('label.common.answer.label'),
      }),
      actionUri: this.$t('validate.this_field_is_required', {
        placeholder: this.$t('label.common.answer.uri'),
      }),
      actionMessage: this.$t('validate.this_field_is_required', {
        placeholder: this.$t('label.common.answer.message'),
      }),
    }
  }

  get options() {
    const options: [
      {
        id?: string
        title?: string
        icon?: string
      }
    ] = [{}]
    options.pop()
    options.push({
      id: IMAGEMAP_MESSAGE.ACTION_TYPE.URI,
      title: this.$t('label.common.answer.uri'),
      icon: 'http',
    })

    options.push({
      id: IMAGEMAP_MESSAGE.ACTION_TYPE.POSTBACK,
      title: 'メッセージ',
      icon: 'message',
    })
    // options.push({
    //   id: IMAGEMAP_MESSAGE.ACTION_TYPE.POSTBACK,
    //   title: 'Postback',
    //   icon: 'message',
    // })
    return options
  }

  get aspectRatios() {
    return [
      {
        label: 'レクタングル（1.51:1）',
        value: 1.51,
      },
      {
        label: 'スクエア（1:1）',
        value: 1,
      },
    ]
  }

  get imageSizes() {
    return [
      {
        label: 'カバー',
        value: 'cover',
      },
      {
        label: 'コンテイン',
        value: 'contain',
      },
    ]
  }

  get lineButtonTemplate() {
    return window.location.origin + '/img/line-button-template.png'
  }

  onOpenMediaDetails(image) {
    this.selectedMedia.url = image.image_url
    this.selectedMedia.name = image.title ?? 'image'
    this.modalMediaVisible = true
  }

  customIsValidUrl(url) {
    if (!isValidUrl(url)) {
      return false || this.requiredRule.actionUri
    }

    return true || ''
  }

  async validateImageWidth(file: File) {
    return new Promise<boolean>((resolve) => {
      const img = new Image()
      img.src = window.URL.createObjectURL(file)
      img.onload = (event: Event) => {
        const data = event.target as HTMLImageElement
        if (data.width / data.height !== this.currentButton.image_aspect_ratio) {
          this.errorImageMessage = this.$t('validate.image_width_size_imagemap')
          resolve(false)
        }

        resolve(true)
      }
    })
  }

  onCloseModal() {
    this.cropperImageModalVisible = false
    this.file = null
    this.cropFiles = []
  }

  onCloseCarouselButtonsModal() {
    this.carouselButtonsModalVisible = false
    this.handleCurrentButton()
  }

  async onCropImage(file) {
    this.cropperImageModalVisible = false
    this.cropFiles.push(file)
    await this.submitFile(file)
    this.onCloseModal()
  }

  async submitFile(file: File) {
    try {
      const uploaded = await UploadApi.uploadFile(file)
      if (uploaded) {
        this.currentButton.image_url = uploaded.file_url_org || ''
      }
    } catch (error) {
      console.log(error)
    }
  }

  async onSelectMedia(file: IMedia) {
    if (file) {
      this.currentButton.image_url = file.url
    }
  }

  onRemoveImage() {
    if (this.currentButton) {
      this.currentButton.image_url = ''
    }
  }

  @Watch('file')
  async handleUpload() {
    if (!this.file) {
      return false
    }

    try {
      if (!(await this.validateImageWidth(this.file))) {
        await this.$q
          .dialog({
            title: this.$t('messages.confirm'),
            message: this.$t('messages.image_aspect_ratio_is_not_1_1', {
              ratio: this.currentButton.image_aspect_ratio + ':1',
            }),
            cancel: {
              flat: true,
              label: this.$t('messages.no'),
            },
            ok: {
              flat: true,
              label: this.$t('messages.yes'),
            },
            persistent: true,
          })
          .onOk(async () => {
            this.cropperImageModalVisible = true
          })
          .onCancel(async () => {
            this.onCloseModal()
          })
      } else {
        this.cropFiles.push(this.file)
        await this.submitFile(this.file)
        this.file = null
        this.cropFiles = []
      }
    } catch (error) {
      console.log(error)
    }
  }

  @Watch('value.is_carousel')
  @Watch('carouselIndexSelected')
  handleCurrentButton() {
    if (this.value.is_carousel && this.value.carousel_images) {
      this.currentButton = cloneDeep(this.value.carousel_images[this.carouselIndexSelected])
    } else {
      this.currentButton = cloneDeep(this.value)
    }
  }

  @Watch('currentButton', { deep: true })
  handleCarouselButton() {
    if (this.value.is_carousel && this.value.carousel_images) {
      this.value.carousel_images[this.carouselIndexSelected] = cloneDeep(this.currentButton)
    } else {
      this.value = { ...this.value, ...cloneDeep(this.currentButton) }
    }
  }

  validate() {
    if (this.value.is_carousel && this.value.carousel_images) {
      let maxAction = 0
      let index = 0

      for (const carousel of this.value.carousel_images) {
        if (
          carousel &&
          carousel.actions &&
          carousel.text &&
          carousel.text !== '' &&
          carousel.title &&
          carousel.title !== ''
        ) {
          let current = 0
          for (const action of carousel.actions) {
            if ((!action.content || action.content === '') && (!action.label || action.label === '')) {
              continue
            }
            current++
          }

          if (index === 0) {
            maxAction = current
          } else {
            if (maxAction !== current) {
              this.errorImageMessage =
                'アクション数が統一されていません、アクション数を統一させたうえで保存してください。'
              return false
            }
          }
          index++
        }
      }
    }
    return true
  }

  mounted() {
    this.handleCurrentButton()
  }
}
</script>

<style scoped lang='scss'>
.text-h6 {
  font-size: 16px !important;
}
:deep(.cus-expansion .q-item) {
  padding-left: 0;
  padding-right: 0;
}
:deep(.cus-expansion .q-item__label) {
  font-weight: 700;
  margin-left: 8px;
}
:deep(.q-expansion-item__content) {
  margin-left: 16px;
  padding-left: 16px;
  border-left: 1px solid #d0d2e0;
}
:deep(.q-radio__label) {
  font-size: 16px;
  font-weight: 600;
}

:deep(.image-opt .q-item) {
  padding: 0px;
  min-height: auto;
}
:deep(.image-opt .q-item__section--side) {
  padding-right: 0;
}

.btn-action {
  color: #3982c2 !important;
}

.selected-block {
  border: 1px solid #3982c2;
  border-radius: 5px !important;
  background-color: #3982c21a !important;
}

.card-radius {
  border-radius: 4px !important;
}

.image-atribute {
  background-color: #d9d9d91a;
  border-radius: 5px !important;
}

.text-subtitle2 {
  white-space: normal; /* Ensures text wraps to the next line */
  word-wrap: break-word; /* Allows breaking words if they are too long */
  overflow-wrap: break-word; /* Alternative to word-wrap */
}

.btn-carousel:before {
  border: 0.5px currentColor dotted;
}
.btn-carousel.selected:before {
  border: 0.5px currentColor solid;
}

.btn-sort {
  // padding: 2px 6px;
  height: 30px;
  margin-top: 4px;
  :deep(span) {
    font-weight: unset;
    line-height: unset;
    font-size: unset;
    letter-spacing: unset;
  }
}

.btn-open {
  font-weight: bold;
  padding: 2px 6px;
  :deep(span) {
    font-weight: unset;
    line-height: unset;
    font-size: unset;
    letter-spacing: unset;
  }
}
</style>
